<template>
  <base-modal
    :modal-title="$t('addUser')"
    :confirm-text="$t('add')"
    :dialog-visible="modalVisible"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('scope')" prop="scope">
          <el-input v-model="form.scope" disabled />
        </el-form-item>
        <el-form-item :label="$t('user')" prop="email">
          <el-select
            v-model="form.email"
            filterable
            :allow-create="createNewUser"
            size="small"
            value-key="email"
            :filter-method="filterUsers"
          >
            <el-option
              v-for="user in sortUsers(filteredUsers)"
              :key="user.email"
              :label="user.name"
              :value="user.email"
              :disabled="user.disabled"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="createNewUser" :label="$t('name')" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>
        <el-form-item :label="$t('roles')" prop="selectedRoles">
          <el-select
            v-model="form.selectedRoles"
            multiple
            size="small"
            value-key="email"
          >
            <el-option
              v-for="role in roles"
              :key="role.name"
              :label="role.name"
              :value="role.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElOption, ElSelect, ElInput } from 'element-plus';

import { onUpdated, reactive, ref } from 'vue';
import {
  PrincipalType,
  RoleAssignmentAction,
  RoleModel,
  UpdateRoleAssignmentModel,
  UserModel
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';

export default {
  name: 'AddUserRoleAssignmentModal',
  components: { BaseModal, ElSelect, ElOption, ElForm, ElFormItem, ElInput },
  props: {
    modalVisible: { type: Boolean, default: false },
    users: {
      type: Array as () => Array<UserModel>,
      required: true
    },
    roles: {
      type: Array as () => Array<RoleModel>,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'create-user', 'add-roles'],
  setup(props, { emit }) {
    const form = reactive({
      email: '',
      name: '',
      selectedRoles: new Array<string>(),
      scope: props.scope
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      email: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      },
      selectedRoles: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      }
    });
    const filteredUsers = ref(new Array<UserModel>());
    const createNewUser = ref(false);

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          if (createNewUser.value) {
            emit('create-user', {
              email: form.email,
              name: form.name,
              disabled: false
            } as UserModel);
          }
          let userRoleAssignments = form.selectedRoles.map(
            (selectedRoleName: string) => {
              return {
                principalId: form.email,
                principalType: PrincipalType.User,
                roleName: selectedRoleName,
                scope: form.scope,
                action: RoleAssignmentAction.Add
              } as UpdateRoleAssignmentModel;
            }
          );
          emit('add-roles', userRoleAssignments);
        }
      });
    };

    const isValidEmail = (input: string) => {
      return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(input);
    };

    const filterUsers = (input: string) => {
      filteredUsers.value = props.users.filter(
        u =>
          u.email.toLowerCase().includes(input.toLowerCase()) ||
          u.name.toLowerCase().includes(input.toLowerCase())
      );
      if (!input) return;
      createNewUser.value = !filteredUsers.value.length && isValidEmail(input);
    };

    onUpdated(() => {
      form.email = '';
      form.selectedRoles = new Array<string>();
      form.scope = props.scope;
      createNewUser.value = false;
      filteredUsers.value = props.users;
    });

    return {
      confirm,
      createNewUser,
      filterUsers,
      filteredUsers,
      form,
      formRef,
      formRules
    };
  },
  methods: {
    sortUsers(users: Array<UserModel>) {
      let sortedList = users.sort((a, b) => {
        let fa = a.name?.toLowerCase() ?? '';
        let fb = b.name?.toLowerCase() ?? '';
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return sortedList;
    }
  }
};
</script>
<style scoped lang="scss"></style>
