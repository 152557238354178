<template>
  <div>
    <el-row>
      <el-col :span="24">
        <page-header
          :main-title="`${$t('applicationDetails')} - ${application.name}`"
        >
          <template #tabs>
            <div class="d-flex mt-2">
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'ApplicationProfile' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('profile') }}</h4>
                </base-tab>
              </router-link>
              <router-link
                v-slot="{ isExactActive }"
                :to="{ name: 'ApplicationRoles' }"
              >
                <base-tab :active="isExactActive">
                  <h4>{{ $t('roles') }}</h4>
                </base-tab>
              </router-link>
              <div class="horizontal-line mt-2">
                <hr />
              </div>
            </div>
          </template>
        </page-header>
      </el-col>
    </el-row>
    <div class="mt-6">
      <router-view />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, ComputedRef, onMounted } from 'vue';
import { useStore } from 'vuex';
import PageHeader from '@/components/misc/PageHeader.vue';
import { useRouter } from 'vue-router';
import { ElRow, ElCol } from 'element-plus';
import BaseTab from '@/components/tabs/BaseTab.vue';
import { ApplicationDetailsModel } from '@etp/etp-authorization-client/axios';
export default {
  name: 'ApplicationDetails',
  components: {
    PageHeader,
    ElRow,
    ElCol,
    BaseTab
  },
  setup() {
    const router = useRouter();
    const store = useStore();

    const application: ComputedRef<ApplicationDetailsModel> = computed(
      () => store.getters['applications/getApplicationDetails']
    );

    onMounted(async () => {
      const aadObjectId = router.currentRoute.value.params
        .aadObjectId as string;
      await store.dispatch('applications/fetchApplicationDetails', aadObjectId);
    });

    return {
      application
    };
  }
};
</script>
<style></style>
