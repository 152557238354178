<template>
  <div class="d-flex align-items-flex-end">
    <el-select
      class="storage-select width-auto"
      value-key="storagePurpose"
      @change="handleSelectChange"
    >
      <template #prefix>
        <h2 class="main-title">{{ pageHeader }}</h2>
      </template>
      <el-option
        v-for="item in storageConfigurations.filter(c => c.displayName?.length! > 2)"
        :key="item.storagePurpose"
        :label="item.displayName ?? item.storagePurpose"
        :value="item.storagePurpose"
      />
    </el-select>
    <h4 v-if="filesCount || foldersCount" class="subtitle-2 ml-1 extra-title">
      {{ `(${foldersCount} ${$t('folders')}, ${filesCount} ${$t('files')})` }}
    </h4>
  </div>
  <div class="mt-1">
    <router-view />
  </div>
</template>

<script lang="ts">
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { computed, ComputedRef, onMounted } from 'vue';
import { ElOption, ElSelect } from 'element-plus';
import {
  EngagementStorageConfiguration,
  StorageObject,
  StorageObjectType
} from '@etp/etp-storage-client/axios';

export default {
  name: 'StorageOverview',
  components: { ElOption, ElSelect },
  setup() {
    const store = useStore();
    const router = useRouter();

    const showStoragePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showStoragePages']
    );
    const pageHeader: ComputedRef<string> = computed(
      () => store.getters['home/getPageHeader']
    );

    const storageConfigurations: ComputedRef<
      Array<EngagementStorageConfiguration>
    > = computed(() => store.getters['storage/getConfigurations']);

    const filesCount: ComputedRef<number> = computed(
      () =>
        store.getters['storage/getCurrentFolderObjects']?.filter(
          (c: StorageObject) => c.type === StorageObjectType.File
        )?.length ?? 0
    );

    const foldersCount: ComputedRef<number> = computed(
      () =>
        store.getters['storage/getCurrentFolderObjects']?.filter(
          (c: StorageObject) =>
            c.type === StorageObjectType.Folder ||
            c.type === StorageObjectType.VirtualFolder
        )?.length ?? 0
    );

    const handleSelectChange = (selectedStoragePurpose: string) => {
      router.push({
        name: 'StorageManager',
        params: { storagePurpose: selectedStoragePurpose, path: [] }
      });
    };

    onMounted(async () => {
      if (!showStoragePages.value) return router.push({ name: 'Overview' });
    });

    return {
      filesCount,
      foldersCount,
      handleSelectChange,
      pageHeader,
      storageConfigurations
    };
  }
};
</script>
<style lang="scss" scoped>
.storage-select:deep(.el-input__inner) {
  display: none !important;
}
.storage-select:deep(.el-input__wrapper) {
  box-shadow: none;
  background: none;
}
.extra-title {
  padding-bottom: 1px;
}
</style>
