import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "terms-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      gutter: 5,
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 14 }, {
          default: _withCtx(() => [
            _createVNode(_component_page_header, {
              class: "page-header",
              "main-title": _ctx.$t('termsOfUse')
            }, null, 8, ["main-title"]),
            _cache[3] || (_cache[3] = _createElementVNode("p", null, [
              _createTextVNode(" Last revised: "),
              _createElementVNode("b", null, "16th August 2021")
            ], -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " If you have questions or require assistance, please contact your Deloitte engagement team. ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("h3", null, "1. Who These Terms of Use Apply to; What They Govern.", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, [
              _createTextVNode(" This Deloitte Enterprise Tax Platform – Core (ETP Core) Website (this "),
              _createElementVNode("b", null, "\"Website\""),
              _createTextVNode(" ) is for use by certain personnel of (i) Deloitte Touche Tohmatsu Limited ( "),
              _createElementVNode("b", null, "“DTTL”"),
              _createTextVNode(" ), (ii) each member firm of DTTL and the affiliates of such member firms (each a "),
              _createElementVNode("b", null, "“Deloitte Firm”"),
              _createTextVNode(" ), and (iii) clients and other unrelated parties invited to use this website by a Deloitte Firm. DTTL and the Deloitte Firms and any of their related entities are referred to herein collectively as the "),
              _createElementVNode("b", null, "“Deloitte Network”"),
              _createTextVNode(" . Partners, principals, directors and employees of entities within the Deloitte Network are not subject to these Terms of Use (these "),
              _createElementVNode("b", null, "“Terms”"),
              _createTextVNode(" ). ")
            ], -1)),
            _cache[7] || (_cache[7] = _createElementVNode("p", null, [
              _createTextVNode(" As noted above, each Deloitte Firm may invite personnel of clients and other unrelated parties to use this Website pursuant to these Terms. If you are such an invitee, these Terms are applicable to you (and further references below to "),
              _createElementVNode("b", null, "“you”"),
              _createTextVNode(" and "),
              _createElementVNode("b", null, "“your”"),
              _createTextVNode(" shall be to you as such an invitee). ")
            ], -1)),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, [
              _createTextVNode(" Prior to your use (or continued use) of this Website, you must agree to these Terms on behalf of both yourself and the legal entity for which you work as an employee, partner, director, or principal ( "),
              _createElementVNode("b", null, "“Company”"),
              _createTextVNode(" ). Thereafter, these Terms shall be a legally binding agreement between you and Company on the one hand, and the Deloitte Firm that invited you to this Website on the other (referred to herein as "),
              _createElementVNode("b", null, "\"we\", \"us\" or “our”"),
              _createTextVNode(" ). ")
            ], -1)),
            _cache[9] || (_cache[9] = _createElementVNode("p", null, [
              _createTextVNode(" Company has requested that we grant you access to specific sections of this Website (the "),
              _createElementVNode("b", null, "“Permitted Sections”"),
              _createTextVNode(" ). ")
            ], -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", null, [
              _createTextVNode(" Where you or Company are our client and we have agreed, pursuant to an engagement letter or other contract (each an "),
              _createElementVNode("b", null, "\"Engagement Letter\""),
              _createTextVNode(" ) to the performance of services or delivery of deliverables (an "),
              _createElementVNode("b", null, "“Engagement\""),
              _createTextVNode(" ), then all Engagement services and deliverables shall be governed by the Engagement Letter and, where applicable, these Terms. As set forth more fully in Section 12A, in the event of any conflict between an Engagement Letter and these Terms, the terms of the Engagement Letter shall control with respect to the applicable Engagement. Where neither you nor Company are our client, these Terms shall apply except as set forth in Section 12A. ")
            ], -1)),
            _cache[11] || (_cache[11] = _createElementVNode("p", null, " Although parts of these Terms may reference other entities in the Deloitte Network, these Terms are only with us and not with any of those other entities. ", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("h3", null, "2. Registration and Related Matters; Certain Restrictions.", -1)),
            _cache[13] || (_cache[13] = _createElementVNode("h4", null, "A. Eligibility.", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " In General. To be eligible to use this Website, you must be at least 18 years old and have full power and authority to enter into these Terms on behalf of yourself and Company. You must also not be prohibited from entering into these Terms or using any portion of this Website by us, Company, any other party, or any contractual, regulatory or other legal restrictions. You hereby represent and warrant to us that you meet these eligibility requirements. "),
              _createElementVNode("li", null, " Your obligation to keep us informed. You must notify us if circumstances change such that you are no longer in compliance with the eligibility requirements set forth in the paragraph above. ")
            ], -1)),
            _cache[15] || (_cache[15] = _createElementVNode("h4", null, " A. Certain restrictions applicable to your User Account and general usage of this Website. ", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("p", null, " In order to use this Website, you must register for a “User Account”. You and Company are responsible for anything that happens through your User Account until it is deactivated. Without limiting the foregoing, you and Company agree to the following: ", -1)),
            _cache[17] || (_cache[17] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " You will only create and maintain one User Account at any given time, which shall be for you alone and not shared or transferred to anyone else. "),
              _createElementVNode("li", null, " You will only access the Permitted Sections and will not hack into or otherwise attempt to access other areas of this Website. "),
              _createElementVNode("li", null, " You and Company will keep your User Account password secure and confidential. "),
              _createElementVNode("li", null, " You will not remove any copyright, trademark or other proprietary rights notices found on this Website or its content. "),
              _createElementVNode("li", null, " You and Company will comply with all laws applicable in accessing and using this Website, including all data privacy, export control and securities laws and regulations. "),
              _createElementVNode("li", null, " You will immediately cease all use of your User Account and this Website upon the termination of your employment or other work relationship with Company. Company must notify us in writing of such termination, and Company remains responsible for your actions with respect to your User Account and this Website until such notification and related deactivation of your User Account. "),
              _createElementVNode("li", null, " You and Company will not reverse engineer, decompile or otherwise attempt to derive the source code for any underlying software related to this Website, or adapt, modify or create derivative works based on this Website or its underlying technology. "),
              _createElementVNode("li", null, " You and Company will not engage in any action that directly or indirectly interferes with the proper working of, or places an unreasonable load on, our infrastructure, including spamming and the distribution of computer viruses or other malicious code. "),
              _createElementVNode("li", null, " You or Company will notify us immediately upon becoming aware of any unauthorized use of your User Account or any other breach of the above obligations. ")
            ], -1)),
            _cache[18] || (_cache[18] = _createElementVNode("h3", null, "3. Your Contributed Content", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("h4", null, " A. Your and Company’s obligations regarding your Contributed Content. ", -1)),
            _cache[20] || (_cache[20] = _createElementVNode("p", null, [
              _createTextVNode(" Areas of this Website will allow you to input, upload, modify or otherwise make available (hereinafter, "),
              _createElementVNode("b", null, "“provide” or “provision”"),
              _createTextVNode(" ) data, documents, files, comments and other information or materials (all of the foregoing being "),
              _createElementVNode("b", null, "“your Contributed Content”"),
              _createTextVNode(" ). You and Company are solely responsible for your Contributed Content. ")
            ], -1)),
            _cache[21] || (_cache[21] = _createElementVNode("p", null, " Without limiting the foregoing, you and Company will ensure the following: ", -1)),
            _cache[22] || (_cache[22] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " The provision of your Contributed Content has been appropriately authorized by Company. "),
              _createElementVNode("li", null, " Your Contributed Content does not contain any content that could be reasonably viewed as false, offensive, indecent, defamatory, libelous, harassing, threatening, or otherwise harmful. "),
              _createElementVNode("li", null, " Your Contributed Content, and the provision of it by you on this Website, does not violate any laws, rules, regulations or professional standards that are applicable to you or Company, including any third party privacy right, or third party copyright, trademark or other intellectual property right. ")
            ], -1)),
            _cache[23] || (_cache[23] = _createElementVNode("h4", null, "B. Access to and use of your Contributed Content.", -1)),
            _cache[24] || (_cache[24] = _createElementVNode("p", null, " You and Company agree to the following with respect to your Contributed Content: ", -1)),
            _cache[25] || (_cache[25] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " Your Contributed Content will be available, via this Website, to us, any other Deloitte Firm, and to other persons whom Company (or our client, where Company is not our client) has authorized to access the Permitted Sections, including, potentially, persons outside the Deloitte Network. "),
              _createElementVNode("li", null, " Your Contributed Content may also be accessed, in certain limited circumstances and for limited periods of time, for site administration, troubleshooting, system maintenance, emergencies, and other technical support, by Deloitte Network personnel and contractors, each of whom will be subject to confidentiality obligations. "),
              _createElementVNode("li", null, " Notwithstanding the above, and although we are under no obligation to verify the authenticity or timeliness of, or, unless otherwise agreed in accordance with an Engagement Letter, to review the nature or content of, your Contributed Content, we may review and/or remove any portion of your Contributed Content that we believe has been placed on this Website in breach of these Terms or any applicable Engagement Letter, or is outdated and/or no longer relevant to users of this Website. "),
              _createElementVNode("li", null, " We may use your Contributed Content for internal purposes and, if your Contributed Content is relevant to an Engagement pursuant to the applicable Engagement Letter. "),
              _createElementVNode("li", null, " Your Contributed Content will be used to display informational output that will be general in nature, represents an export of Your Contributed Content that you have submitted or is based on Your Contributed Content, and may only be used for informational purposes. The output is not intended to be, not designed to be, and should not be, used for the purposes of decision making, making filings with relevant tax authorities, or for any other purpose. Such output (i) has not been validated or otherwise verified and (ii) do not constitute advice or professional services. It is recommended that you seek professional advice before making any decision or taking any action or refraining from taking any action with respect to the output. "),
              _createElementVNode("li", null, " The Website is hosted in the United States and United Kingdom. Due to the nature of the Website, any information sent or uploaded to the system may also be accessible in other jurisdictions outside your home jurisdiction as reasonably required. This may involve transfers of your information to countries or regions without data protection rules similar to those in effect in your home jurisdiction. By registering for or using the Website, you are consenting to such transfers. ")
            ], -1)),
            _cache[26] || (_cache[26] = _createElementVNode("p", null, " Any information sent or uploaded may also be made accessible to third parties providing services on our behalf, such as the administration and management of this Website. ", -1)),
            _cache[27] || (_cache[27] = _createElementVNode("h3", null, "4. Other Disclosure of Your Information; Privacy.", -1)),
            _createElementVNode("p", null, [
              _cache[1] || (_cache[1] = _createTextVNode(" You and Company acknowledge and agree that we may disclose your User Account information and your Contributed Content to others if we have a good faith belief that such action is reasonably necessary to comply with law, regulatory requirements, professional standards, or prevent harm, or as otherwise provided in the applicable Engagement Letter, these Terms or the Privacy Statement. Our ")),
              _createElementVNode("a", {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Privacy' })))
              }, "Privacy Statement"),
              _cache[2] || (_cache[2] = _createTextVNode(" is incorporated herein by reference, and you hereby agree to its terms. "))
            ]),
            _cache[28] || (_cache[28] = _createElementVNode("h3", null, "5. Your Use of this Website.", -1)),
            _cache[29] || (_cache[29] = _createElementVNode("h4", null, "A. In general.", -1)),
            _cache[30] || (_cache[30] = _createElementVNode("p", null, " On the condition that you comply with all your obligations under these Terms, we grant you a limited, revocable, nonexclusive, non-assignable, non-sublicensable right to access and use the Permitted Sections only as expressly permitted herein. ", -1)),
            _cache[31] || (_cache[31] = _createElementVNode("h4", null, "B. Your use of ETP Core Content and ETP Core Technology.", -1)),
            _cache[32] || (_cache[32] = _createElementVNode("p", null, " As used herein, “ETP Core Content” means any content on this Website, including content that is provided by us, any other entity within the Deloitte Network, or a third party. ", -1)),
            _cache[33] || (_cache[33] = _createElementVNode("p", null, " As used herein, “ETP Core Technology” means any software code, program, process, tool or device on this Website. ", -1)),
            _cache[34] || (_cache[34] = _createElementVNode("p", null, " Except as otherwise provided in an applicable Engagement Letter, you and Company will: ", -1)),
            _cache[35] || (_cache[35] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " Maintain the confidentiality of the ETP Core Content and ETP Core Technology using at least the same degree of care as Company uses in maintaining its own proprietary and/or confidential information, but in no event using less than a reasonable degree of care. "),
              _createElementVNode("li", null, " Use the ETP Core Content and ETP Core Technology solely for the internal purposes of Company. ")
            ], -1)),
            _cache[36] || (_cache[36] = _createElementVNode("p", null, " Except as may be required by law, limit access to the ETP Core Content solely to those persons who have a direct and immediate need of such access and who are obligated to maintain its confidentiality. ", -1)),
            _cache[37] || (_cache[37] = _createElementVNode("p", null, " Ensure that all copies of the ETP Core Content (printed or electronic) retain and/or reflect any copyright, confidentiality, or other legal notice that appears on or with such material when accessed on this Website. ", -1)),
            _cache[38] || (_cache[38] = _createElementVNode("p", null, " Not copy, download, reproduce, modify or distribute the ETP Core Technology in whole or in part, except as may be expressly permitted elsewhere in writing outside of these Terms. ", -1)),
            _cache[39] || (_cache[39] = _createElementVNode("p", null, " Not extract any redistributable files from the ETP Core Technology and/or use such redistributables for any purpose other than your use of this Website in accordance with these Terms. ", -1)),
            _cache[40] || (_cache[40] = _createElementVNode("p", null, " For purposes of clarity, Company's usage and/or ownership rights with respect to any software code that is a deliverable under an Engagement Letter or licensed under any other agreement is governed by the terms of such document and not by these Terms, even if such code is provided to you or Company via this Website. ", -1)),
            _cache[41] || (_cache[41] = _createElementVNode("h3", null, "6. No Professional Relationship.", -1)),
            _cache[42] || (_cache[42] = _createElementVNode("p", null, " You and Company acknowledge and agree that no professional relationship of any nature is created solely by your use of this Website or by any correspondence or communication with us or any other person or entity relating to this Website. For clarification, if a professional relationship already exists with us (e.g., pursuant to an Engagement Letter), it will not be terminated by your use of this Website. ", -1)),
            _cache[43] || (_cache[43] = _createElementVNode("p", null, " The infrastructure of, and certain ETP Core Technology and content found on, this Website are provided to us by DTTL or one or more of its related entities. However, your use of this Website is solely at our invitation, and neither DTTL nor any of those related entities is providing a service to you or Company or entering into a professional relationship with you or Company by way of this Website. Unless otherwise set forth specifically in a separate written agreement, any service that you receive and relationship that you have with respect to this Website is with us alone and is governed by these Terms and any related Engagement Letter. ", -1)),
            _cache[44] || (_cache[44] = _createElementVNode("h3", null, "7. Availability and Access.", -1)),
            _cache[45] || (_cache[45] = _createElementVNode("h4", null, "A. Availability.", -1)),
            _cache[46] || (_cache[46] = _createElementVNode("p", null, " We will endeavor to provide reasonable notice of any modifications to this Website that are likely to materially affect access to this Website or this Website. However, we reserve the right to modify, replace, refuse access to, suspend, limit, disable or discontinue this Website and its functionality, in whole or in part, in our sole discretion, with or without notice. ", -1)),
            _cache[47] || (_cache[47] = _createElementVNode("p", null, " Accordingly, you and Company should have in place appropriate measures for ensuring the retention of copies of all your Contributed Content. You and Company may not use this Website as a document archive or storage facility. ", -1)),
            _cache[48] || (_cache[48] = _createElementVNode("h4", null, "B. Access and Mobile Access.", -1)),
            _cache[49] || (_cache[49] = _createElementVNode("p", null, " It is your responsibility to ensure that you have software and hardware that are capable of effectively accessing this Website via an internet service provider. You are also responsible for all charges and necessary permissions related to accessing this Website through your internet provider. ", -1)),
            _cache[50] || (_cache[50] = _createElementVNode("p", null, " We may offer access to this Website through mobile applications created by us or third party developers. If you use this Website through a mobile device, you agree that information about such usage may be communicated to us, including information about your mobile carrier, your mobile device, or your physical location. You are responsible for all charges and necessary permissions related to accessing this Website through your mobile access provider. Note that, by using any downloadable application to enable your use of this Website, you will be required to accept the terms of the end-user license agreement associated with the application. ", -1)),
            _cache[51] || (_cache[51] = _createElementVNode("h3", null, "8. Certain Intellectual Property Rights.", -1)),
            _cache[52] || (_cache[52] = _createElementVNode("h4", null, "A. This Website.", -1)),
            _cache[53] || (_cache[53] = _createElementVNode("p", null, " This Website and its contents are protected by copyright, trademark and other laws of the United States and foreign countries. We and our licensors reserve all rights not expressly granted in these Terms. ", -1)),
            _cache[54] || (_cache[54] = _createElementVNode("h4", null, "B. Names and Logos.", -1)),
            _cache[55] || (_cache[55] = _createElementVNode("p", null, " “Deloitte”, “Touche”, “Tohmatsu”, “Deloitte Touche Tohmatsu”, “Deloitte & Touche”, the Deloitte logo, and local language variants of the foregoing trademarks, and certain product names that appear on this Website (collectively, the “Deloitte Marks”), are trademarks or registered trademarks of entities within the Deloitte Network. Except as expressly provided in these Terms of Use or as expressly authorized in writing by the relevant trademark owner, neither you nor Company shall use any Deloitte Marks either alone or in combination with other words or design elements, including in any press release, advertisement, or other promotional or marketing material or media, whether in written, oral, electronic, visual or any other form. ", -1)),
            _cache[56] || (_cache[56] = _createElementVNode("p", null, " References to other parties’ trademarks on this Website are for identification purposes only and do not indicate that such parties have approved this Website or any of its contents. These Terms do not grant you any right to use the trademarks of other parties. ", -1)),
            _cache[57] || (_cache[57] = _createElementVNode("h3", null, "9. Limitations of Liability; Indemnity.", -1)),
            _cache[58] || (_cache[58] = _createElementVNode("p", null, " NEITHER WE NOR ANY OTHER ENTITY WITHIN THE DELOITTE NETWORK IS, BY MEANS OF PERMITTING YOU ACCESS TO THIS WEBSITE (INCLUDING ALL ETP CORE TECHNOLOGY, ETP CORE CONTENT, OUTPUT, AND OTHER CONTENT), RENDERING PROFESSIONAL ADVICE OR SERVICES TO YOU OR COMPANY. BEFORE MAKING ANY DECISION OR TAKING ANY ACTION THAT MIGHT AFFECT YOUR OR COMPANY'S FINANCES OR BUSINESS, YOU SHOULD CONSULT A QUALIFIED PROFESSIONAL ADVISOR. FOR CLARITY, ADVICE AND DELIVERABLES THAT WE PROVIDE PURSUANT TO AN ENGAGEMENT LETTER SHALL BE GOVERNED BY THE TERMS OF THAT ENGAGEMENT LETTER, EVEN IF ACCESSIBLE BY OR DELIVERED TO YOU VIA THIS WEBSITE. ", -1)),
            _cache[59] || (_cache[59] = _createElementVNode("p", null, " ALTHOUGH WE TAKE COMMERCIALLY REASONABLE STEPS TO MAKE THIS WEBSITE USEFUL AND SECURE, THIS WEBSITE (INCLUDING ALL ETP CORE TECHNOLOGY, ETP CORE CONTENT, OUTPUT, AND OTHER CONTENT) IS PROVIDED AS IS, WITHOUT WARRANTY OF ANY KIND. WITHOUT LIMITING THE FOREGOING, WE DO NOT WARRANT THAT THIS WEBSITE WILL BE SECURE, ERROR-FREE, FREE FROM VIRUSES OR MALICIOUS CODE, OR WILL MEET ANY PARTICULAR CRITERIA OF PERFORMANCE OR QUALITY, AND WE EXPRESSLY DISCLAIM ALL IMPLIED WARRANTIES, INCLUDING WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, COMPATIBILITY, SECURITY, AND ACCURACY. ", -1)),
            _cache[60] || (_cache[60] = _createElementVNode("p", null, " YOUR USE OF THIS WEBSITE (INCLUDING ALL ETP CORE TECHNOLOGY, ETP CORE CONTENT, OUTPUT, AND OTHER CONTENT) IS AT YOUR OWN RISK AND YOU ASSUME FULL RESPONSIBILITY AND RISK OF LOSS RESULTING FROM YOUR USAGE, INCLUDING WITH RESPECT TO LOSS OF SERVICE OR DATA. WE WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR ANY OTHER DAMAGES WHATSOEVER, WHETHER IN AN ACTION OF CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), OR OTHERWISE, RELATING TO THE USE OF THIS WEBSITE (INCLUDING ALL ETP CORE TECHNOLOGY, ETP CORE CONTENT, OUTPUT, AND OTHER CONTENT). ", -1)),
            _cache[61] || (_cache[61] = _createElementVNode("p", null, " CERTAIN LINKS ON THIS WEBSITE MAY LEAD TO RESOURCES, WEBSITES AND TOOLS MAINTAINED BY THIRD PARTIES OVER WHICH WE HAVE NO CONTROL, INCLUDING THOSE MAINTAINED OR PROVIDED BY OTHER ENTITIES WITHIN THE DELOITTE NETWORK. WE MAKE NO REPRESENTATIONS OR WARRANTIES AS TO THE ACCURACY OF, OR ANY OTHER ASPECT RELATING TO, THOSE RESOURCES, WEBSITES AND TOOLS AND LINKS TO ANY SUCH WEBSITES, RESOURCES, AND TOOLS SHOULD NOT BE CONSTRUED AS AN ENDORSEMENT OF THEM OR THEIR CONTENT BY US OR AN ENDORSEMENT BY THEM OF US OR THIS WEBSITE. ", -1)),
            _cache[62] || (_cache[62] = _createElementVNode("p", null, " THE ABOVE LIMITATIONS OF LIABILITY SHALL BE APPLICABLE NOT ONLY TO US BUT ALSO TO EACH OTHER ENTITY WITHIN THE DELOITTE NETWORK AND TO OUR AND THEIR RESPECTIVE PERSONNEL. ", -1)),
            _cache[63] || (_cache[63] = _createElementVNode("p", null, " IF ANY OF THE ABOVE LIMITATIONS OF LIABILITY IS INVALID OR UNENFORCEABLE IN ANY JURISDICTION, THEN (I) IN THAT JURISDICTION IT SHALL BE RE-CONSTRUED TO THE MAXIMUM EFFECT PERMITTED BY LAW TO EFFECT ITS INTENT AS NEARLY AS POSSIBLE AND THE REMAINING TERMS SHALL REMAIN IN FULL FORCE AND EFFECT, AND (II) IN EVERY OTHER JURISDICTION ALL OF THESE TERMS SHALL REMAIN IN FULL FORCE AND EFFECT. ", -1)),
            _cache[64] || (_cache[64] = _createElementVNode("p", null, " COMPANY AGREES TO INDEMNIFY US AND EACH OTHER ENTITY WITHIN THE DELOITTE NETWORK, AND OUR AND THEIR PERSONNEL, AGAINST ALL COSTS, CLAIMS, LOSSES OR EXPENSES INCURRED BY OR MADE AGAINST ANY OF THE FOREGOING AS A RESULT OF ANY BREACH BY YOU OR COMPANY OF THESE TERMS, INCLUDING ANY THIRD-PARTY CLAIM THAT ANY OF YOUR CONTRIBUTED CONTENT INFRINGES ANY INTELLECTUAL PROPERTY OR OTHER RIGHT OF ANY PERSON OR ENTITY. ", -1)),
            _cache[65] || (_cache[65] = _createElementVNode("h3", null, "10. User Account Deactivation.", -1)),
            _cache[66] || (_cache[66] = _createElementVNode("h4", null, "A. Deactivating Your User Account.", -1)),
            _cache[67] || (_cache[67] = _createElementVNode("p", null, " You or Company may deactivate your User Account, for any or no reason, at any time, with written notice to us as per Section 11 (Notices). This notice will be effective upon our processing such notice. ", -1)),
            _cache[68] || (_cache[68] = _createElementVNode("p", null, " We may deactivate your User Account for any reason or no reason, at any time, with or without notice, effective immediately or as may be specified in any given notice. Without limiting the foregoing, your User Account may be deactivated by Website administration if inactive for extended time periods. ", -1)),
            _cache[69] || (_cache[69] = _createElementVNode("h4", null, "B. Effect of Deactivation.", -1)),
            _cache[70] || (_cache[70] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, " Usage. Deactivation of your User Account results in the immediate termination of your license to access and use this Website, and also the disabling, as soon as possible, of your technological access to this Website. In addition, we may bar you from any future use of this Website and also block access to this Website from any IP address or range of IP addresses associated with you. "),
              _createElementVNode("li", null, " Surviving Terms. Section 5A of these Terms shall not survive deactivation of your User Account and termination of your license to access and use this Website. The remainder of these Terms shall survive. ")
            ], -1)),
            _cache[71] || (_cache[71] = _createElementVNode("h3", null, "12. General Terms.", -1)),
            _cache[72] || (_cache[72] = _createElementVNode("h4", null, "A. Entire Agreement; Engagement Letter.", -1)),
            _cache[73] || (_cache[73] = _createElementVNode("p", null, " Subject to the following two sentences, these Terms constitute the full and complete agreement between you and Company on the one hand, and us on the other, with respect to the subject matter hereof, and supersede any oral and prior written agreements with respect to such subject matter. In the event of any conflict between these Terms and an Engagement Letter, the terms of the Engagement Letter shall control with respect to the applicable Engagement. Where (i) neither you nor Company are our client and your activity on this Website is not covered by an Engagement Letter and (ii) we have entered into a separate written agreement with you or your Company with respect to this Website, then, in the event of a conflict between such agreement and these Terms, such agreement shall control. ", -1)),
            _cache[74] || (_cache[74] = _createElementVNode("h4", null, "B. Amendments to these Terms.", -1)),
            _cache[75] || (_cache[75] = _createElementVNode("p", null, " We may revise these Terms at any time in our sole discretion by posting such revised terms at this Terms of Use link in this Website’s footer (i.e., this webpage that you are currently viewing) or elsewhere on this Website, or otherwise notifying you in accordance with Section 11 (Notices). Such revised terms shall be effective to you upon posting or other notice, unless otherwise explicitly stated by us. ", -1)),
            _cache[76] || (_cache[76] = _createElementVNode("p", null, " It is your and Company’s responsibility to be aware of any such revised terms by checking here and reading your notices. ", -1)),
            _cache[77] || (_cache[77] = _createElementVNode("p", null, " If you or Company does not agree with any of these Terms as they may be amended from time to time, you should deactivate your User Account as per Section 10 (Termination). ", -1)),
            _cache[78] || (_cache[78] = _createElementVNode("h4", null, "C. No informal waivers, agreements or representations.", -1)),
            _cache[79] || (_cache[79] = _createElementVNode("p", null, " No waiver of any breach by you or Company, or of any objection to any act or omission connected therewith, shall be implied or claimed by you or Company or be deemed to constitute a consent to any continuation of such breach, act or omission, unless contained in a writing signed by us. ", -1)),
            _cache[80] || (_cache[80] = _createElementVNode("h4", null, "D. Dispute resolution; equitable relief.", -1)),
            _cache[81] || (_cache[81] = _createElementVNode("ol", null, [
              _createElementVNode("li", null, [
                _createElementVNode("p", null, " Choice of Law; etc. Any choice of law, venue, and dispute resolution terms and procedures applicable to the Engagement Letter shall govern these Terms, and all rights and obligations arising from or otherwise relating to them. In the event no Engagement Letter exists, then (i) these Terms shall be governed by, construed in accordance with, and enforced under the laws (both substantive and procedural) of the State of New York, USA (but specifically excluding the United Nations Convention on Contracts for the International Sale of Goods), without reference to principles of conflict of laws; (ii) you, Company and we agree to submit to the exclusive jurisdiction and venue of the federal and state courts situated in New York State with respect to any dispute arising hereunder or in connection with such rights and obligations and (iii) you, Company and we also agree to irrevocably waive any right to a jury trial in any action, proceeding or counterclaim relating to these Terms. "),
                _createElementVNode("p", null, " Notwithstanding the above, we shall still be allowed to apply for injunctive remedies (or an equivalent type of urgent legal relief) in any jurisdiction. ")
              ]),
              _createElementVNode("li", null, " Equitable Relief. In no event shall you or Company seek or be entitled to rescission, injunctive or other equitable relief, or to enjoin or restrain the operation, use or exploitation of this Website or any part of it, including any ETP CORE Technology or ETP CORE Content. We shall be entitled to injunctive or other equitable relief in order to prevent, mitigate or remedy the breach, continuing breach or continuing breach of these Terms. ")
            ], -1)),
            _cache[82] || (_cache[82] = _createElementVNode("h4", null, "E. Remedies not exclusive.", -1)),
            _cache[83] || (_cache[83] = _createElementVNode("p", null, " Exercise or enforcement of a right or remedy given in these Terms shall not be considered to be in lieu of enforcement of other rights or remedies otherwise existing at law or equity, unless specifically waived in writing. ", -1)),
            _cache[84] || (_cache[84] = _createElementVNode("h4", null, "F. Severability.", -1)),
            _cache[85] || (_cache[85] = _createElementVNode("p", null, " If any term in these Terms is invalid or unenforceable in any jurisdiction, then (i) in that jurisdiction it shall be re-construed to the maximum extent permitted by law to effect its intent as nearly as possible and the remaining terms shall remain in full force and effect, and (ii) in every other jurisdiction all of these Terms shall remain in full force and effect. ", -1)),
            _cache[86] || (_cache[86] = _createElementVNode("h4", null, "G. No construction against the drafter.", -1)),
            _cache[87] || (_cache[87] = _createElementVNode("p", null, " If an ambiguity or question of intent or interpretation arises with respect to these Terms, these Terms will be construed as if drafted jointly by you, Company and us, and no presumption or burden of proof will arise favoring or disfavoring any of those parties by virtue of authorship of these Terms. ", -1)),
            _cache[88] || (_cache[88] = _createElementVNode("h4", null, "H. Assignment and delegation.", -1)),
            _cache[89] || (_cache[89] = _createElementVNode("p", null, " You and Company may not assign or delegate any rights or obligations under these Terms, and any such purported assignment or delegation shall be ineffective. We may freely assign or delegate all rights and obligations under these Terms, fully or partially, without notice to you or Company. These Terms shall be binding upon your, Company’s and our successors and permitted assigns. ", -1)),
            _cache[90] || (_cache[90] = _createElementVNode("h4", null, "I. Relationship of the parties; third party beneficiaries.", -1)),
            _cache[91] || (_cache[91] = _createElementVNode("p", null, " No agency, partnership, franchise, or joint venture is created by these Terms between you and Company on the one hand, and us on the other. ", -1)),
            _cache[92] || (_cache[92] = _createElementVNode("p", null, " Certain Deloitte Firms and DTTL and its related entities are third party beneficiaries to certain sections of these Terms, and may enforce specific rights hereunder. ", -1)),
            _cache[93] || (_cache[93] = _createElementVNode("h4", null, "J. Language.", -1)),
            _cache[94] || (_cache[94] = _createElementVNode("p", null, " In the event that we have provided you or Company with a translation of the English language version of these Terms, the Privacy Statement, and/or any other documentation, you and Company agree that the translation is provided for convenience only and that the English language version governs your relationship with us. ", -1)),
            _cache[95] || (_cache[95] = _createElementVNode("h4", null, "K. Construction.", -1)),
            _cache[96] || (_cache[96] = _createElementVNode("p", null, " Agreement Headings and Numbering. Paragraph or Section numbers and headings that are used in these Terms are included for convenience only and, if there is any conflict between any such numbers and headings and the text of these Terms, the text shall control. ", -1)),
            _cache[97] || (_cache[97] = _createElementVNode("h4", null, "L. Feedback.", -1)),
            _cache[98] || (_cache[98] = _createElementVNode("p", null, " You, on behalf of yourself and your Company, hereby assigns and conveys to us, without additional compensation, all right, title, and interest worldwide in and to all feedback provided in connection with this Website by you or your Company, including all patent, copyright, trademark, trade secret, and other intellectual property rights in the feedback, together with all goodwill and claims appurtenant thereto and the right to modify and create derivative works therefrom as we may determine in its sole discretion. You, on behalf of yourself and your Company, hereby irrevocably waives all rights with respect to the foregoing (including all rights of attribution, rights of integrity, rights of publicity or privacy, moral rights, and/or rights to inspect or approve any materials incorporating the feedback or any use thereof). ", -1)),
            _cache[99] || (_cache[99] = _createElementVNode("hr", null, null, -1))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}