<template>
  <base-modal
    width="690px"
    :modal-title="$t('applicationSettings')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm"
    @close="handleClose"
  >
    <template #content>
      <div>
        <el-divider>{{ $t('inheritedAssignments') }}</el-divider>
        <el-table
          :data="localInheritedApplications"
          :empty-text="$t('noApplications')"
        >
          <el-table-column>
            <template #default="scope">
              <div class="body-1">
                {{ scope.row.name }}
              </div>
              <div class="body-2">
                {{ scope.row.aadObjectId }}
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-divider>{{ $t('directAssignments') }}</el-divider>
        <el-table
          :data="localDirectApplications"
          :empty-text="$t('noApplications')"
        >
          <el-table-column>
            <template #default="scope">
              <div class="body-1">
                {{ scope.row.name }}
              </div>
              <div class="body-2">
                {{ scope.row.aadObjectId }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :width="100" align="center">
            <template #default="scope">
              <div class="actions">
                <el-tooltip :content="$t('remove')">
                  <img
                    src="@/assets/images/icons/new/delete.svg"
                    class="icon-size-7"
                    @click="removeApplication(scope.$index, scope.row)"
                  />
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>
    <template #footer>
      <el-dropdown max-height="400px" type="primary" trigger="click">
        <div class="application-dropdown">
          <div class="body-1">
            {{ $t('selectApplication') }}
          </div>
          <div class="el-dropdown-link">
            <i class="el-icon-d-caret el-icon--right"></i>
          </div>
        </div>
        <template #dropdown>
          <el-dropdown-menu class="dropdown-menu">
            <el-dropdown-item
              v-for="(application, index) in localAvailableApplications"
              :key="application.aadObjectId"
              @click="addApplication(index, application)"
            >
              {{ application.name }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </template>
  </base-modal>
</template>
<script lang="ts">
import {
  ElDivider,
  ElDropdown,
  ElDropdownMenu,
  ElDropdownItem,
  ElTable,
  ElTableColumn,
  ElTooltip
} from 'element-plus';

import BaseModal from '@/components/modals/BaseModal.vue';
import { ref, onMounted, onUpdated } from 'vue';
import { Modification, ModificationAction } from '@/models/modification';
import { ApplicationDetailsModel } from '@etp/etp-authorization-client/axios';
import { roles_engagement_folderContributor } from '@/utils/variables';

export default {
  name: 'FolderApplicationsModal',
  components: {
    BaseModal,
    ElDivider,
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    ElTable,
    ElTableColumn,
    ElTooltip
  },
  props: {
    dialogVisible: { type: Boolean, default: false },
    engagementApplications: {
      type: Array as () => Array<ApplicationDetailsModel>,
      required: true
    },
    targetScope: { type: String, required: true }
  },
  emits: ['close', 'update-applications'],
  setup(props, { emit }) {
    let localInheritedApplications = ref(new Array<ApplicationDetailsModel>());
    let localDirectApplications = ref(new Array<ApplicationDetailsModel>());
    let localAvailableApplications = ref(new Array<ApplicationDetailsModel>());
    let applicationsToBeAdded = ref(new Array<ApplicationDetailsModel>());
    let applicationsToBeDeleted = ref(new Array<ApplicationDetailsModel>());

    const sortList = (list: ApplicationDetailsModel[]) =>
      list.sort((a, b) => a.name.localeCompare(b.name));

    const updateLocalVars = (
      engagementApplications: Array<ApplicationDetailsModel>
    ) => {
      localInheritedApplications.value = new Array<ApplicationDetailsModel>();
      localDirectApplications.value = new Array<ApplicationDetailsModel>();
      for (var application of engagementApplications) {
        var inheritedRoleAssignments = application.roleAssignments.filter(
          ass =>
            ass.roleName == roles_engagement_folderContributor &&
            props.targetScope.startsWith(ass.scope) &&
            props.targetScope != ass.scope
        );
        if (inheritedRoleAssignments.length) {
          localInheritedApplications.value.push({
            ...application,
            roleAssignments: inheritedRoleAssignments
          });
        }
        var directRoleAssignments = application.roleAssignments.filter(
          ass =>
            ass.roleName == roles_engagement_folderContributor &&
            ass.scope == props.targetScope
        );
        if (directRoleAssignments.length) {
          localDirectApplications.value.push({
            ...application,
            roleAssignments: directRoleAssignments
          });
        }
      }
      localAvailableApplications.value = engagementApplications.filter(
        ea =>
          !localDirectApplications.value.find(
            da => ea.aadObjectId === da.aadObjectId
          )
      );
      localInheritedApplications.value = sortList(
        localInheritedApplications.value
      );
      localDirectApplications.value = sortList(localDirectApplications.value);
      localAvailableApplications.value = sortList(
        localAvailableApplications.value
      );
    };
    onMounted(() => updateLocalVars([...props.engagementApplications]));
    onUpdated(() => updateLocalVars([...props.engagementApplications]));

    const removeApplication = (
      index: number,
      application: ApplicationDetailsModel
    ) => {
      localDirectApplications.value.splice(index, 1);
      localAvailableApplications.value.push(application);
      localAvailableApplications.value = sortList(
        localAvailableApplications.value
      );
      applicationsToBeAdded.value = applicationsToBeAdded.value.filter(
        atba => atba.aadObjectId !== application.aadObjectId
      );
      applicationsToBeDeleted.value.push(application);
    };
    const addApplication = (
      index: number,
      application: ApplicationDetailsModel
    ) => {
      localDirectApplications.value.push(application);
      localDirectApplications.value = sortList(localDirectApplications.value);
      localAvailableApplications.value.splice(index, 1);
      applicationsToBeAdded.value.push(application);
      applicationsToBeDeleted.value = applicationsToBeDeleted.value.filter(
        atbr => atbr.aadObjectId !== application.aadObjectId
      );
    };

    const handleClose = () => {
      emit('close');
    };
    const confirm = () => {
      const removeModifications = [
        ...new Set(applicationsToBeDeleted.value)
      ].map(
        u =>
          new Modification<ApplicationDetailsModel>(
            u,
            ModificationAction.Remove
          )
      );
      const addModifications = [...new Set(applicationsToBeAdded.value)].map(
        u =>
          new Modification<ApplicationDetailsModel>(u, ModificationAction.Add)
      );
      emit('update-applications', removeModifications.concat(addModifications));
      applicationsToBeAdded.value = [];
      applicationsToBeDeleted.value = [];
    };

    return {
      addApplication,
      confirm,
      handleClose,
      localAvailableApplications,
      localDirectApplications,
      localInheritedApplications,
      removeApplication
    };
  }
};
</script>
<style lang="scss" scoped>
.application-dropdown {
  color: $etp-light-grey;
  background: $etp-grey-white-1;
  border-radius: 12px;
  width: 100%;
  border: 1px solid $etp-blue-white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  cursor: pointer;
}
.actions {
  cursor: pointer;
  transition: 250ms all cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.75;
}
</style>
