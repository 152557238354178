<template>
  <div v-if="!fullScreen" class="bs-1 glass-card menu-layout main-menu-pane">
    <the-engagement-selector
      :engagements="engagements"
      :current-engagement="currentEngagement"
      @click="changeEngagement"
    />
    <the-menu>
      <template #menu-contents>
        <hr class="border-line" />
        <el-menu-item
          index="Overview"
          :route="{ name: 'Overview' }"
          class="main-menu-item"
        >
          <template #title>
            <img
              class="menu-icon"
              src="@/assets/images/icons/new/home.svg"
              height="15"
              width="15"
            />
            <span>
              {{ $t('overview') }}
            </span>
          </template>
        </el-menu-item>
        <div>
          <el-sub-menu v-if="showImportMenuItem || showNmMenuItem" index="3">
            <template #title>
              <img
                class="menu-icon"
                src="@/assets/images/icons/new/database.svg"
                height="15"
                width="15"
              />
              <span>{{ $t('dataJourney') }}</span>
            </template>
            <el-menu-item
              v-if="showImportMenuItem"
              index="Import"
              :route="{ name: 'Import' }"
            >
              {{ $t('import') }}
            </el-menu-item>
            <el-menu-item
              v-if="showNmMenuItem"
              index="Model"
              :route="{ name: 'Model' }"
            >
              {{ $t('modeling') }}
            </el-menu-item>
            <el-menu-item
              v-if="showNmMenuItem && !isGainEngagement"
              index="Filtering"
              :route="{ name: 'Filtering' }"
            >
              {{ $t('filtering') }}
            </el-menu-item>
            <el-menu-item
              v-if="showNmMenuItem && !isGainEngagement"
              index="Labeling"
              :route="{ name: 'Labeling' }"
            >
              {{ $t('labeling') }}
            </el-menu-item>
            <el-menu-item
              v-if="showNmMenuItem && !isGainEngagement"
              index="Analytical"
              :route="{ name: 'Analytical' }"
            >
              {{ $t('analyticalModel') }}
            </el-menu-item>
            <el-menu-item
              v-if="showNmMenuItem"
              index="Testing"
              :route="{ name: 'Testing' }"
            >
              {{ $t('testing') }}
            </el-menu-item>
          </el-sub-menu>
          <el-menu-item
            v-if="showStorageItem"
            index="StorageManager"
            :route="{
              name: 'StorageManager',
              params: { storagePurpose: currentStoragePurpose, path: [] }
            }"
            class="main-menu-item"
          >
            <template #title>
              <img
                class="menu-icon"
                src="@/assets/images/icons/file.svg"
                height="15"
                width="15"
              />
              <span>
                {{ $t('storage') }}
              </span>
            </template>
          </el-menu-item>
          <el-menu-item
            v-if="showDashboardMenuItem"
            index="DashboardOverview"
            :route="{
              name: 'DashboardOverview'
            }"
            class="main-menu-item"
          >
            <template #title>
              <img
                class="menu-icon"
                src="@/assets/images/icons/new/dashboarding.svg"
                height="15"
                width="15"
              />
              <span>
                {{ $t('dashboards') }}
              </span>
            </template>
          </el-menu-item>
          <el-sub-menu
            v-if="
              showConfigurationMenuITem ||
              isGainEngagement ||
              showAccessMenuItem ||
              showCostsMenuItem ||
              showConnectorsMenuItem
            "
            index="6"
          >
            <template #title>
              <img
                class="menu-icon"
                src="@/assets/images/icons/new/settings.svg"
                height="15"
                width="15"
              />
              <span>{{ $t('administration') }}</span>
            </template>
            <el-menu-item
              v-if="showConfigurationMenuITem"
              index="Configuration"
              :route="{ name: 'Configuration' }"
            >
              {{ $t('configuration') }}
            </el-menu-item>
            <el-menu-item
              v-if="isGainEngagement"
              index="Gain"
              :route="{ name: 'GainOverview' }"
            >
              {{ $t('gain') }}
            </el-menu-item>
            <el-menu-item
              v-if="showCostsMenuItem"
              index="Costs"
              :route="{ name: 'Costs' }"
            >
              {{ $t('costs') }}
            </el-menu-item>
            <el-menu-item
              v-if="showConnectorsMenuItem"
              index="ConnectorsConfiguration"
              :route="{
                name: 'ConnectorsConfiguration',
                params: {
                  engagementId: currentEngagement.id
                }
              }"
            >
              {{ $t('connectors') }}
            </el-menu-item>
            <el-menu-item
              v-if="showAccessMenuItem"
              index="EngagementAccess"
              :route="{
                name: 'EngagementAccessOverview',
                params: {
                  engagementId: currentEngagement.id
                }
              }"
            >
              {{ $t('access') }}
            </el-menu-item>
          </el-sub-menu>
        </div>
      </template>
    </the-menu>
  </div>
  <div
    v-if="!loading"
    :class="'content-wrapper ' + [fullScreen ? 'full-screen' : '']"
  >
    <router-view :key="$route.fullPath" />
  </div>
</template>
<script lang="ts">
import { ElSubMenu, ElMenuItem } from 'element-plus';
import TheMenu from '@/components/layout/TheMenu.vue';
import TheEngagementSelector from '@/components/layout/TheEngagementSelector.vue';
import { computed, onMounted, ComputedRef } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import { EngagementStorageConfiguration } from '@etp/etp-storage-client/axios';

export default {
  name: 'HomeOverview',
  components: {
    TheMenu,
    TheEngagementSelector,
    ElSubMenu,
    ElMenuItem
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const fullScreen = computed(() => store.getters['base/getFullScreen']);

    store.commit('home/SET_LOADING', true);
    const loading = computed(() => store.getters['home/getLoading']);

    let currentEngagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['home/getCurrentEngagement']
    );
    const currentStoragePurpose: ComputedRef<string> = computed(
      () =>
        store.getters['storage/getCurrentConfiguration']?.storagePurpose ??
        store.getters['storage/getConfigurations']?.find(
          (c: EngagementStorageConfiguration) => c.storagePurpose?.length > 2
        )?.storagePurpose ??
        'main'
    );

    let engagements: ComputedRef<Array<EngagementModel>> = computed(
      () => store.getters['home/getAvailableEngagements']
    );

    let isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    let showDashboardPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showDashboardPages']
    );
    let showNodeModelGitPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showNodeModelGitPages']
    );
    let showPipelinePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showPipelinePages']
    );
    let showStoragePages: ComputedRef<boolean> = computed(
      () => store.getters['home/showStoragePages']
    );
    let showTaskSchedulerPages: ComputedRef<boolean> = computed(
      () => store.getters['home/showTaskSchedulerPages']
    );
    let userCanReadEngagementAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementAuthorization']
    );
    let userCanReadEngagementGainImportConfig: ComputedRef<boolean> = computed(
      () =>
        store.getters[
          'userPermissions/canReadEngagementGainImportConfiguration'
        ]
    );
    let userCanReadEngagementNodeModelGit: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementNodeModelGit']
    );
    let userCanReadEngagementPipelines: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementPipelines']
    );
    let userCanReadEngagementPowerBiDashboards: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementPowerBiDashboards']
    );
    let userCanReadEngagementRbacTasks: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadEngagementRbacTasks']
    );
    let userCanReadEngagementTaskSchedulerTasks: ComputedRef<boolean> =
      computed(
        () =>
          store.getters['userPermissions/canReadEngagementTaskSchedulerTasks']
      );
    let userCanReadTenantCosts: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canReadTenantCosts']
    );
    let userCanWriteEngagementConnectorsConfig: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementConnectorsConfig']
    );

    const showAccessMenuItem: ComputedRef<boolean> = computed(
      () =>
        !!userCanReadEngagementAuthorization.value ||
        !!userCanReadEngagementRbacTasks.value
    );
    const showConfigurationMenuITem: ComputedRef<boolean> = computed(
      () => !!currentEngagement.value.id && !isGainEngagement.value
    );
    const showConnectorsMenuItem: ComputedRef<boolean> = computed(
      () =>
        showPipelinePages.value && userCanWriteEngagementConnectorsConfig.value
    );
    const showCostsMenuItem: ComputedRef<boolean> = computed(
      () => userCanReadTenantCosts.value
    );
    const showDashboardMenuItem: ComputedRef<boolean> = computed(
      () =>
        showDashboardPages.value && userCanReadEngagementPowerBiDashboards.value
    );
    const showImportMenuItem: ComputedRef<boolean> = computed(
      () =>
        (showTaskSchedulerPages.value &&
          userCanReadEngagementTaskSchedulerTasks.value) ||
        (showPipelinePages.value && userCanReadEngagementPipelines.value) ||
        (isGainEngagement.value && userCanReadEngagementGainImportConfig.value)
    );
    const showNmMenuItem: ComputedRef<boolean> = computed(
      () =>
        (showNodeModelGitPages.value &&
          userCanReadEngagementNodeModelGit.value) ||
        (showPipelinePages.value && userCanReadEngagementPipelines.value)
    );
    const showStorageItem: ComputedRef<boolean> = computed(
      () => showStoragePages.value
    );

    const resetStores = async () => {
      store.dispatch('analytical/resetState');
      store.dispatch('filtering/resetState');
      store.dispatch('labeling/resetState');
      store.dispatch('model/resetState');
      store.dispatch('testing/resetState');
      store.dispatch('storage/resetState');
      store.dispatch('import/resetState');
      store.dispatch('userPermissions/resetEngagementsTenantsState');
      if (router.currentRoute.value.name == 'Overview') {
        await store.dispatch('dashboard/fetchDashboards');
        await store.dispatch('overview/fetchLastPipelineRunsOverview');
      }
    };

    const checkPermissions = async () => {
      await store.dispatch(
        'userPermissions/checkIfCanReadEngagementAuthorization'
      );
      await store.dispatch('userPermissions/checkIfCanReadEngagementRbacTasks');
      if (showStoragePages.value) {
        await store.dispatch('userPermissions/checkIfCanReadEngagementFolders');
        await store.dispatch('storage/fetchConfigurations');
      }
      if (isGainEngagement.value) {
        await store.dispatch(
          'userPermissions/checkIfCanWriteEngagementGainImportConfiguration'
        );
      } else {
        await store.dispatch('userPermissions/checkIfCanReadTenantCosts');
      }
      if (showNodeModelGitPages.value) {
        await store.dispatch(
          'userPermissions/checkIfCanReadEngagementNodeModelGit'
        );
      }
      if (showPipelinePages.value) {
        await store.dispatch(
          'userPermissions/checkIfCanReadEngagementPipelines'
        );
        if (!isGainEngagement.value) {
          await store.dispatch(
            'userPermissions/checkIfCanWriteEngagementConnectorsConfig'
          );
        }
      }
      if (showDashboardPages.value) {
        await store.dispatch(
          'userPermissions/checkIfCanReadEngagementPowerBiDashboards'
        );
      }
      if (showTaskSchedulerPages.value) {
        await store.dispatch(
          'userPermissions/checkIfCanReadEngagementTaskSchedulerTasks'
        );
      }
    };

    const changeEngagement = async (engagement: EngagementModel) => {
      if (!engagement.id) return;
      if (engagement?.id != currentEngagement.value?.id) {
        await store.dispatch('home/fetchCurrentEngagement', engagement.id);
        await resetStores();
        await checkPermissions();
        router.push({ name: 'Overview' });
      }
    };

    onMounted(async () => {
      await store.dispatch('home/fetchAvailableEngagements');
      await store.dispatch('home/fetchLastCurrentEngagement');
      if (currentEngagement.value.id) {
        await checkPermissions();
      }
      store.commit('home/SET_LOADING', false);
    });

    return {
      currentEngagement,
      currentStoragePurpose,
      engagements,
      changeEngagement,
      isGainEngagement,
      loading,
      fullScreen,
      showAccessMenuItem,
      showConfigurationMenuITem,
      showConnectorsMenuItem,
      showCostsMenuItem,
      showDashboardMenuItem,
      showImportMenuItem,
      showNmMenuItem,
      showStorageItem
    };
  }
};
</script>
<style scoped lang="scss">
.border-line {
  border: 0.5px solid $etp-deloitte-white;
  background: $etp-deloitte-white;
  width: 100%;
  margin: 1em 0 1.25em;
}
.menu-layout {
  padding-top: 7.5em;
  border-radius: 0px 1.5em 1.5em 0px;
}
.content-wrapper {
  padding: 7.5em 6em 3em 5em;
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  width: 100%;
  &.full-screen {
    padding: 0;
  }
}
::-webkit-scrollbar {
  display: none;
}
.menu-icon {
  filter: invert(62%) sepia(4%) saturate(1076%) hue-rotate(179deg)
    brightness(90%) contrast(96%);
}
</style>
