import config from '@/config/config';
import { EngagementStorageConfiguration } from '@etp/etp-storage-client/axios';

const CreateSystemName = (displayName: string): string => {
  return displayName
    .replace(/[^0-9a-z- ]/gi, '')
    .trim()
    .replace(/  +/g, ' ')
    .replaceAll(' ', '-')
    .replace(/--+/g, '-')
    .toLowerCase();
};

const FileUploadSupported = (
  pathPrefix: string,
  currentPath: Array<string>
): boolean => {
  const cloudProvider = config.cloudProvider.toLowerCase();
  if (
    cloudProvider == 'azure' &&
    !currentPath.length &&
    !pathPrefix.includes('/')
  )
    return false;
  return true;
};

const GetPathPrefix = (esc: EngagementStorageConfiguration): string => {
  return JSON.parse(esc.configuration || '{}')['PathPrefix'] ?? '';
};

const IsBlankPath = (path: Array<string>): boolean => {
  return !path || !path.length || !path[0].trim();
};

export { CreateSystemName, FileUploadSupported, GetPathPrefix, IsBlankPath };
