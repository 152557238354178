import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/icons/new/where_you_left_off.svg'
import _imports_1 from '@/assets/images/icons/new/quick_action.svg'


const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["href"]
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "bs-1 glass-card overview-card-pipelineruns" }
const _hoisted_6 = {
  key: 0,
  class: "bs-1 mb-4 glass-card overview-card-dashboards"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_panel_header = _resolveComponent("panel-header")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_quick_action_panel = _resolveComponent("quick-action-panel")!
  const _component_last_pipeline_runs_panel = _resolveComponent("last-pipeline-runs-panel")!
  const _component_dashboards_panel = _resolveComponent("dashboards-panel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 17 }, {
          default: _withCtx(() => [
            _createVNode(_component_page_header, {
              "main-title": _ctx.$t('overview')
            }, {
              subheader: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('welcomeBack') +
              ' ' +
              $setup.currentUser.displayName +
              '!' +
              ' ' +
              _ctx.$t('yourOverview')), 1)
              ]),
              _: 1
            }, 8, ["main-title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      gutter: 24,
      class: "mt-6"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 5,
          class: "mr-7"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, { gutter: 4 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, {
                  span: 24,
                  class: "bs-1 glass-card overview-card-whereyouleftoff"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_panel_header, {
                      "main-title": _ctx.$t('whereYouLeftOff')
                    }, {
                      avatar: _withCtx(() => _cache[0] || (_cache[0] = [
                        _createElementVNode("img", {
                          src: _imports_0,
                          height: "16",
                          width: "16"
                        }, null, -1)
                      ])),
                      _: 1
                    }, 8, ["main-title"]),
                    ($setup.whereYouLeftOff)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                          _createElementVNode("h4", null, _toDisplayString($setup.whereYouLeftOff.displayPath), 1),
                          _createElementVNode("a", {
                            href: $setup.whereYouLeftOff.fullPath
                          }, [
                            _createVNode(_component_el_button, {
                              type: "primary",
                              class: "mt-3"
                            }, {
                              default: _withCtx(() => [
                                _createElementVNode("h4", null, _toDisplayString(_ctx.$t('continue')), 1)
                              ]),
                              _: 1
                            })
                          ], 8, _hoisted_3)
                        ]))
                      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('thereIsNoActivityYet')), 1)
                        ]))
                  ]),
                  _: 1
                }),
                (
            $setup.currentEngagement &&
            $setup.showImportStoragePages &&
            $setup.showPipelinePages &&
            !$setup.isGainEngagement
          )
                  ? (_openBlock(), _createBlock(_component_el_col, {
                      key: 0,
                      span: 24,
                      class: "bs-1 mt-4 glass-card overview-card-quickactions"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_panel_header, {
                          "main-title": _ctx.$t('quickActions')
                        }, {
                          avatar: _withCtx(() => _cache[1] || (_cache[1] = [
                            _createElementVNode("img", {
                              src: _imports_1,
                              height: "16",
                              width: "16"
                            }, null, -1)
                          ])),
                          _: 1
                        }, 8, ["main-title"]),
                        _createElementVNode("div", null, [
                          _createVNode(_component_quick_action_panel)
                        ])
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        ($setup.currentEngagement && $setup.showPipelinePages)
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 0,
              span: 5,
              class: "mr-7"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_panel_header, {
                    "main-title": _ctx.$t('pipelineRuns')
                  }, null, 8, ["main-title"]),
                  _createVNode(_component_last_pipeline_runs_panel, { "pipeline-runs": $setup.lastPipelineRunsOverview }, null, 8, ["pipeline-runs"])
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        ($setup.currentEngagement)
          ? (_openBlock(), _createBlock(_component_el_col, {
              key: 1,
              span: 5
            }, {
              default: _withCtx(() => [
                ($setup.showDashboardPages)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                      _createVNode(_component_panel_header, {
                        "main-title": _ctx.$t('dashboards')
                      }, null, 8, ["main-title"]),
                      _createVNode(_component_dashboards_panel)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}