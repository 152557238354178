import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mt-5" }
const _hoisted_2 = { class: "mt-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tab = _resolveComponent("base-tab")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, { "main-title": $setup.pageHeader }, {
      tabs: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          ($setup.isGainEngagement)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: { name: 'GainImportFiles' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('filesGain')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.isGainEngagement)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: 'GainImportTables' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('tablesGain')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.importStorageExists)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: {
            name: 'ImportFolders',
            params: { storagePurpose: 'import', path: [] }
          }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('folders')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (
            $setup.showTaskSchedulerPages && $setup.userCanReadEngagementTaskSchedulerTasks
          )
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: { name: 'ImportScheduler' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('tasks')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.showPipelinePages)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                to: { name: 'ImportMonitor' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('monitor')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "horizontal-line mt-2" }, [
            _createElementVNode("hr")
          ], -1))
        ])
      ]),
      _: 1
    }, 8, ["main-title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}