<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ engagementApplications.length }} &#183;
      {{ $t('applications') }}
    </h4>
    <el-button
      v-if="userCanWriteEngagementAuthorization"
      :disabled="loading"
      class="ml-auto"
      type="primary"
      @click="showAddRoleAssignmentModal = true"
    >
      <h4>{{ $t('addApplication') }}</h4>
    </el-button>
    <el-button
      v-if="userCanWriteEngagementRbac"
      :disabled="loading"
      type="primary"
      @click="applyApplicationsRbac"
    >
      <h4>
        {{ $t('syncRoles') }}
      </h4>
    </el-button>
  </el-row>
  <table-placeholder-loading v-if="loading" :columns="4" />
  <el-table v-else :data="engagementApplications">
    <el-table-column width="200">
      <template #header>
        {{ showObjectId ? $t('objectId') : $t('name') }}
      </template>
      <template #default="scope">
        {{ showObjectId ? scope.row.aadObjectId : scope.row.name }}
      </template>
    </el-table-column>
    <el-table-column>
      <template #header>
        <el-row>
          <el-col :span="6">
            {{ $t('role') }}
          </el-col>
          <el-col :span="17">
            {{ $t('scope') }}
          </el-col>
        </el-row>
      </template>
      <template #default="scope">
        <el-row
          v-for="roleAssignment in scope.row.roleAssignments.sort(compareRoleAssignments) as Array<RoleAssignmentModel>"
          :key="roleAssignment.roleName"
        >
          <el-col :span="6">
            <span class="body-1">
              {{ roleAssignment.roleName }}
            </span>
          </el-col>
          <el-col :span="17">
            <span class="body-1">
              {{ roleAssignment.scope }}
            </span>
          </el-col>
          <el-col :span="1" class="d-flex align-items-center">
            <img
              v-if="userCanWriteEngagementAuthorization"
              :src="require(`@/assets/images/icons/new/delete.svg`)"
              height="12"
              width="12"
              class="ml-auto action-icon"
              @click="openRemoveRoleAssignmentModal(roleAssignment)"
            />
          </el-col>
        </el-row>
      </template>
    </el-table-column>
    <el-table-column v-if="userCanWriteEngagementAuthorization" width="55">
      <template #default="scope">
        <div class="d-flex align-items-center">
          <img
            :src="require(`@/assets/images/icons/bin.svg`)"
            class="icon-size-7 cursor-pointer"
            @click="removeApplication(scope.row)"
          />
        </div>
      </template>
    </el-table-column>
  </el-table>
  <el-row class="d-flex">
    <base-toggle-button
      v-model="showObjectId"
      :active-text="$t('objectId')"
      :inactive-text="$t('name')"
      class="ml-auto"
    />
  </el-row>
  <add-application-role-assignment-modal
    :modal-visible="showAddRoleAssignmentModal"
    :applications="allApplications"
    :roles="allRoles"
    :scope="GetScopeForEngagement(engagementId)"
    @confirm="addRoleAssignments"
    @close="showAddRoleAssignmentModal = false"
  />
  <base-confirmation-modal
    v-model="showRemoveRoleAssignmentModal"
    :content="$t('confirmRemoveUserFromEngagement')"
    :feedback-text="$t('engagementApplicationDeleted')"
    @confirm="removeRoleAssignment"
    @close="showRemoveRoleAssignmentModal = false"
  />
</template>
<script lang="ts">
import {
  ElRow,
  ElCol,
  ElTable,
  ElTableColumn,
  ElButton,
  ElMessage
} from 'element-plus';
import AddApplicationRoleAssignmentModal from '@/components/modals/AddApplicationRoleAssignmentModal.vue';
import BaseConfirmationModal from '@/components/modals/BaseConfirmationModal.vue';
import BaseToggleButton from '@/components/buttons/BaseToggleButton.vue';
import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import {
  GetScopeForEngagement,
  GetScopeMaskForEngagement
} from '@/utils/scopeHelper';
import {
  ApplicationDetailsModel,
  RoleAssignmentModel,
  RoleModel,
  ApplicationModel,
  UpdateRoleAssignmentModel,
  RoleAssignmentAction,
  PrincipalType
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';
import config from '@/config/config';
import { compareRoleAssignments } from '@/utils/roleAssignmentFunctions';

export default {
  name: 'EngagementApplications',
  components: {
    AddApplicationRoleAssignmentModal,
    BaseConfirmationModal,
    BaseToggleButton,
    ElButton,
    ElRow,
    ElCol,
    ElTable,
    ElTableColumn,
    TablePlaceholderLoading
  },
  setup() {
    let engagementId = ref('');
    let showAddRoleAssignmentModal = ref(false);
    const roleAssignmentToRemove = ref({} as RoleAssignmentModel);
    const showRemoveRoleAssignmentModal = ref(false);
    const showObjectId = ref(false);
    const loading = ref(false);

    const router = useRouter();
    const store = useStore();

    const engagementApplications: ComputedRef<Array<ApplicationDetailsModel>> =
      computed(() => store.getters['engagements/getEngagementApplications']);
    const allApplications: ComputedRef<Array<ApplicationModel>> = computed(
      () => store.getters['applications/getApplications']
    );
    const allRoles: ComputedRef<Array<RoleModel>> = computed(
      () => store.getters['roles/getRoles']
    );
    const userCanWriteEngagementAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementAuthorization']
    );
    const userCanWriteEngagementRbac: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementRbac']
    );

    const fetchEngagementApplications = async () =>
      await store.dispatch(
        'engagements/fetchEngagementApplications',
        engagementId.value
      );

    const openRemoveRoleAssignmentModal = (
      roleAssignment: RoleAssignmentModel
    ) => {
      roleAssignmentToRemove.value = roleAssignment;
      showRemoveRoleAssignmentModal.value = true;
    };

    const removeRoleAssignment = async () => {
      loading.value = true;
      showRemoveRoleAssignmentModal.value = false;
      await store.dispatch('roles/updateRoleAssignments', [
        {
          principalId: roleAssignmentToRemove.value.principalId,
          principalType: PrincipalType.Application,
          roleName: roleAssignmentToRemove.value.roleName,
          scope: roleAssignmentToRemove.value.scope,
          action: RoleAssignmentAction.Remove
        } as UpdateRoleAssignmentModel
      ]);
      if (
        config.cloudProvider.toLowerCase() !== 'aws' &&
        roleAssignmentToRemove.value.scope ==
          GetScopeForEngagement(engagementId.value)
      ) {
        await store.dispatch('engagements/applyApplicationRbac', {
          aadObjectId: roleAssignmentToRemove.value.principalId
        });
      }
      await fetchEngagementApplications();
      loading.value = false;
    };

    const removeApplication = async (app: ApplicationDetailsModel) => {
      loading.value = true;
      await store.dispatch(
        'roles/updateRoleAssignments',
        app.roleAssignments.map((ass: RoleAssignmentModel) => {
          return {
            principalId: ass.principalId,
            principalType: PrincipalType.Application,
            roleName: ass.roleName,
            scope: ass.scope,
            action: RoleAssignmentAction.Remove
          } as UpdateRoleAssignmentModel;
        })
      );
      if (config.cloudProvider.toLowerCase() !== 'aws') {
        await store.dispatch('engagements/applyApplicationRbac', {
          aadObjectId: app.aadObjectId
        });
      }
      await fetchEngagementApplications();
      loading.value = false;
    };

    const addRoleAssignments = async (
      applicationRoleAssignments: Array<RoleAssignmentModel>
    ) => {
      loading.value = true;
      showAddRoleAssignmentModal.value = false;
      await store.dispatch(
        'roles/updateRoleAssignments',
        applicationRoleAssignments.map((ass: RoleAssignmentModel) => {
          return {
            principalId: ass.principalId,
            principalType: PrincipalType.Application,
            roleName: ass.roleName,
            scope: ass.scope,
            action: RoleAssignmentAction.Add
          } as UpdateRoleAssignmentModel;
        })
      );
      if (config.cloudProvider.toLowerCase() !== 'aws') {
        await store.dispatch('engagements/applyApplicationRbac', {
          engagementId: engagementId.value,
          aadObjectId: applicationRoleAssignments[0].principalId
        });
      }
      ElMessage({
        showClose: true,
        message: i18n.global.t('rolesAddedSuccessfully')
      });
      await fetchEngagementApplications();
      loading.value = false;
    };

    const applyApplicationsRbac = async () => {
      loading.value = true;
      for (let application of engagementApplications.value) {
        await store.dispatch('engagements/applyApplicationRbac', {
          engagementId: engagementId.value,
          aadObjectId: application.aadObjectId
        });
      }
      ElMessage({
        showClose: true,
        message: i18n.global.t('rolesSyncedSuccessfully')
      });
      loading.value = false;
    };

    onMounted(async () => {
      loading.value = true;
      engagementId.value = router.currentRoute.value.params
        .engagementId as string;
      await fetchEngagementApplications();
      await store.dispatch('applications/fetchAllApplications');
      await store.dispatch('roles/fetchAllRoles', GetScopeMaskForEngagement());
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementAuthorization'
      );
      await store.dispatch('userPermissions/checkIfCanWriteEngagementRbac');
      loading.value = false;
    });

    return {
      addRoleAssignments,
      allApplications,
      allRoles,
      applyApplicationsRbac,
      compareRoleAssignments,
      engagementApplications,
      engagementId,
      GetScopeForEngagement,
      fetchEngagementApplications,
      loading,
      openRemoveRoleAssignmentModal,
      removeApplication,
      removeRoleAssignment,
      showAddRoleAssignmentModal,
      showRemoveRoleAssignmentModal,
      showObjectId,
      userCanWriteEngagementAuthorization,
      userCanWriteEngagementRbac
    };
  }
};
</script>
<style lang="scss" scoped></style>
