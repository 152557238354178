import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "500px",
    "modal-title": _ctx.$t('addNewFolder'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    onConfirm: _cache[2] || (_cache[2] = ($event: any) => ($setup.confirm())),
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      _createVNode(_component_el_form, {
        ref: "formRef",
        model: $setup.form,
        "label-position": "top",
        rules: $setup.formRules,
        inline: true
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('name'),
            prop: "displayName",
            class: "mr-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.displayName,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.displayName) = $event)),
                size: "small",
                onInput: $setup.handleDisplayNameInput
              }, null, 8, ["modelValue", "onInput"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$t('resultingSystemName'),
            prop: "systemName"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: $setup.form.systemName,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.systemName) = $event)),
                disabled: "",
                size: "small"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["model", "rules"])
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onClose"]))
}