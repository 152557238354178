{
  "signIn": "Log in",
  "loginMessage": "Welkom terug, log alstublieft in om verder te gaan naar de applicatie",
  "login": "Log in",
  "etpMobile": "ENTERPRISE TAX PLATFORM",
  "welcome": "Welkom",
  "welcomeBack": "Welkom terug",
  "yourOverview": "Dit is jouw overzicht, met snelle acties.",
  "welcomeMessage": "Ontdek het beste van het tax analytics platform",
  "etpVersion": "Versie 1.0",
  "welcomeText": "Etp is leidend op het gebied van het verwerken en draaien van fiscales analyses voor uw onderneming",
  "addNewDatasource": "Voeg databron toe",
  "secureConnection": "Veilige verbindingen",
  "secureConnectionSubtitle": "Bijvoorbeeld: oracle, sap",
  "specifyKindNewSource": "Specifieer welk type nieuwe bron wordt toegevoegd",
  "nameOfSource": "Naam van bron",
  "headerTextReplace": ";",
  "healthCheck": "Healthcheck",
  "fileTextQualifier": "Quote character",
  "fileDelimiter": "Bestands afbakener:",
  "succeeded": "geslaagd",
  "failed": "mislukt",
  "editFolder": "Map bewerken",
  "pleaseProvideName": "Voer de naam in",
  "userAdded": "Verzoek om gebruiker toe te voegen verzonden.",
  "mayTakeMoment": "Dit kan een ogenblik duren.",
  "youShallNotPass": "U heeft geen toegang tot deze bron",
  "contactGeenen": "Neem alstublieft contact op met de administrator.",
  "fillInRequiredSettingsSource": "Vul de vereiste instellingen voor het maken van een bron.",
  "fileLine": "Lijneinde",
  "options": "Opties",
  "analysisServices": "Analyse diensten",
  "getStarted": "Begin",
  "actions": "Acties",
  "loading": "Bezig met laden",
  "loaded": "Geladen",
  "quoteChar": "Citaat Char",
  "headerFileLine": "Koptekstbestandsregel:",
  "fileUnicode": "Unicode bestand:",
  "fileIsQueued": "Bestand wordt gestageerd",
  "fileAreQueued": "Bestanden worden gestageerd",
  "fileHasHeader": "Bestand heeft een kop:",
  "fileTextQualified": "Bestandstekst gekwalificeerd:",
  "validateParameters": "Valideer de ingevoerde parameters.",
  "createTask": "Maak eerst uw taak.",
  "startWizard": "Tovenaar",
  "addDatasource": "Nieuwe bron",
  "parameters": "Parameters",
  "addMoreFiles": "Meer bestanden toevoegen",
  "confirmationRequired": "Bevestiging vereist",
  "validSourceName": "Voer een geldige bronnaam in.",
  "task": "Taak",
  "activated": "Geactiveerd",
  "lastReload": "Herladen",
  "userSettings": "Gebruikersinstellingen",
  "settings": "Instellingen",
  "open": "Open",
  "strangeHappened": "Er is iets vreemds gebeurd..",
  "noRetrievingDatasources": "De gegevensbronnen konden niet worden opgehaald.",
  "oracle": "Oracle",
  "sqlDb": "SQL DB",
  "close": "Sluiten",
  "vatReturns": "Btw-aangiften",
  "filtering": "Filtering",
  "materials": "Materiaal",
  "invoices": "Facturen",
  "companies": "Bedrijven",
  "fiscalCalendar": "Fiscale kalender",
  "lastUpload": "Laatste upload",
  "uploadAll": "Alles uploaden",
  "loggedInAs": "Ingelogd als",
  "help&feedback": "Hulp & feedback",
  "gettingStartedGuide": "Aan de slag gids",
  "profile": "Profiel",
  "profileSettings": "Profielinstellingen",
  "helpCenter": "Helpcentrum",
  "contactUs": "Neem contact met ons op",
  "selectDatanodeFirst": "en selecteer eerst een gegevensknooppunt.",
  "about": "Over",
  "privacy": "Privacy",
  "privacyStatement": "Privacy verklaring",
  "termsOfUse": "Gebruiksvoorwaarden",
  "general": "Algemeen",
  "users": "Gebruikers",
  "tenants": "Huurders",
  "newField": "Nieuw veld",
  "addField": "veld toevoegen",
  "addFile": "Bestand toevoegen",
  "delimiter": "Scheidingsteken",
  "lineEnding": "Regeleinde",
  "lineEndingPlaceholder": "{CR}{LF}",
  "codeName": "Coderingsnaam",
  "utf8": "utf-8",
  "delete": "Verwijderen",
  "noSelectionDetected": "Geen selectie gedetecteerd",
  "pleaseGoTabNode": "Ga naar het tabblad 'knooppuntselectie'",
  "nameNewField": "Naam van nieuw veld",
  "language": "Taal",
  "logout": "Uitloggen",
  "labeling": "Labeling",
  "materialType": "Materiaaltype",
  "awaiting": "Wachten",
  "rebuildAll": "Alles opnieuw opbouwen",
  "activateAll": "Alles activeren",
  "upload": "Uploaden",
  "download": "Downloaden",
  "userManagement": "Console voor gebruikersbeheer",
  "userManagementSubtitle": "Gebruikers toevoegen of verwijderen",
  "addUser": "Gebruiker toevoegen",
  "followInstructions": "Volg de onderstaande instructies.",
  "start": "Start",
  "name": "Naam",
  "results": "Resultaten",
  "addDashboard": "Dashboard toevoegen",
  "enterName": "Voer uw naam in.",
  "email": "E-mail",
  "sendAs": "Send as",
  "enterEmail": "E-mailadres invoeren.",
  "userRole": "Gebruikersrol",
  "applicationRole": "Applicatierol",
  "specifyNode": "Knooppunt opgeven voor bouwen.",
  "selectRole": "Rollen selecteren.",
  "selectTenant": "Huurders selecteren.",
  "seedDatabase": "Zaaddatabase",
  "provisionResources": "Voorzieningsresources",
  "save": "Opslaan",
  "forgotToSave": "Het lijkt erop dat u iets aan het wijzigen was.\nAls u het formulier sluit voordat u uw wijzigingen opslaat, dan gaan uw wijzigingen verloren.",
  "allUsers": "Alle gebruikers",
  "overview": "Overzicht",
  "paginated": "Gepagineerd",
  "tenantManagement": "Console voor huurdersbeheer",
  "addTenants": "huurders toevoegen of bijwerken",
  "activeDirectoryId": "Active Directory ID",
  "resourceGroupName": "Resourcegroepnaam",
  "resourceName": "Resource naam",
  "resourceConfiguration": "Resource configuratie",
  "orchestrator": "Orchestrator",
  "processFunctions": "Procesfuncties",
  "activeUsers": "Actieve gebruikers",
  "functions": "Functies",
  "dataFactory": "Gegevensfabriek",
  "azureDataFactory": "Azure-gegevensfabriek",
  "azureDatabricks": "Azure-gegevensstenen",
  "addTenant": "Huurder toevoegen",
  "usersTenant": "Gebruikers Huurder",
  "usersDeloitte": "Gebruikers Deloitte",
  "costMtd": "Kosten huidige maand",
  "goBack": "Terug",
  "costLastMonth": "Kosten vorige maand",
  "active": "Actief",
  "rejected": "Afgekeurd",
  "reviewing": "Herziening",
  "approved": "Goedgekeurd",
  "downloadTemplate": "Sjabloon downloaden",
  "uploadTemplate": "Sjabloon uploaden",
  "approve": "Goedkeuren",
  "reject": "Afwijzen",
  "setReminder": "Herinnering instellen",
  "reset": "Resetten",
  "help": "Help",
  "sortBy": "Sorteren op",
  "userAddSuccess": "De gebruiker is met succes toegevoegd/gewijzigd.",
  "fileUploadSuccess": "De file is met succes geüpload",
  "fileProcessingSuccess": "Het bestand is met succes verwerkt",
  "taskCompleteSuccess": "De taak is met succes verwerkt",
  "selectFollowUpAction": "Selecteer hieronder een vervolgactie.",
  "notification": "Meldingen",
  "markAsRead": "Alles markeren als gelezen",
  "tenant": "Huurder",
  "editTenant": "Huurder bewerken",
  "toggleActive": "Actief / Inactief",
  "copyValue": "Naar klembord kopiëren",
  "taxDataModel": "Belastinggegevensmodel",
  "mappings": "Toewijzingen",
  "completed": "Voltooid",
  "update": "Update",
  "openDashboard": "Open dashboard",
  "openDashboardNewTab": "Open dashboard (nieuwe tab)",
  "configure": "Configureren",
  "configuration": "Configuratie",
  "taskScheduler": "Taakplanner",
  "dataType": "Data Type",
  "source": "Bron",
  "sources": "Bronnen",
  "tables": "Tabellen",
  "powerBI": "Power BI",
  "FSI": "FSI",
  "used": "Gebruikt",
  "addLabels": "Nieuw label",
  "build": "Bouwen",
  "import": "Importeren",
  "modeling": "Modeling",
  "updateTenant": "Huurder bijwerken",
  "editUser": "Gebruiker bewerken",
  "updateUser": "Gebruiker bijwerken",
  "userSuccesAdded": "Gebruiker is succesvol toegevoegd / gewijzigd.",
  "reload": "Herladen",
  "datasources": "Gegevensbronnen",
  "success": "succes",
  "running": "bezig",
  "edit": "Bewerken",
  "azureSettings": "Azure-instellingen",
  "configured": "Geconfigureerd",
  "newUser": "Nieuwe gebruiker",
  "tenantFormName": "Naam huurder",
  "fillInTenantName": "Een naam invoeren voor de huurder die moet worden gemaakt",
  "dropFilesHere": "Plaats het bestand hier",
  "dropFileHere": "Klik hier om uw bestand te uploaden",
  "payAttention": "Let op de vereisten.",
  "fileStorage": "Bestandsopslag",
  "uploadFiles": "Bestanden uploaden",
  "queryEditor": "Query Editor",
  "configureDataNodes": "Gegevensknooppunten configureren",
  "selectionModel": "Selecteer uw model",
  "fields": "Velden",
  "dataNodes": "Gegevensknooppunten",
  "nodes": "Knooppunten",
  "solution": "Oplossing",
  "node": "Knoop",
  "version": "Versie",
  "addNewBranch": "Nieuwe branch toevoegen",
  "addNewCategory": "Nieuwe category toevoegen",
  "addNewSource": "Nieuwe node toevogen",
  "categories": "Categories",
  "editCategories": "Categories bewerken",
  "parentBranch": "Parent branch",
  "branchName": "Branch naam",
  "editModule": "Module bewerken",
  "savedAsVersion": "Opgeslagen als versie",
  "overwrite": "Overschrijven",
  "saveConfiguration": "Configuratie opslaan",
  "newTenant": "Nieuwe huurder",
  "dataFactorySubheader": "Dit is een service waarmee ontwikkelaars verschillende gegevensbronnen kunnen integreren.",
  "databricks": "Gegevensstenen",
  "databricksSubheader": "Dit geeft gebruikers één platform voor Big Data processing en Machine Learning.",
  "oops": "Oeps!",
  "importAll": "Alles importeren",
  "somethingStrange": "Er is iets vreemds gebeurd.",
  "keepCalm": "Blijf kalm en probeer het opnieuw.",
  "tryAgain": "Probeer het opnieuw",
  "valid": "Geldig",
  "personalInformation": "Persoonlijke informatie",
  "fillInPersonalInformation": "De persoonlijke gegevens van een gebruiker invullen of aanpassen",
  "invalid": "Ongeldig",
  "roles": "Rollen",
  "test": "Test",
  "addNewTest": "Nieuwe test toevoegen",
  "fillInRoles": "Definieer de rollen en wijs huurders toe aan de gebruiker",
  "pipeline": "Pijplijn",
  "pipelines": "Pijplijnen",
  "template": "Template",
  "na": "N.V.T",
  "analyticalModelStatus": "Analytische status",
  "initiate": "Initiëren",
  "encoding": "Codering",
  "modelStatus": "Modelstatus",
  "copyString": "Kopiëren",
  "testingModelStatus": "Teststatus",
  "nodeSchema": "Knooppuntschema",
  "nodeProcessing": "Knooppuntverwerking",
  "nodeType": "Knooppunttype",
  "newNodeName": "Naam van het nieuwe knooppunt",
  "initialVersionName": "Oorspronkelijke versienaam",
  "createNewUser": "Nieuwe gebruiker maken",
  "anyUsersAddedListed": "Gebruiker(s) toegevoegd wordt(en) hier vermeld.",
  "nodeSequence": "Knooppuntvolgorde",
  "addNode": "Knooppunt toevoegen",
  "createNode": "Nieuw knooppunt maken",
  "buildNodes": "Gegevensknooppunten bouwen",
  "rebuild": "Herbouwen",
  "toggleRebuild": "Opnieuw opbouwen in- en uitschakelen",
  "configureBuild": "Build configureren",
  "pipelineName": "Stapel",
  "openServiceWindow": "Service openen in een nieuw tabblad / venster",
  "link": "Link naar uitvoering",
  "status": "Status",
  "runStart": "Starttijd",
  "runEnd": "Eindtijd",
  "monitorExecution": "Koppeling naar uitvoeringsdetails",
  "category": "Categorie",
  "az": "A-Z",
  "dashboards": "Dashboards",
  "dashboardsDesc": "Het platform heeft een ingebouwde dashboardfunctie. Gegevens kunnen worden geïmporteerd in het dashboard en het dashboard kan worden geüpload en gepubliceerd in het platform.",
  "reportName": "Dashboard naam",
  "discard": "Weggooien",
  "discardAll": "Gooi alles weg",
  "replaceFile": "Bestand vervangen",
  "administration": "Beheer",
  "azureServices": "Azure-services",
  "overviewAzureServcies": "Overzicht van Azure-services",
  "none": "Geen",
  "user": "Gebruiker",
  "selectApplication": "Selecteer een applicatie",
  "selectUser": "Selecteer een gebruiker",
  "available": "Beschikbaar",
  "pleaseWaitWhile": "Dit kan enkele minuten duren..",
  "pipelineMonitor": "Pijplijn Monitor",
  "console": "Console",
  "newNode": "Nieuw knooppunt",
  "selectNodeForBuilding": "Knooppunten selecteren om model te bouwen",
  "makeSelection": "Maak selectie in de console aan de rechterkant van het scherm,",
  "buildCnm": "om te beginnen met het opbouwen van het gemeenschappelijke knooppuntmodel",
  "editQueries": "om te beginnen met het bewerken van de query's.",
  "setNodeActive": "Knooppunt instellen op status actief",
  "nodeWillOverwritten": "Knooppunt wordt overschreven.",
  "selectNodesEditQueries": "Knooppunten selecteren om query's te bewerken",
  "reloadAll": "Alles opnieuw laden",
  "tenantName": "Naam",
  "storageAccountName": "Naam opslagaccount",
  "eventGridTopic": "Onderwerp gebeurtenisraster",
  "eventGridSubscription": "Abonnement op gebeurtenisraster",
  "destination": "Bestemming",
  "functionAppName": "Naam functie-app",
  "tenantId": "Huurder ID",
  "projectDatabaseConnectionstring": "Verbindingsreeks projectdatabase",
  "stagingDatawarehouseConnectionstring": "Koppelingsreeks voor datawarehouses faseren",
  "modelDatawarehouseConnectionstring": "Verbindingsreeks voor modeldatawarehouse",
  "createResourceGroup": "Resourcegroep maken",
  "dashboardsUnavailable": "Schakel de Power BI- en Analysis Service in om de dashboards weer te geven.",
  "pausing": "Onderbreken",
  "resuming": "Hervatten",
  "paused": "Gepauzeerd",
  "refreshResourceStatus": "Status vernieuwen",
  "reprocess": "Opwerking",
  "reprocessedMessage": "Bestand wordt opnieuw geïmporteerd.",
  "powerBi": "Power BI",
  "versionNameToSave": "Versienaam om op te slaan",
  "copied": "Gekopieerd!",
  "errorReprocessing": "Fout opwerking",
  "building": "Bouwen!",
  "configuring": "Configureren!",
  "etp": "ETPcore",
  "core": "core.",
  "byDeloitte": "door Deloitte",
  "empty": "Leeg",
  "0": "0.0",
  "seq": "Seq X.X",
  "hybridDataIntegration": "Hybride gegevensintegratie",
  "apacheSparkBased": "Apache Spark-gebaseerd analyseplatform",
  "synapseStaging": "Synaps enscenering",
  "analyticalService": "Analytische service",
  "synapseNodeModel": "Synaps knooppuntmodel",
  "projectManagementDatabase": "Database voor projectbeheer",
  "selectTestEditQueries": "Selecteer Testen om query's te bewerken",
  "activity": "Activiteit",
  "details": "Bijzonderheden",
  "testSelection": "Testselectie",
  "newTest": "Nieuwe test",
  "updateTestMeta": "Testmeta bijwerken",
  "updateQuery": "Query bijwerken",
  "subscriptionId": "Abonnements-ID",
  "dataFactoryName": "Naam datafabriek",
  "analysisServicesConnectionString": "Verbindingsreeks analyseservices",
  "analysisServiceDescription": "Onderliggend model voor Power BI",
  "manuallyAdded": "moet handmatig worden toegevoegd aan de modellaag.",
  "contactAdmin": "Neem contact op met de beheerder.",
  "notPresent": "Absent",
  "powerBiEmbeddedResourceName": "Power Bi-ingesloten resourcenaam",
  "workspaceName": "Naam werkruimte",
  "sqlInCloud": "SQL in de cloud",
  "solutionNode": "Oplossingsknooppunt",
  "description": "Beschrijving",
  "businessKey": "Bedrijfssleutel",
  "addNewField": "Nieuw veld toevoegen",
  "inactiveUser": "Gebruiker is niet actief",
  "ifThisIsMistake": "Als je denkt dat dit een vergissing is,",
  "testTopic": "Testonderwerp",
  "initialVersion": "Eerste versie",
  "testTheme": "Test Thema",
  "testDomain": "Test Domein",
  "testName": "Test Naam",
  "testNumber": "Test Nummer",
  "testId": "Test Id",
  "testDescription": "Beschrijving van de test",
  "userNotFound": "U bent geen geregistreerde gebruiker. Neem contact op met de beheerder.",
  "createNewTest": "Nieuwe test maken.",
  "noTenantSelected": "U hebt geen huurder geselecteerd. Selecteer eerst een huurder.",
  "utc": "UTC",
  "copy": "Kopiëren",
  "ap": "AP",
  "control": "Control",
  "validVat": "Geldige BTW",
  "vatVersion": "BTW_MODEL_100",
  "vatId": "BTW ID",
  "vat": "BTW",
  "putShortDescription": "Plaats hier een korte testbeschrijving.",
  "result": "Resultaat",
  "noTenantUser": "U bent niet toegewezen aan een huurder!",
  "enterUserName": "Voer de voor- en achternaam van de gebruiker in.",
  "enterValidEmail": "Het e-mailadres is ongeldig.",
  "selectValue": "Selecteer een waarde in de vervolgkeuzelijst.",
  "enterTenantName": "Voer de naam van de huurder in.",
  "cannotBeEmpty": "Dit veld mag niet leeg zijn of beginnen met speciale tekens.",
  "noSpaceUnderscore": "Deze veldwaarde mag geen andere spatie(s) of speciale tekens bevatten dan :./",
  "errorOccured": "Er is een fout opgetreden tijdens de uitvoering van uw aanvraag.",
  "errorNo": "Uw foutnummer is:",
  "useErrorNo": "Gebruik dit foutnummer en neem contact op met de beheerder.",
  "tenantSettings": "Huurder instellingen",
  "deleteDashboard": "Weet u zeker dat u het dashboard wilt verwijderen?",
  "downloadLogs": "Logboeken downloaden",
  "provisioning": "Inrichten...",
  "dataJourney": "Datareis",
  "visualisation": "Visualisatie",
  "developers": "Ontwikkelaars",
  "analyticalModel": "Analytisch model",
  "testing": "Testen",
  "dashboarding": "Dashboarding",
  "confirmDeleteUser": "Weet u zeker dat u de gebruiker wilt verwijderen?",
  "queue": "Rij",
  "position": "Positie",
  "clickToUpload": "klik om te uploaden",
  "staged": "Geënsceneerd",
  "enqueued": "In de wachtrij",
  "processing": "Verwerking",
  "finished": "Klaar",
  "showCurrent": "Huidige weergeven",
  "showAll": "Alles weergeven",
  "removeAll": "Alles verwijderen",
  "noFilesInCurrentQueue": "Geen bestanden in huidige wachtrij",
  "submit": "Opslaan",
  "saveAsVersion": "Opslaan als versie",
  "model": "Model",
  "createNewNode": "Nieuw knooppunt maken",
  "view": "Bekijken",
  "labels": "Labels",
  "fileUpload": "Bestand uploaden",
  "fileUploadQueue": "Wachtrij voor het uploaden van bestanden",
  "dashboardUpload": "Dashboard uploaden",
  "dashboardUploadQueue": "Dashboard uploadwachtrij",
  "noReportsInCurrentQueue": "Geen rapporten in huidige wachtrij",
  "dashboardsToDisplay": "Dashboards om weer te geven.",
  "noDashboards": "Dashboard(s) uploaden en  zorg ervoor dat Power BI Service is ingeschakeld om de dashboard(s) te bekijken.",
  "noSpecialCharsAllowed": "Dit veld mag geen speciale karakters bevatten behalve '-' ",
  "viewDashboards": "Schakel de Power BI-service in om het dashboard(s) weer te geven.",
  "totalUsers": "Totaal aantal gebruikers",
  "deloitteUsers": "Deloitte Gebruikers",
  "created": "Gecreëerd",
  "lastLogin": "Laatste aanmelding",
  "tenantDetails": "Huurdersgegevens",
  "removable": "Verwijderbaar",
  "infrastructure": "Infrastructuur",
  "dataWarehouseModel": "Modelgegevensmagazijn",
  "dataWarehouseStaging": "Gegevensmagazijn ensceneren",
  "functionApp": "Naam functie-app",
  "powerBIEmbeddedResource": "Ingesloten Power BI-resource",
  "projectDB": "Projectdatabase",
  "storageAccount": "Naam opslagaccount",
  "workspace": "Naam werkruimte",
  "reporting": "Rapporteren",
  "export": "Exporteren",
  "environment": "Omgeving",
  "versionNo": "Versienummer",
  "noTenant": "Geen huurder",
  "run": "Uitvoeren",
  "jobGrade": "Functiegraad",
  "add": "Toevoegen",
  "newFile": "Nieuw bestand",
  "modelingConfigDesc": "Gegevens in de staging-omgeving worden getransformeerd naar een generiek datamodel, het Common Node Model genaamd, en overgebracht naar een datawarehouse of database. Deze gegevenstransformaties kunnen worden gewijzigd op het tabblad Configureren en worden geïmplementeerd op het tabblad Bouwen.",
  "modelingBuildingDesc": "Hier kunt u kiezen of u bepaalde knooppunten aan wil zetten of uit wil schakelen.",
  "monitorTabDesc": "Hier kunt u de status zien van acties die tijdens de gegevensverwerking zijn gemaakt.",
  "runTabDesc": "Hier kunt u kiezen welke pijplijnen u wilt uitvoeren.",
  "analyticalModelOverviewDesc": "Op basis van de datareis worden aggregaties en berekeningen uitgevoerd en worden te analyseren datasets gecreëerd binnen het Analytisch Model. Deze datareis kan worden gewijzigd in het tabblad Configureren en worden toegepast op het tabblad Bouwen.",
  "analyticalModelBuildingOverviewDesc": "Hier kunt u kiezen of u bepaalde knooppunten aan wil zetten of uit wil schakelen.",
  "analyticalModelRunOverviewDesc": "Hier kunt u kiezen welke pijplijnen u wilt uitvoeren.",
  "testingConfigDesc": "Vooraf vastgestelde analyses worden uitgevoerd op de gegevens in het Common Node Model en de resultaten worden naar het model geschreven. Deze tests kunnen worden gewijzigd op het tabblad Configureren en worden geïmplementeerd op het tabblad Bouwen.",
  "testingBuildingDesc": "Modeleer uw data.",
  "testingRunOverviewDesc": "Hier kunt u kiezen welke pijplijnen u wilt uitvoeren.",
  "importDesc": "Gegevens kunnen uit verschillende bronnen en via verschillende methoden worden geïmporteerd, variërend van geautomatiseerde pijplijnen tot handmatige uploads. U kunt bronnen maken om invoergegevens te groeperen en gegevenstoegang te beperken tot specifieke gebruikers.",
  "filteringStorageDesc": "Filtering bepaalt welke gegevens in de analyse worden gebruikt. Bijvoorbeeld welke bedrijven onder het domein vallen. U kunt filteren door het sjabloon Excel-bestand te downloaden, de gewenste filters toe te passen en het Excel-bestand in het platform te uploaden.",
  "filteringConfigDesc": "Filter uw gegevens.",
  "filteringBuildDesc": "Hier kunt u kiezen of u bepaalde knooppunten aan wil zetten of uit wil schakelen.",
  "labelingBuildDesc": "Hier kunt u kiezen of u bepaalde knooppunten aan wil zetten of uit wil schakelen.",
  "labelingConfigDesc": "Label uw gegevens.",
  "labelingOverviewDesc": "De gegevens in het platform kunnen worden verrijkt met handmatige invoer. Door de data te downloaden als Excel-bestand, de dataverrijking in het bestand toe te voegen en het Excel-bestand in het platform te uploaden, worden de gegevens in het platform verrijkt.",
  "yes": "Ja",
  "no": "Nee",
  "addNewNode": "Nieuw knooppunt toevoegen",
  "nameOfNode": "Naam van het knooppunt",
  "EULA": "Licentieovereenkomst voor eindgebruikers",
  "acceptTerms": "Ik heb de voorwaarden in de Licentieovereenkomst gelezen.",
  "accept": "Accepteren",
  "tenantDesc": "Hier beheert u tenants",
  "noSourcesInCurrentQueue": "Geen bronnen in de huidige wachtrij.",
  "allFiles": "Alle bestanden",
  "noSource": "Geen gegevens om weer te geven. Voeg een nieuwe bron toe.",
  "newNodeModal": "Nieuw knooppunt maken",
  "new": "Nieuw",
  "newTestNode": "Nieuw testknooppunt",
  "userDesc": "Hier kunt u gebruikers van ETP bekijken en bijwerken.",
  "newDashboard": "Nieuw dashboard",
  "dashboardName": "Dashboardnaam",
  "deploymentStatus": "Status uitrol",
  "refresh": "Verversen",
  "sync": "Synchroniseren",
  "syncRbacTenantUsers": "Synchroniseer RBAC van gebruikers van huurder",
  "type": "Type",
  "metaCheck": "Metacheck",
  "queued": "Wachtrij",
  "nodeTypes": "Knooppunttypen",
  "deploy": "Uitrollen",
  "noData": "Geen gegevens om weer te geven.",
  "tenantAzureSettings": "Tenant Azure-instellingen",
  "azureComponents": "Azure componenten",
  "tenantAzureSetting": "Huurder Azure instelling",
  "compose": "Samenstellen",
  "id": "ID",
  "syncRoles": "Synchroniseer rollen",
  "saveAndDeploy": "Opslaan en implementeren",
  "editGeneralInfo": "Bewerk algemene informatie",
  "time": "Tijd",
  "monitor": "Monitor",
  "executions": "Uitvoeringen",
  "file": "Bestand",
  "confirmDeleteTenantAzureSetting": "Weet u het zeker?",
  "whereYouLeftOff": "Waar u gebleven bent",
  "quickActions": "Direct naar acties",
  "support": "Ondersteuning",
  "pipelineRuns": "Pijplijn uitvoeringen",
  "exports": "Geëxporteerd",
  "seeAllDashboards": "bekijk alle dashboards",
  "importFiles": "Importeer bestanden",
  "runDataModel": "Voer data model uit",
  "runTests": "Voer testen uit",
  "runPipeline": "Activeer pijplijn",
  "pleaseProvideGUID": "Voer een GUID in",
  "resourceNameMustStartWithnletp": "Naam moet beginnen met nletp",
  "onlyAlphanumericCharactersLowerCase": "Naam mag alleen letters en nummers bevatten",
  "onlyAlphaNumericCharactersLowerCaseAndDash": "Naam mag alleen letters, nummers en streepjes bevatten",
  "resourceGroupNameMustStartWithNLETP": "Resource groep naam moet beginnen met NL-ETP-",
  "removeResources": "Resources verwijderen",
  "confirmRemoveTenant": "Weet je zeker dat je de tenant wil verwijderen?",
  "confirmRemoveTenantResources": "Weet je zeker dat je alle tenant resources wil verwijderen?",
  "files": "Bestanden",
  "folders": "Mappen",
  "filters": "Filters",
  "feedback": "Feedback",
  "addNewFolder": "Map toevoegen",
  "createFolder": "Nieuwe map",
  "feedbackDesc": "Hiermee kunt u feedback geven om ons platform te verbeteren.",
  "feedbackForm": "Feedback formulier",
  "helpForm": "Hulp formulier",
  "emailSubject": "Onderwerp",
  "emailMessage": "Bericht",
  "send": "Verzend",
  "remove": "Verwijderen",
  "pleaseProvideDelimiter": "Voer een scheidingsteken in",
  "pleaseProvideQuoteCharacter": "Voer een aanhalingsteken in",
  "pleaseProvideLineEnding": "Voer een regeleinde teken in",
  "pleaseProvideEncodingName": "Voer de coderingsnaam in",
  "editFields": "Velden bewerken",
  "BuildingDesc": "Hier kunt u kiezen of u bepaalde knooppunten wilt in- of uitschakelen.",
  "welkom": "Welkom",
  "noFolderAccessUser": "U heeft geen toegang tot deze map. Vraag uw engagement manager om u toegang te verlenen",
  "noFolderAccessManager": "U heeft geen toegang tot deze map",
  "continue": "Ga verder",
  "thisFieldIsRequired": "Deze veld is verplicht",
  "noDuplicateFolderName": "Er bestaat al een map met dezelfde naam",
  "pleaseEnterNumCharacters": "Vul minimaal [num] karakters in",
  "thereIsNoActivityYet": "Er is nog geen activiteit",
  "confirmResetTenantDeployment": "Weet je zeker dat je de tenant deployment wil resetten?",
  "confirm": "Bevestig",
  "cancel": "Annuleer",
  "areYouSure": "Weet je het zeker?",
  "createContainers": "Containers aanmaken",
  "createGatewayEntry": "Gateway entry aanmaken",
  "dataLakeStorageName": "Data Lake Storage naam",
  "deployBlobPrivateEndpoint": "blob private endpoint aanmaken",
  "deployQueuePrivateEndpoint": "queue private endpoint aanmaken",
  "deployDataFactoryPrivateEndpoint": "data-factory private endpoint aanmaken",
  "deployDfsPrivateEndpoint": "dfs private endpoint aanmaken",
  "deployFilePrivateEndpoint": "file private endpoint aanmaken",
  "deploySqlPrivateEndpoint": "sql private endpoint aanmaken",
  "deployApacheSparkPool": "Apache Spark Pool aanmaken",
  "deploySqlPool": "SQL pool aanmaken",
  "deploySqlServerPrivateEndpoint": "sqlServer private endpoint aanmaken",
  "deployMachineLearningPrivateEndpoint": "Azure MachineLearning private endpoint aanmaken",
  "deployKeyVaultPrivateEndpoint": "Key Vault private endpoint aanmaken",
  "deployContainerRegistryPrivateEndpoint": "Azure Container Registry private endpoint aanmaken",
  "deployTopicPrivateEndpoint": "topic private endpoint aanmaken",
  "managedIdentityName": "Managed identity naam",
  "powerBiCapacityName": "Power BI capacity naam",
  "preventDataExfiltration": "Prevent data exfiltration",
  "seedEntries": "Tabellen en proceduren aanmaken",
  "sqlServerName": "SQL server naam",
  "message": "Bericht",
  "noBuilds": "Er is niets gebouwd om te laten zien",
  "noRuns": "Er zijn geen uitvoeringen om te laten zien",
  "noMonitor": "Er is niks om te monitoren",
  "noImport": "Er is niks om te monitoren",
  "noPipeline": "Er is geen pijplijn om te monitoren",
  "noUsers": "Er zijn geen gebruikers om te importeren",
  "noTests": "Er zijn geen tests om te laten zien",
  "runs": "Uitvoeringen",
  "powerbi": "Power BI",
  "tenantConfiguration": "Tenant Configuratie",
  "error": "Fout",
  "mustBeAtLeastEightChars": "Resource naam moet minimaal acht characters lang zijn",
  "mustBeShorterThan24": "Resource naam mag niet langer zijn dan 24 characters",
  "tasks": "Taken",
  "newFolder": "Nieuwe Map",
  "outcome": "uitkomst",
  "uploaded": "Geüpload",
  "imported": "Geïmporteerd",
  "changeSettings": "Instellingen wijzigen",
  "importToDatabase": "Importeer naar database",
  "removeFromDatabase": "Verwijder uit database",
  "searchUser": "Zoek gebruiker...",
  "searchFiles": "Zoek bestand...",
  "searchTenants": "Zoek tenant...",
  "searchEngagements": "Zoek engagement...",
  "mustIncludeEnvironment": "Resource naam moet de omgeving bevatten",
  "orchestrations": "orchestraties",
  "newOrchestration": "Nieuwe orchestratie",
  "noTasks": "Er zijn geen taken om te laten zien",
  "plannedExecutionDate": "Geplande executie datum",
  "orchestrationStatus": "Orchestratie status",
  "addNewOrchestration": "Voeg een nieuwe orchestratie toe",
  "updateOrchestration": "pas orchestratie aan",
  "createOrchestration": "Maak orchestratie",
  "pickADate": "Kies een dag",
  "orchestrationType": "Orchestration type",
  "dashboardStorage": "Dashboard opslag",
  "applications": "Applicaties",
  "applicationsDesc": "Hier kan je verbindingen opzetten met externe applicaties",
  "newApplication": "Nieuwe applicatie",
  "objectId": "Object id",
  "updateApplication": "Bewerk applicatie",
  "noApplications": "Er zijn geen externe aplicaties om te tonen",
  "addApplication": "Voeg applicatie toe",
  "deployments": "Implementaties",
  "notValidJson": "Geen valide json formaat",
  "costs": "Kosten",
  "costsOverview": "Hier kunt u een overzicht van de kosten per resource vinden",
  "cost": "Kosten",
  "extract": "Uittreksel",
  "extractConfigDesc": "Uittreksel uw gegevens",
  "fieldsFromQry": "Velden van de query om toe te voegen",
  "documentation": "Documentatie",
  "noExport": "Er is niks om te exporteren",
  "noCosts": "Er zijn geen kosten om te tonen",
  "confirmApproveTask": "Weet je zeker dat je deze taak wilt goedkeuren?",
  "confirmDisapproveTask": "Weet je zeker dat je de goedkeuring van deze taak wilt verwijderen?",
  "confirmEditTask": "Weet je zeker dat je deze taak wilt bewerken?",
  "confirmRemoveUserFromTenant": "Weet je zeker dat je de gebruiker wil verwijderen van de huurder?",
  "path": "Bron",
  "hidePipeline": "Verberg pijplijn",
  "taskNotEditable": "De parameters van deze taak zijn niet te bewerken",
  "toggleClicked": "Omschakelen...",
  "pipelineRunning": "[pipeline] is bezig",
  "nodesBuilding": "De geselecteerde knooppunten zijn aan het bouwen",
  "addingTask": "Orchestration word toegevoegd. Dit kan even duren",
  "editTask": "Orchestration word bewerkt. Dit kan even duren",
  "total": "Totaal",
  "noConfigs": "Er zijn geen tenant configuraties om te tonen",
  "togglingPipeline": "Pijplijn zichtbaarheid wordt aangepast",
  "togglingUser": "Gebruiker word omgeschakeld",
  "togglingPowerBI": "Power BI word omgeschakeld",
  "togglingAnalysisServices": "Analyse diensten worden omgeschakeld",
  "noConfigurations": "Geen configuraties om weer te geven.",
  "gitRepository": "Node Model Git Repository",
  "onlyLettersNumbersDots": "Alleen letters, cijfers en '.' zijn toegestaan",
  "onlyLettersNumbersUnderScore": "Alleen letters, cijfers en '_' zijn toegestaan",
  "onlyLettersNumbersUnderScoreSquareBrackets": "Alleen letters, cijfers, '_' en '[' ']' zijn toegestaan",
  "onlyLettersNumbersDashUnderScore": "Alleen letters, cijfers, '-' en '_' zijn toegestaan",
  "notValidField": "[name] is geen geldige veldnaam.",
  "noDuplicateBranchName": "Er bestaat al een branch met dezelfde naam",
  "noDuplicateNodeName": "Er bestaat al een node met dezelfde naam",
  "testsRunning": "De geselecteerde tests worden uitgevoerd.",
  "inherit": "Overnemen van map",
  "clone": "Klonen",
  "urlCloned": "URL gekopieerd naar klembord",
  "profileDesc": "Hier kunt u uw persoonlijke informatie en de tenants waar u onderdeel van bent zien",
  "myInfo": "Mijn informatie",
  "yourTenants": "Uw tenants",
  "yourTenantsDesc": "Klik op een van de tenants om direct van tenant te wisselen",
  "noTenants": "U heeft geen huurders aan u toegewezen",
  "403Desc": "Oh oh, het lijkt erop dat je geen toegang hebt tot deze bron. Als je net toegang hebt gekregen, kan het ongeveer 5 minuten duren voordat het van kracht wordt",
  "stopCostSaving": "Cost Saving stoppen",
  "stopCostSavingDesc": "Hoe lang zal de Cost Saving worden stopgezet?",
  "custom": "Aangepaste",
  "periodInHours": "Periode in uren vanaf nu",
  "noEmptyFieldAllowed": "Veld mag niet leeg zijn",
  "dbSchema": "Database schema",
  "nameMustMinNumCharacters": "Naam moet minimaal [num] karakters zijn",
  "invalidValue": "Ongeldige waarde",
  "invalidDataType": "Ongeldig data type",
  "branch": "Tak",
  "inherited": "Geërfd",
  "unknown": "Onbekend",
  "removedfromdatabase": "Verwijderd uit db",
  "validationsuccess": "Gevalideerd",
  "validationfailed": "Validatie mislukt",
  "validationerror": "Validatie fout",
  "activeStage": "Actieve stadium",
  "activeTask": "Actieve taak",
  "completedStage": "Voltooid stadium",
  "completedTask": "Voltooid taak",
  "createInstance": "Creër een instantie",
  "currentStage": "Huidige stadium",
  "definitions": "Definities",
  "instanceOf": "Instantie van",
  "instances": "Instanties",
  "noDefinitions": "Er zijn geen definities beschikbaar",
  "noInstances": "Er zijn geen instanties beschikbaar",
  "stage": "Stadium",
  "admin": "Beheerder",
  "addNewProperty": "Nieuwe eigenschap toevoegen",
  "properties": "Eigenschappen",
  "configurer": "Configurator",
  "superConfigurer": "Super Configurator",
  "superUser": "Super gebruiker",
  "savingChanges": "Wijzigingen opslaan...",
  "deletingFile": "Bestand verwijderen...",
  "not-a-valid-json": "Geen geldige json",
  "containerName": "Containernaam",
  "fileName": "Bestandsnaam",
  "userInfoSaved": "Gebruikersinformatie wordt opgeslagen",
  "saved": "opgeslagen",
  "applicationInfoSaved": "Applicatie informatie is opgeslagen",
  "tenantInfoSaved": "Tenantgegevens opgeslagen",
  "tenantAzureSettingsSaved": "De tenant azure settings zijn opgeslagen",
  "tenantResourceSettingsSaved": "De tenant resources zijn opgeslagen",
  "confirmStartDeployment": "Weet u zeker dat u een deployment wilt starten?",
  "azureComponentAdded": "Azure component toegevoegd",
  "deploymentInProgress": "Uw implementatie wordt uitgevoerd",
  "tenantDeploymentReset": "Uw tenantimplementatie is opnieuw ingesteld",
  "resource": "Resource",
  "resourcesRemoved": "Alle resource zijn verwijderd",
  "resourceDeleted": "De resource is verwijderd",
  "azureResourceDeleted": "De Azure resource is verwijderd",
  "tenantIntegrationIdsSaved": "De tenant-integratie-ID's zijn opgeslagen",
  "rolesSyncing": "Rollen worden gesynchroniseerd",
  "tenantUserDeleted": "Gebruiker is verwijderd uit de tenant",
  "applicationAdded": "Applicatie is toegevoegd",
  "confirmRemoveApplication": "Weet u zeker dat u deze applicatie wilt verwijderen?",
  "confirmDeleteResource": "Weet u zeker dat u deze resource wilt verwijderen?",
  "confirmImportFolder": "Weet u zeker dat u deze map wilt importeren?",
  "applicationDeleted": "Applicatie is verwijderd",
  "configurationSaved": "Configuratie is opgeslagen",
  "inProgress": "In uitvoering",
  "newBranchAdded": "Nieuwe branch toegevoegd",
  "newNodeAdded": "Nieuwe node toegevoegd",
  "newSourceAdded": "Nieuwe bron toegevoegd",
  "newCategoryAdded": "Nieuwe categorie toegevoegd",
  "newTestAdded": "Nieuwe test toegevoegd",
  "enterValidEmailAddress": "Voer een geldig e-mail adres in",
  "tenantRemoved": "Tenant is verwijderd",
  "newTenantAdded": "De tenant is toegevoegd",
  "folderAdded": "Map is toegevoegd",
  "dashboardDeleted": "De geselecteerde dashboard(s) zijn verwijderd",
  "orchestrationUpdated": "Orchestration is bijgewerkt",
  "orchestrationAdded": "Orchestration is toegevoegd",
  "tenantConfigurationDesc": "Hier kunt u de tenant configuraties zien en kopiëren voor tenant",
  "noDuplicateField": "Er bestaat al een veld met dezelfde naam",
  "noDuplicateProperty": "Er bestaat al een eigenschap met dezelfde naam",
  "noDuplicateCategory": "Er bestaat al een categorie met dezelfde naam",
  "noResultsFound": "Er zijn geen resultaten gevonden met de gegeven query",
  "pipelineStatusFilter": "Filter status",
  "searchOrchestrations": "Zoek orchestraties",
  "filterStatus": "Filteren op status",
  "importingFilesToDb": "Bestanden importeren in database",
  "importingFileToDb": "Bestand importeren in database",
  "Queued": "In Wachtrij",
  "Succeeded": "Succes",
  "Failed": "Mislukt",
  "scope": "Scope",
  "rolesAddedSuccessfully": "Roles zijn toegevoegd",
  "Canceling": "Annuleren",
  "Cancelled": "Geannuleerd",
  "Timed Out": "Timed Out",
  "newRole": "Nieuwe Rol",
  "editRole": "Rol Bewerken",
  "rolesDesc": "Hier kunt u nieuwe rollen aanmaken en de bestaande bewerken",
  "roleInfoSaved": "Rol informatie is opgeslagen",
  "noRoles": "Er zijn geen rollen om te tonen",
  "permissions": "Permissies",
  "permissionsDesc": "Hier kunt u nieuwe permissies aanmaken en de bestaande bewerken",
  "newPermission": "Nieuwe Permissie",
  "noPermissions": "Er zijn geen permissies om te tonen",
  "editPermission": "Permissie Bewerken",
  "deletePermission": "Permissie verwijderen",
  "deleteRole": "Rol verwijderen",
  "permissionInfoSaved": "Permissie informatie is opgeslagen",
  "confirmRemovePermission": "Weet u zeker dat u deze permissie wilt verwijderen?",
  "confirmRemoveRole": "Weet u zeker dat u deze rol wilt verwijderen?",
  "permissionRemoved": "Permissie is verwijderd",
  "roleRemoved": "Rol is verwijderd",
  "cloudProvider": "Cloud provider",
  "cloudConfiguration": "Cloud configuratie",
  "microsoftAzure": "Microsoft Azure",
  "amazonAws": "Amazon Aws",
  "PageLockedBecauseDeploymentInProgress": "De velden zijn onbruikbaar omdat er een deployment aan de gang is",
  "confirmDeleteTenant": "Weet je zeker dat je deze tenant en al zijn resources permanent wilt verwijderen?",
  "tenantDeleted": "De tenant is verwijderd",
  "userDetails": "Gebruikersdetails",
  "jobTitle": "Functietitel",
  "emailAddressNotAvailable": "Het e-mailadres is niet beschikbaar",
  "addRole": "Rol toevoegen",
  "roleName": "Rol naam",
  "roleAdded": "Rol is toegevoegd",
  "role": "Rol",
  "showTenantCosts": "Toon Tenant Kosten",
  "implementation": "Implementatie",
  "engagementUserDeleted": "Gebruiker is verwijderd uit de engagement",
  "engagementApplicationDeleted": "Applicatie is verwijderd uit de engagement",
  "confirmRemoveUserFromEngagement": "Weet je zeker dat je de gebruiker wil verwijderen van de engagement?",
  "repository": "Repository",
  "targetRepository": "Doel Repository",
  "sourceRepository": "Bron Repository",
  "targetBranch": "Doel Branch",
  "features": "Functies",
  "targetRepoRequired": "Kies een doelrepository",
  "getExampleParams": "Voorbeeld krijgen",
  "addConfiguration": "Configuratie toevoegen",
  "publishPipelines": "Pijplijnen Publiceren",
  "repositories": "Repositories",
  "openAll": "Alles Openen",
  "GitRepository": "Git Repository",
  "gitImplementation": "Git Implementatie",
  "KeyVault": "Key Vault",
  "ContainerRegistry": "Container Registry",
  "AzureContainerRegistryTier": "Azure Container Registry Tier",
  "keyVaultName": "Key Vault Name",
  "containerRegistryName": "Azure Container Registry Name",
  "linkedServices": "Linked Services",
  "addDataFactoryLinkedServices": "data-factory linked services aanmaken",
  "azureDatabricksTier": "Databricks workspace Tier",
  "DatabricksWorkspace": "Databricks Workspace",
  "outputContainer": "Uitvoercontainer",
  "resources": "Resources",
  "etpTemplateType": "Selecteren Template",
  "prefix": "Prefix",
  "importTemplate": "Import Template",
  "resourceType": "Resource type",
  "tag": "Tag"
}
