import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/icons/new/home.svg'
import _imports_1 from '@/assets/images/icons/new/database.svg'
import _imports_2 from '@/assets/images/icons/file.svg'
import _imports_3 from '@/assets/images/icons/new/dashboarding.svg'
import _imports_4 from '@/assets/images/icons/new/settings.svg'


const _hoisted_1 = {
  key: 0,
  class: "bs-1 glass-card menu-layout main-menu-pane"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_the_engagement_selector = _resolveComponent("the-engagement-selector")!
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_el_sub_menu = _resolveComponent("el-sub-menu")!
  const _component_the_menu = _resolveComponent("the-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!$setup.fullScreen)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_the_engagement_selector, {
            engagements: $setup.engagements,
            "current-engagement": $setup.currentEngagement,
            onClick: $setup.changeEngagement
          }, null, 8, ["engagements", "current-engagement", "onClick"]),
          _createVNode(_component_the_menu, null, {
            "menu-contents": _withCtx(() => [
              _cache[5] || (_cache[5] = _createElementVNode("hr", { class: "border-line" }, null, -1)),
              _createVNode(_component_el_menu_item, {
                index: "Overview",
                route: { name: 'Overview' },
                class: "main-menu-item"
              }, {
                title: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_0,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('overview')), 1)
                ]),
                _: 1
              }),
              _createElementVNode("div", null, [
                ($setup.showImportMenuItem || $setup.showNmMenuItem)
                  ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                      key: 0,
                      index: "3"
                    }, {
                      title: _withCtx(() => [
                        _cache[1] || (_cache[1] = _createElementVNode("img", {
                          class: "menu-icon",
                          src: _imports_1,
                          height: "15",
                          width: "15"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('dataJourney')), 1)
                      ]),
                      default: _withCtx(() => [
                        ($setup.showImportMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 0,
                              index: "Import",
                              route: { name: 'Import' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('import')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showNmMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 1,
                              index: "Model",
                              route: { name: 'Model' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('modeling')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showNmMenuItem && !$setup.isGainEngagement)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 2,
                              index: "Filtering",
                              route: { name: 'Filtering' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('filtering')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showNmMenuItem && !$setup.isGainEngagement)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 3,
                              index: "Labeling",
                              route: { name: 'Labeling' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('labeling')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showNmMenuItem && !$setup.isGainEngagement)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 4,
                              index: "Analytical",
                              route: { name: 'Analytical' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('analyticalModel')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showNmMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 5,
                              index: "Testing",
                              route: { name: 'Testing' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('testing')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                ($setup.showStorageItem)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      key: 1,
                      index: "StorageManager",
                      route: {
              name: 'StorageManager',
              params: { storagePurpose: $setup.currentStoragePurpose, path: [] }
            },
                      class: "main-menu-item"
                    }, {
                      title: _withCtx(() => [
                        _cache[2] || (_cache[2] = _createElementVNode("img", {
                          class: "menu-icon",
                          src: _imports_2,
                          height: "15",
                          width: "15"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('storage')), 1)
                      ]),
                      _: 1
                    }, 8, ["route"]))
                  : _createCommentVNode("", true),
                ($setup.showDashboardMenuItem)
                  ? (_openBlock(), _createBlock(_component_el_menu_item, {
                      key: 2,
                      index: "DashboardOverview",
                      route: {
              name: 'DashboardOverview'
            },
                      class: "main-menu-item"
                    }, {
                      title: _withCtx(() => [
                        _cache[3] || (_cache[3] = _createElementVNode("img", {
                          class: "menu-icon",
                          src: _imports_3,
                          height: "15",
                          width: "15"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('dashboards')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true),
                (
              $setup.showConfigurationMenuITem ||
              $setup.isGainEngagement ||
              $setup.showAccessMenuItem ||
              $setup.showCostsMenuItem ||
              $setup.showConnectorsMenuItem
            )
                  ? (_openBlock(), _createBlock(_component_el_sub_menu, {
                      key: 3,
                      index: "6"
                    }, {
                      title: _withCtx(() => [
                        _cache[4] || (_cache[4] = _createElementVNode("img", {
                          class: "menu-icon",
                          src: _imports_4,
                          height: "15",
                          width: "15"
                        }, null, -1)),
                        _createElementVNode("span", null, _toDisplayString(_ctx.$t('administration')), 1)
                      ]),
                      default: _withCtx(() => [
                        ($setup.showConfigurationMenuITem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 0,
                              index: "Configuration",
                              route: { name: 'Configuration' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('configuration')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.isGainEngagement)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 1,
                              index: "Gain",
                              route: { name: 'GainOverview' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('gain')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showCostsMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 2,
                              index: "Costs",
                              route: { name: 'Costs' }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('costs')), 1)
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        ($setup.showConnectorsMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 3,
                              index: "ConnectorsConfiguration",
                              route: {
                name: 'ConnectorsConfiguration',
                params: {
                  engagementId: $setup.currentEngagement.id
                }
              }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('connectors')), 1)
                              ]),
                              _: 1
                            }, 8, ["route"]))
                          : _createCommentVNode("", true),
                        ($setup.showAccessMenuItem)
                          ? (_openBlock(), _createBlock(_component_el_menu_item, {
                              key: 4,
                              index: "EngagementAccess",
                              route: {
                name: 'EngagementAccessOverview',
                params: {
                  engagementId: $setup.currentEngagement.id
                }
              }
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.$t('access')), 1)
                              ]),
                              _: 1
                            }, 8, ["route"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    (!$setup.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass('content-wrapper ' + [$setup.fullScreen ? 'full-screen' : ''])
        }, [
          (_openBlock(), _createBlock(_component_router_view, {
            key: _ctx.$route.fullPath
          }))
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}