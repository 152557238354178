import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/icons/new/delete.svg'


const _hoisted_1 = { class: "body-1" }
const _hoisted_2 = { class: "body-2" }
const _hoisted_3 = { class: "body-1" }
const _hoisted_4 = { class: "body-2" }
const _hoisted_5 = { class: "actions" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "user-dropdown" }
const _hoisted_8 = { class: "body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_divider = _resolveComponent("el-divider")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!
  const _component_base_modal = _resolveComponent("base-modal")!

  return (_openBlock(), _createBlock(_component_base_modal, {
    width: "690px",
    "modal-title": _ctx.$t('userSettings'),
    "confirm-text": _ctx.$t('save'),
    "dialog-visible": $props.dialogVisible,
    onConfirm: $setup.confirm,
    onClose: $setup.handleClose
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", null, [
        _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('inheritedAssignments')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table, {
          data: $setup.localInheritedUsers,
          "empty-text": _ctx.$t('noUsers')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, null, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_1, _toDisplayString(scope.row.name), 1),
                _createElementVNode("div", _hoisted_2, _toDisplayString(scope.row.email), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data", "empty-text"]),
        _createVNode(_component_el_divider, null, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('directAssignments')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table, {
          data: $setup.localDirectUsers,
          "empty-text": _ctx.$t('noUsers')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, null, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_3, _toDisplayString(scope.row.name), 1),
                _createElementVNode("div", _hoisted_4, _toDisplayString(scope.row.email), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, {
              width: 100,
              align: "center"
            }, {
              default: _withCtx((scope) => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_el_tooltip, {
                    content: _ctx.$t('remove')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: _imports_0,
                        class: "icon-size-7",
                        onClick: ($event: any) => ($setup.removeUser(scope.$index, scope.row))
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 2
                  }, 1032, ["content"])
                ])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data", "empty-text"])
      ])
    ]),
    footer: _withCtx(() => [
      _createVNode(_component_el_dropdown, {
        "max-height": "400px",
        type: "primary",
        trigger: "click"
      }, {
        dropdown: _withCtx(() => [
          _createVNode(_component_el_dropdown_menu, { class: "dropdown-menu" }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localAvailableUsers, (user, index) => {
                return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                  key: user.email,
                  onClick: ($event: any) => ($setup.addUser(index, user))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(user.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              }), 128))
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.$t('selectUser')), 1),
            _cache[0] || (_cache[0] = _createElementVNode("div", { class: "el-dropdown-link" }, [
              _createElementVNode("i", { class: "el-icon-d-caret el-icon--right" })
            ], -1))
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modal-title", "confirm-text", "dialog-visible", "onConfirm", "onClose"]))
}