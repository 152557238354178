import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "toggle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_table = _resolveComponent("el-table")!

  return (_openBlock(), _createBlock(_component_el_table, {
    data: $props.pipelines,
    "empty-text": _ctx.$t('noRuns')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_table_column, {
        label: _ctx.$t('name')
      }, {
        default: _withCtx((scope) => [
          _createElementVNode("h4", null, _toDisplayString(scope.row.shortName), 1)
        ]),
        _: 1
      }, 8, ["label"]),
      ($props.canHidePipelines)
        ? (_openBlock(), _createBlock(_component_el_table_column, {
            key: 0,
            label: _ctx.$t('hidePipeline'),
            width: 120
          }, {
            default: _withCtx((scope) => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_base_toggle_button, {
                  "model-value": scope.row.isHidden ?? false,
                  "indicator-text": _ctx.$t('togglingPipeline'),
                  "onUpdate:modelValue": ($event: any) => ($setup.updatePipeline(scope.row))
                }, null, 8, ["model-value", "indicator-text", "onUpdate:modelValue"])
              ])
            ]),
            _: 1
          }, 8, ["label"]))
        : _createCommentVNode("", true),
      _createVNode(_component_el_table_column, {
        width: 150,
        "class-name": "overflow",
        align: "right"
      }, {
        default: _withCtx((scope) => [
          _createVNode(_component_el_button, {
            type: "primary",
            onClick: ($event: any) => ($setup.startPipelineRun(scope.row))
          }, {
            default: _withCtx(() => [
              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('run')), 1)
            ]),
            _: 2
          }, 1032, ["onClick"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["data", "empty-text"]))
}