import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "modal-title" }
const _hoisted_2 = { class: "modal-body" }
const _hoisted_3 = { class: "subtitle-2 modal-content" }
const _hoisted_4 = { class: "dialog-footer" }
const _hoisted_5 = { class: "footer-cancel" }
const _hoisted_6 = { class: "footer-confirm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_dialog, {
      "model-value": $props.modelValue,
      "before-close": $setup.handleClose,
      "show-close": $props.showClose,
      width: $props.width,
      center: $props.center
    }, {
      header: _withCtx(() => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString($props.modalTitle), 1)
      ]),
      footer: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "footer", {}, () => [
            _createElementVNode("div", _hoisted_5, [
              _createVNode(_component_el_button, {
                class: "modal-cancel",
                type: "default",
                onClick: $setup.handleClose
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString($props.cancelText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_button, {
                class: "modal-confirm",
                type: "primary",
                onClick: $setup.handleConfirm
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString($props.confirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ], true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "content", {}, () => [
            _createElementVNode("h3", _hoisted_3, _toDisplayString($props.content), 1)
          ], true)
        ])
      ]),
      _: 3
    }, 8, ["model-value", "before-close", "show-close", "width", "center"])
  ]))
}