<template>
  <el-form ref="formRef" :model="form" :rules="formRules" label-position="top">
    <el-row>
      <el-col :span="12" class="d-flex pt-1">
        <el-button
          class="ml-auto"
          type="primary"
          @click="updateApplicationInfo"
        >
          <h4>{{ $t('save') }}</h4>
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="mt-2">
        <el-card class="cursor-default">
          <el-form-item :label="$t('name')" prop="name">
            <el-input v-model="form.name" size="small" class="body-1" />
          </el-form-item>
          <el-form-item :label="$t('aadObjectId')" prop="aadObjectId">
            <div class="body-1 ml-2 form-item-readonly">
              {{ form.aadObjectId }}
            </div>
          </el-form-item>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
</template>
<script lang="ts">
import {
  ElButton,
  ElCard,
  ElCol,
  ElForm,
  ElFormItem,
  ElMessage,
  ElInput,
  ElRow
} from 'element-plus';
import { computed, ComputedRef, onMounted, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
import {
  ApplicationModel,
  CreateOrUpdateApplicationModel
} from '@etp/etp-authorization-client/axios';

export default {
  name: 'ApplicationProfile',
  components: {
    ElRow,
    ElCol,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElCard
  },
  setup() {
    const router = useRouter();
    const aadObjectId = router.currentRoute.value.params.aadObjectId as string;

    let application: ComputedRef<ApplicationModel> = computed(
      () => store.getters['applications/getApplicationDetails']
    );

    const form = ref({
      name: '',
      aadObjectId: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        }
      ]
    });

    const updateApplicationInfo = () => {
      formRef.value?.validate(async (valid: boolean) => {
        if (valid) {
          await store.dispatch('applications/createOrUpdateApplication', {
            aadObjectId: aadObjectId,
            model: {
              name: form.value.name
            } as CreateOrUpdateApplicationModel
          });
          await updateLocalVars();
          ElMessage({
            showClose: true,
            message: i18n.global.t('applicationInfoSaved')
          });
        }
      });
    };

    const updateLocalVars = async () => {
      await store.dispatch('applications/fetchApplicationDetails', aadObjectId);
      form.value.name = application.value.name ?? '';
      form.value.aadObjectId = application.value.aadObjectId ?? '';
    };

    onMounted(async () => await updateLocalVars());

    return {
      application,
      form,
      formRef,
      formRules,
      updateApplicationInfo
    };
  }
};
</script>
