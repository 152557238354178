import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "subtitle-2 mt-3" }
const _hoisted_2 = { class: "engagement-table" }
const _hoisted_3 = { class: "body-1 mt-1" }
const _hoisted_4 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_table_header = _resolveComponent("base-table-header")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_pagination = _resolveComponent("pagination")!
  const _component_new_engagement_modal = _resolveComponent("new-engagement-modal")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_page_header, {
          "main-title": _ctx.$t('engagements')
        }, {
          subheader: _withCtx(() => [
            _createElementVNode("h4", _hoisted_1, _toDisplayString(_ctx.$t('engagementsDesc')), 1)
          ]),
          _: 1
        }, 8, ["main-title"])
      ]),
      _: 1
    }),
    _createVNode(_component_base_table_header, {
      "row-count": $setup.engagementsCount,
      "quantity-message": _ctx.$t('engagements'),
      "input-placeholder": _ctx.$t('searchEngagements'),
      debounce: "",
      class: "mt-6",
      onQueryChange: $setup.updateEngagementsFilter
    }, {
      default: _withCtx(() => [
        (!$setup.isCloudProviderAws)
          ? (_openBlock(), _createBlock(_component_el_button, {
              key: 0,
              class: "ml-2",
              type: "primary",
              onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.openNewEngagementModal()))
            }, {
              default: _withCtx(() => [
                _createElementVNode("h4", null, _toDisplayString('+ ' + _ctx.$t('newEngagement')), 1)
              ]),
              _: 1
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["row-count", "quantity-message", "input-placeholder", "onQueryChange"]),
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_el_table, { data: $setup.engagements }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_table_column, {
                    prop: "name",
                    sortable: "custom",
                    label: _ctx.$t('name')
                  }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", null, [
                        _createElementVNode("h4", null, _toDisplayString(scope.row.name), 1),
                        _createElementVNode("span", _hoisted_3, _toDisplayString(scope.row.id), 1)
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_table_column, { width: "100" }, {
                    default: _withCtx((scope) => [
                      _createElementVNode("div", null, [
                        _createElementVNode("img", {
                          src: require(`@/assets/images/icons/new/settings.svg`),
                          class: "action-icon action-spin",
                          height: "14",
                          width: "14",
                          onClick: ($event: any) => ($setup.openEngagementDetails(scope.row.id))
                        }, null, 8, _hoisted_4)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["data"])
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_pagination, {
      count: $setup.engagementsCount,
      page: $setup.engagementsPage,
      "page-size": $setup.engagementsPageSize,
      onChangePage: $setup.changePage
    }, null, 8, ["count", "page", "page-size", "onChangePage"]),
    _createVNode(_component_new_engagement_modal, {
      modelValue: $setup.showNewEngagementModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showNewEngagementModal) = $event)),
      "feedback-text": _ctx.$t('newEngagementAdded'),
      tenants: $setup.tenants,
      onConfirm: $setup.createNewEngagement
    }, null, 8, ["modelValue", "feedback-text", "tenants", "onConfirm"])
  ]))
}