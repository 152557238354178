import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "d-flex align-items-center ml-auto" }
const _hoisted_2 = { class: "ml-1 pipeline-status-label" }
const _hoisted_3 = { class: "ml-1 pipeline-status-label" }
const _hoisted_4 = { class: "ml-1 pipeline-status-label" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "ml-1 pipeline-status-label" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_monitor_pipelines_table = _resolveComponent("monitor-pipelines-table")!
  const _component_base_table = _resolveComponent("base-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", {
            class: _normalizeClass(["ml-auto pipeline-status-indicator", $setup.PipelineStatus.Succeeded])
          }, null, 2),
          _createElementVNode("h4", _hoisted_2, _toDisplayString(_ctx.$t('success')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["ml-3 pipeline-status-indicator", $setup.PipelineStatus.InProgress])
          }, null, 2),
          _createElementVNode("h4", _hoisted_3, _toDisplayString(_ctx.$t('running')), 1),
          _createElementVNode("div", {
            class: _normalizeClass(["ml-3 pipeline-status-indicator", $setup.PipelineStatus.Failed])
          }, null, 2),
          _createElementVNode("h4", _hoisted_4, _toDisplayString(_ctx.$t('failed')), 1)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, {
      class: "mt-2 mb-1",
      justify: "space-between",
      align: "middle"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 5 }, {
          default: _withCtx(() => [
            ($props.loadingPipelineRuns && !$props.pipelineRuns.length)
              ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.$t('loading')), 1))
              : (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString($props.pipelineRuns.length) + " · " + _toDisplayString(_ctx.$t('pipelineRuns')), 1))
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: $setup.statusFilter,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.statusFilter) = $event)),
              multiple: "",
              placeholder: _ctx.$t('pipelineStatusFilter'),
              size: "small",
              onChange: $setup.updateStatusFilter
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.PipelineStatus, (item, key) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: key,
                    label: _ctx.$t(key),
                    value: item,
                    class: "d-flex align-items-center"
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", {
                        class: _normalizeClass(["pipeline-status-indicator", item.toLowerCase()])
                      }, null, 2),
                      _createElementVNode("h4", _hoisted_7, _toDisplayString(_ctx.$t(key)), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "placeholder", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "d-block" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_table, {
          "show-loading-table": $props.loadingPipelineRuns && !$props.pipelineRuns.length,
          "show-no-content-table": !$props.pipelineRuns.length,
          "error-message": _ctx.$t('noPipeline')
        }, {
          default: _withCtx(() => [
            _createVNode(_component_monitor_pipelines_table, {
              "pipeline-runs": $props.pipelineRuns,
              "sort-params": $props.sortParams,
              "show-adf-button": $props.showAdfButton,
              onUpdateSortParams: $setup.updateSortParams
            }, null, 8, ["pipeline-runs", "sort-params", "show-adf-button", "onUpdateSortParams"])
          ]),
          _: 1
        }, 8, ["show-loading-table", "show-no-content-table", "error-message"])
      ]),
      _: 1
    })
  ], 64))
}