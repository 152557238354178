import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "privacy-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, {
      gutter: 5,
      justify: "center"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 14 }, {
          default: _withCtx(() => [
            _createVNode(_component_page_header, {
              class: "page-header",
              "main-title": _ctx.$t('privacyStatement')
            }, null, 8, ["main-title"]),
            _cache[0] || (_cache[0] = _createElementVNode("p", null, [
              _createTextVNode(" Deloitte provides global, regional, national and/or practice-specific services; each service is administered by Deloitte Touche Tohmatsu Limited "),
              _createElementVNode("b", null, "(‘DTTL’)"),
              _createTextVNode(" or by one of the affiliated member firms or entities related thereto (jointly referred to as the "),
              _createElementVNode("b", null, "‘Deloitte Network’"),
              _createTextVNode(" ). ")
            ], -1)),
            _cache[1] || (_cache[1] = _createElementVNode("p", null, [
              _createTextVNode(" Deloitte Belastingadviseurs B.V. (hereinafter also referred to as "),
              _createElementVNode("b", null, "‘we’, ‘us’, or ‘our’"),
              _createTextVNode(" ) is a part of Deloitte NSE, the member firm affiliated to DTTL. We are a global organization that handles privacy consciously. We are the entity within the Deloitte Network responsible for Enterprise Tax Platform - Core (hereinafter also referred as ETP Core) and act as ‘controller’ in the sense of the General Data Protection Regulation "),
              _createElementVNode("b", null, "(‘GDPR’)"),
              _createTextVNode(" . In this Privacy Statement we explain how we use and protect the data of the individuals that are directly or indirectly involved with ETP Core (hereinafter referred to as "),
              _createElementVNode("b", null, "‘the individuals’"),
              _createTextVNode(" ). Please contact your Deloitte engagement partner for more information on the nature of cooperation between you, the client (hereinafter referred to as ‘the client’), and Deloitte Belastingadviseurs B.V. ")
            ], -1)),
            _cache[2] || (_cache[2] = _createElementVNode("p", null, " We point out that this Privacy Statement does not apply to the other services or products provided by other entities within the Deloitte Network. ", -1)),
            _cache[3] || (_cache[3] = _createElementVNode("h3", null, "Data collection", -1)),
            _cache[4] || (_cache[4] = _createElementVNode("p", null, " The personal data that falls within the scope of this Privacy Statement is originally collected by the client. ", -1)),
            _cache[5] || (_cache[5] = _createElementVNode("p", null, " Whilst providing ETP Core, it is necessary to process the personal data of the individuals for the purposes of the legitimate interest pursued by us or by the client. The legitimate interests are specified in the underlying engagement letter granting access to ETP Core and may include compliance with a legal obligation to which the client is subject to. ", -1)),
            _cache[6] || (_cache[6] = _createElementVNode("p", null, " The purpose of processing the personal data is specified in the underlying engagement letter. The underlying engagement letter is always available from your Deloitte engagement partner upon request. ", -1)),
            _cache[7] || (_cache[7] = _createElementVNode("p", null, [
              _createTextVNode(" We process the following categories of personal data: contact details. Additional categories of personal data may be processed and are specified in the engagement letter. Throughout this Privacy Statement these categories of personal data are jointly referred to as "),
              _createElementVNode("b", null, "‘the personal data’"),
              _createTextVNode(" . ")
            ], -1)),
            _cache[8] || (_cache[8] = _createElementVNode("p", null, " We do not intentionally collect special categories of personal data, such as data relating to race or ethnic origin, religious conviction, criminal record, physical and mental health status or sexual orientation. If unforeseen, special categories of personal data are being processed, for example when a medical data of individuals are processed, we will ensure that the appropriate measures are in place for compliant processing. ", -1)),
            _cache[9] || (_cache[9] = _createElementVNode("h3", null, "Use of data", -1)),
            _cache[10] || (_cache[10] = _createElementVNode("p", null, " The beforementioned types of personal data will only be used to provide the services rendered as mentioned in the engagement letter through ETP Core. We will not use the data for any other type of activity. We will not disclose the personal data to any third party, unless we are obliged to do so by law. There is no automated decision-making, including profiling, involved in the processing of the personal data. The personal data may also be used to protect our rights or properties and, if necessary, to comply with legal proceedings. In any case, the personal data is not processed outside the European Union/European Economic Area (EU/EAA). ", -1)),
            _cache[11] || (_cache[11] = _createElementVNode("h3", null, "Access to data", -1)),
            _cache[12] || (_cache[12] = _createElementVNode("p", null, [
              _createTextVNode(" Individuals wishing to exercise their rights under the GDPR, such as accessing, rectifying or erasing personal data, can contact our Privacy Office through this "),
              _createElementVNode("a", {
                href: "https://www2.deloitte.com/nl/nl/footerlinks/contact.html",
                target: "_blank"
              }, " contact form. ")
            ], -1)),
            _cache[13] || (_cache[13] = _createElementVNode("h3", null, "Retention", -1)),
            _cache[14] || (_cache[14] = _createElementVNode("p", null, " We will process the personal data for the duration of the services mentioned in the engagement letters. The personal data can be retained for a longer period, if this is required either, directly or indirectly by law, or by another type of obligation where Deloitte Belastingadviseurs B.V. is subject to, such as insurance agreements or other contractual obligations. We will make our best effort to ensure optimal data protection measures are in place to make sure that the personal data is securely processed throughout its entire lifecycle. ", -1)),
            _cache[15] || (_cache[15] = _createElementVNode("h3", null, "Complaints", -1)),
            _cache[16] || (_cache[16] = _createElementVNode("p", null, [
              _createTextVNode(" We make every effort to process personal data in a lawful manner. If individuals are of the opinion that we violate or harm any of their privacy rights, they have the right to lodge a complaint with the Dutch Data Protection Authority (‘Autoriteit Persoonsgegevens’). Before turning to the Data Protection Authority, we kindly request individuals to first inform our Privacy Office through this "),
              _createElementVNode("a", {
                href: "https://www2.deloitte.com/nl/nl/footerlinks/contact.html",
                target: "_blank"
              }, " contact form. ")
            ], -1)),
            _cache[17] || (_cache[17] = _createElementVNode("h3", null, "Contact", -1)),
            _cache[18] || (_cache[18] = _createElementVNode("p", null, [
              _createTextVNode(" If there are any questions or concerns about the processing of personal data in respect of ETP Core, please contact our Privacy Office through this "),
              _createElementVNode("a", {
                href: "https://www2.deloitte.com/nl/nl/footerlinks/contact.html",
                target: "_blank"
              }, " contact form. ")
            ], -1)),
            _cache[19] || (_cache[19] = _createElementVNode("hr", null, null, -1))
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}