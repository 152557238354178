import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/new/users.svg'
import _imports_1 from '@/assets/images/icons/new/healthcheck.svg'
import _imports_2 from '@/assets/images/icons/new/tenants.svg'
import _imports_3 from '@/assets/images/icons/office.svg'
import _imports_4 from '@/assets/images/icons/userroles.svg'
import _imports_5 from '@/assets/images/icons/check-list.svg'


const _hoisted_1 = { class: "menu-layout bs-1 glass-card" }
const _hoisted_2 = { class: "content-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_menu_item = _resolveComponent("el-menu-item")!
  const _component_the_menu = _resolveComponent("the-menu")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_the_menu, null, {
        "menu-contents": _withCtx(() => [
          ($setup.userCanReadGlobalUsers)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 0,
                index: "UsersOverview",
                route: { name: 'UsersOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[0] || (_cache[0] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_0,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('users')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadGlobalUsers)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 1,
                index: "ApplicationsOverview",
                route: { name: 'ApplicationsOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_1,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('applications')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadGlobalTenants)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 2,
                index: "TenantsOverview",
                route: { name: 'TenantsOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[2] || (_cache[2] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_2,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('tenants')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadGlobalEngagements)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 3,
                index: "EngagementsOverview",
                route: { name: 'EngagementsOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[3] || (_cache[3] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_3,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('engagements')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadGlobalAuthorization)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 4,
                index: "RolesOverview",
                route: { name: 'RolesOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[4] || (_cache[4] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_4,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('roles')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadGlobalAuthorization)
            ? (_openBlock(), _createBlock(_component_el_menu_item, {
                key: 5,
                index: "PermissionsOverview",
                route: { name: 'PermissionsOverview' },
                class: "main-menu-item"
              }, {
                default: _withCtx(() => [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    class: "menu-icon",
                    src: _imports_5,
                    height: "15",
                    width: "15"
                  }, null, -1)),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('permissions')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(), _createBlock(_component_router_view, {
        key: _ctx.$route.fullPath
      }))
    ])
  ], 64))
}