import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/etplogo.svg'


const _hoisted_1 = { class: "navbar" }
const _hoisted_2 = { class: "logo-text" }
const _hoisted_3 = { class: "subtitle-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: { name: 'Overview' },
      class: "logo-wrapper"
    }, {
      default: _withCtx(() => [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo-holder" }, [
          _createElementVNode("img", {
            src: _imports_0,
            class: "logo-svg"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('etp')), 1)
        ])
      ]),
      _: 1
    }),
    _renderSlot(_ctx.$slots, "default", {}, undefined, true)
  ]))
}