import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/icons/user-placeholder.png'
import _imports_1 from '@/assets/images/icons/new/menu.svg'
import _imports_2 from '@/assets/images/icons/new/profile.svg'
import _imports_3 from '@/assets/images/icons/new/users.svg'
import _imports_4 from '@/assets/images/icons/new/healthcheck.svg'
import _imports_5 from '@/assets/images/icons/new/tenants.svg'
import _imports_6 from '@/assets/images/icons/office.svg'
import _imports_7 from '@/assets/images/icons/userroles.svg'
import _imports_8 from '@/assets/images/icons/check-list.svg'
import _imports_9 from '@/assets/images/icons/new/home.svg'
import _imports_10 from '@/assets/images/icons/new/logout.svg'


const _hoisted_1 = { class: "user-menu-container" }
const _hoisted_2 = { class: "user-name" }
const _hoisted_3 = { class: "welcome" }
const _hoisted_4 = { class: "picture-menu-wrapper" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: _imports_0,
  height: "38",
  width: "38",
  class: "user"
}
const _hoisted_7 = { class: "menu-item" }
const _hoisted_8 = { class: "menu-item" }
const _hoisted_9 = { class: "menu-item" }
const _hoisted_10 = { class: "menu-item" }
const _hoisted_11 = { class: "menu-item" }
const _hoisted_12 = { class: "menu-item" }
const _hoisted_13 = { class: "menu-item" }
const _hoisted_14 = {
  key: 0,
  class: "divider"
}
const _hoisted_15 = { class: "menu-item" }
const _hoisted_16 = { class: "menu-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", _hoisted_3, [
        _createTextVNode(_toDisplayString(_ctx.$t('welcome')) + ", ", 1),
        _createElementVNode("b", null, _toDisplayString($props.currentUser.displayName), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      ($props.profilePicture)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.profilePicture,
            height: "38",
            width: "38",
            class: "user"
          }, null, 8, _hoisted_5))
        : _createCommentVNode("", true),
      (!$props.profilePicture)
        ? (_openBlock(), _createElementBlock("img", _hoisted_6))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_el_dropdown, {
      type: "primary",
      trigger: "click",
      placement: "bottom-end"
    }, {
      dropdown: _withCtx(() => [
        _createVNode(_component_el_dropdown_menu, { class: "menu-dropdown" }, {
          default: _withCtx(() => [
            _createElementVNode("h3", null, [
              _createVNode(_component_el_dropdown_item, {
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'ProfileOverview' })))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_7, [
                    _cache[8] || (_cache[8] = _createElementVNode("img", {
                      class: "icon",
                      src: _imports_2,
                      height: "14",
                      width: "14"
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('profile')), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _cache[17] || (_cache[17] = _createElementVNode("hr", { class: "divider" }, null, -1)),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalUsers)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'UsersOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_8, [
                        _cache[9] || (_cache[9] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_3,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('users')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalUsers)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'ApplicationsOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_9, [
                        _cache[10] || (_cache[10] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_4,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('applications')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalTenants)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'TenantsOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_10, [
                        _cache[11] || (_cache[11] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_5,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('tenants')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalEngagements)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$router.push({ name: 'EngagementsOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_11, [
                        _cache[12] || (_cache[12] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_6,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('engagements')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalAuthorization)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    class: "menu-item",
                    onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$router.push({ name: 'RolesOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_12, [
                        _cache[13] || (_cache[13] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_7,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('roles')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("h3", null, [
              ($setup.userCanReadGlobalAuthorization)
                ? (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: 0,
                    onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$router.push({ name: 'PermissionsOverview' })))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("span", _hoisted_13, [
                        _cache[14] || (_cache[14] = _createElementVNode("img", {
                          class: "icon",
                          src: _imports_8,
                          height: "14",
                          width: "14"
                        }, null, -1)),
                        _createTextVNode(" " + _toDisplayString(_ctx.$t('permissions')), 1)
                      ])
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true)
            ]),
            (
              $setup.userCanReadGlobalAuthorization ||
              $setup.userCanReadGlobalEngagements ||
              $setup.userCanReadGlobalTenants ||
              $setup.userCanReadGlobalUsers
            )
              ? (_openBlock(), _createElementBlock("hr", _hoisted_14))
              : _createCommentVNode("", true),
            _createElementVNode("h3", null, [
              _createVNode(_component_el_dropdown_item, {
                onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.$router.push({ name: 'Overview' })))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_15, [
                    _cache[15] || (_cache[15] = _createElementVNode("img", {
                      class: "icon",
                      src: _imports_9,
                      height: "14",
                      width: "14"
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('overview')), 1)
                  ])
                ]),
                _: 1
              })
            ]),
            _cache[18] || (_cache[18] = _createElementVNode("hr", { class: "divider" }, null, -1)),
            _createElementVNode("h3", null, [
              _createVNode(_component_el_dropdown_item, { onClick: $setup.signOut }, {
                default: _withCtx(() => [
                  _createElementVNode("span", _hoisted_16, [
                    _cache[16] || (_cache[16] = _createElementVNode("img", {
                      class: "icon",
                      src: _imports_10,
                      height: "14",
                      width: "14"
                    }, null, -1)),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t('logout')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _cache[19] || (_cache[19] = _createElementVNode("span", { class: "el-dropdown-link" }, [
          _createElementVNode("img", {
            src: _imports_1,
            width: "10",
            height: "10",
            class: "action-icon"
          })
        ], -1))
      ]),
      _: 1
    })
  ]))
}