import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mt-5" }
const _hoisted_2 = { class: "mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tab = _resolveComponent("base-tab")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      "main-title": _ctx.$t('gainOverview')
    }, {
      tabs: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_router_link, { to: { name: 'GainBatches' } }, {
            default: _withCtx(({ isActive }) => [
              _createVNode(_component_base_tab, { active: isActive }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('batches')), 1)
                ]),
                _: 2
              }, 1032, ["active"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'GainRequirementsStatus' } }, {
            default: _withCtx(({ isActive }) => [
              _createVNode(_component_base_tab, { active: isActive }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('requirementsStatus')), 1)
                ]),
                _: 2
              }, 1032, ["active"])
            ]),
            _: 1
          }),
          _createVNode(_component_router_link, { to: { name: 'GainPromotions' } }, {
            default: _withCtx(({ isActive }) => [
              _createVNode(_component_base_tab, { active: isActive }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('promotions')), 1)
                ]),
                _: 2
              }, 1032, ["active"])
            ]),
            _: 1
          }),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "horizontal-line mt-2" }, [
            _createElementVNode("hr")
          ], -1))
        ])
      ]),
      _: 1
    }, 8, ["main-title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}