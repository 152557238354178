import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '@/assets/images/icons/new/approve.svg'
import _imports_1 from '@/assets/images/icons/new/delete.svg'
import _imports_2 from '@/assets/images/icons/new/edit.svg'


const _hoisted_1 = { class: "el-form-item__label align-left" }
const _hoisted_2 = { class: "el-form-item__label align-left" }
const _hoisted_3 = { class: "d-inline-block ml-1" }
const _hoisted_4 = { class: "actions" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_code_editor = _resolveComponent("code-editor")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_table, {
      data: $props.tableData,
      "empty-text": _ctx.$t('noTasks'),
      "row-key": "id",
      "expand-row-keys": $setup.expandedRowKeys,
      onExpandChange: $setup.expandChange
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, { type: "expand" }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t('parameters')), 1),
              _createVNode(_component_code_editor, {
                value: JSON.stringify(scope.row.params),
                font_size: "13px",
                read_only: true,
                languages: [['JSON', 'JSON']],
                theme: "light",
                min_height: "300px",
                min_width: "100%"
              }, null, 8, ["value"])
            ])
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('orchestrationType')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("h4", null, _toDisplayString(scope.row.type), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('plannedExecutionDate')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("h4", null, _toDisplayString($setup.formatDate(scope.row.plannedExecutionDate)), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('orchestrationStatus')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: _normalizeClass(["pipeline-status-indicator", $setup.getStatusColor(scope.row.state)])
            }, null, 2),
            _createElementVNode("h4", _hoisted_3, _toDisplayString(scope.row.state), 1)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('actions'),
          width: "100"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", _hoisted_4, [
              ($setup.checkAction('approve', scope.row.state))
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 0,
                    content: _ctx.$t('approve')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: _imports_0,
                        class: "action-icon mr-1 icon-size-7",
                        onClick: ($event: any) => ($setup.openApproveTaskModal(scope.row.id))
                      }, null, 8, _hoisted_5)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : _createCommentVNode("", true),
              ($setup.checkAction('disapprove', scope.row.state))
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 1,
                    content: _ctx.$t('reject')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: _imports_1,
                        class: "action-icon mr-1 icon-size-7",
                        onClick: ($event: any) => ($setup.openRejectTaskModal(scope.row.id))
                      }, null, 8, _hoisted_6)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : _createCommentVNode("", true),
              ($setup.checkAction('edit', scope.row.state))
                ? (_openBlock(), _createBlock(_component_el_tooltip, {
                    key: 2,
                    content: _ctx.$t('edit')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("img", {
                        src: _imports_2,
                        class: "action-icon mr-1 icon-size-7",
                        onClick: _withModifiers(($event: any) => (_ctx.$emit('edit-task', scope.row)), ["stop"])
                      }, null, 8, _hoisted_7)
                    ]),
                    _: 2
                  }, 1032, ["content"]))
                : _createCommentVNode("", true)
            ])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["data", "empty-text", "expand-row-keys", "onExpandChange"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showRejectTaskModal,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.showRejectTaskModal) = $event)),
      content: _ctx.$t('confirmDisapproveTask'),
      onConfirm: _cache[1] || (_cache[1] = ($event: any) => {
      _ctx.$emit('disapprove-task', $setup.taskToApproveOrReject);
      $setup.showRejectTaskModal = false;
    }),
      onClose: _cache[2] || (_cache[2] = ($event: any) => ($setup.showRejectTaskModal = false))
    }, null, 8, ["modelValue", "content"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showApproveTaskModal,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.showApproveTaskModal) = $event)),
      content: _ctx.$t('confirmApproveTask'),
      onConfirm: _cache[4] || (_cache[4] = ($event: any) => {
      _ctx.$emit('approve-task', $setup.taskToApproveOrReject);
      $setup.showApproveTaskModal = false;
    }),
      onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showApproveTaskModal = false))
    }, null, 8, ["modelValue", "content"])
  ], 64))
}