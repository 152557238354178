<template>
  <el-table :data="storageObjects" fit class="nowrap-tab">
    <el-table-column prop="type" width="55">
      <template #default="scope">
        <img
          :src="
            [
              StorageObjectType.Folder,
              StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? require(`@/assets/images/icons/folder-16.svg`)
              : require(`@/assets/images/icons/file.svg`)
          "
          class="icon-size-7"
        />
      </template>
    </el-table-column>
    <el-table-column prop="displayName" :label="$t('name')">
      <template #default="scope">
        <div
          :class="
            [
              StorageObjectType.Folder,
              StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? 'cursor-pointer'
              : ''
          "
          @click="
            [
              StorageObjectType.Folder,
              StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? $emit('open-folder', scope.row)
              : null
          "
        >
          <el-tooltip placement="top" :content="getObjectDetails(scope.row)">
            <h4>
              {{
                showActualNames
                  ? scope.row.actualName
                  : scope.row.displayName ?? scope.row.actualName
              }}
            </h4>
          </el-tooltip>
        </div>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('uploaded')"
      width="120"
      sortable
      :sort-by="sortByDate"
    >
      <template #default="scope">
        <h4>{{ formatDate(scope.row.createdAt) }}</h4>
        <h4>{{ formatTime(scope.row.createdAt) }}</h4>
      </template>
    </el-table-column>
    <el-table-column width="20" class-name="meta-column">
      <template #default="scope">
        <el-tooltip v-if="isCustomMetadata(scope.row.metadata)" placement="top">
          <template #default>
            <img
              :src="require(`@/assets/images/icons/new/information.svg`)"
              class="action-icons icon-size-7"
            />
          </template>
          <template #content>
            <div style="width: 500px">
              <el-row class="mb-2">{{ $t('metadata') + ':' }}</el-row>
              <el-row
                v-for="[key, value] of Object.entries(
                  filterCustomMetadata(scope.row.metadata)
                )"
                :key="key"
              >
                <el-col :span="12">{{ key }}</el-col>
                <el-col :span="12">{{ value }}</el-col>
              </el-row>
            </div>
          </template>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column width="120" align="right" :label="$t('actions')">
      <template #default="scope">
        <slot :row="scope.row"></slot>
      </template>
    </el-table-column>
  </el-table>
  <el-row class="ml-auto">
    <base-toggle-button
      v-model="showActualNames"
      :active-text="$t('actualName')"
      :inactive-text="$t('displayName')"
      class="ml-auto"
      @update:model-value="toggleButton"
    />
  </el-row>
</template>

<script lang="ts">
import { ElCol, ElRow, ElTable, ElTableColumn, ElTooltip } from 'element-plus';
import BaseToggleButton from '@/components/buttons/BaseToggleButton.vue';
import i18n from '@/i18n';
import moment from 'moment';
import {
  StorageObject,
  StorageObjectType
} from '@etp/etp-storage-client/axios';
import { onMounted, ref } from 'vue';

export default {
  name: 'StorageObjectsTable',
  components: {
    BaseToggleButton,
    ElCol,
    ElRow,
    ElTable,
    ElTableColumn,
    ElTooltip
  },
  props: {
    storageObjects: {
      type: Array as () => Array<StorageObject>,
      required: true
    }
  },
  emits: ['open-folder'],

  setup() {
    const showActualNames = ref(false);

    const formatDate = (input: Date) => {
      if (!input) return '';
      return moment(input).format('YYYY-MM-DD');
    };
    const formatTime = (input: Date) => {
      if (!input) return '';
      return moment(input).format('HH:mm:ss');
    };

    const sortByDate = (object: StorageObject) => {
      return object.createdAt ?? '';
    };

    const getObjectDetails = (object: StorageObject) => {
      return i18n.global.t('fullPath') + ': ' + object.fullPath;
    };

    const filterCustomMetadata = (
      metadata: Record<string, string>
    ): Record<string, string> => {
      return Object.fromEntries(
        Object.entries(metadata).filter(
          ([, value]) => !!value || value === 'false'
        )
      );
    };

    const isCustomMetadata = (metadata: Record<string, string>) => {
      if (!metadata) return false;
      return !!Object.keys(filterCustomMetadata(metadata)).length;
    };

    const toggleButton = (newValue: boolean) => {
      localStorage.setItem(
        'storage-table-show-actual-names',
        newValue.toString()
      );
    };

    onMounted(() => {
      showActualNames.value =
        localStorage.getItem('storage-table-show-actual-names') === 'true';
    });

    return {
      filterCustomMetadata,
      formatDate,
      formatTime,
      getObjectDetails,
      isCustomMetadata,
      showActualNames,
      sortByDate,
      StorageObjectType,
      toggleButton
    };
  }
};
</script>
<style scoped lang="scss">
.action-icons {
  opacity: 0.75;
  transition: 250ms all cubic-bezier(0.19, 1, 0.22, 1);
  &:hover {
    transform: scale(1.15);
    opacity: 1;
  }
}
</style>
<style lang="scss">
.meta-column > .cell {
  padding: 0 !important;
  display: flex;
  justify-content: center;
}
</style>
