import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "subtitle-2 modal-content" }
const _hoisted_3 = { class: "content-type" }
const _hoisted_4 = { class: "body-1" }
const _hoisted_5 = { class: "content-name" }
const _hoisted_6 = { class: "body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createBlock(_component_base_confirmation_modal, {
    "model-value": $props.modelValue,
    width: "500px",
    "feedback-text": _ctx.$t('resourceDeleted'),
    "onUpdate:modelValue": _cache[0] || (_cache[0] = val => $setup.handleClose(val)),
    onConfirm: _cache[1] || (_cache[1] = ($event: any) => ($setup.handleConfirm()))
  }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", null, [
          _createElementVNode("h3", _hoisted_2, _toDisplayString($props.confirmationText), 1)
        ]),
        _createElementVNode("div", _hoisted_3, [
          _cache[2] || (_cache[2] = _createElementVNode("h4", null, "Type", -1)),
          _createElementVNode("span", _hoisted_4, _toDisplayString($props.typeText), 1)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _cache[3] || (_cache[3] = _createElementVNode("h4", null, "Name", -1)),
          _createElementVNode("span", _hoisted_6, _toDisplayString($props.nameText), 1)
        ])
      ])
    ]),
    _: 1
  }, 8, ["model-value", "feedback-text"]))
}