import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "panel-header d-flex" }
const _hoisted_2 = { class: "title-container d-flex" }
const _hoisted_3 = { class: "icon d-flex mr-2 align-items-center avatar" }
const _hoisted_4 = { class: "main-title" }
const _hoisted_5 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "avatar", {}, undefined, true)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("h3", _hoisted_4, _toDisplayString($props.mainTitle), 1),
        _renderSlot(_ctx.$slots, "subheader", {}, undefined, true)
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _renderSlot(_ctx.$slots, "actions", {}, undefined, true)
    ])
  ]))
}