import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex mt-5" }
const _hoisted_2 = { class: "mt-3" }
const _hoisted_3 = {
  key: 0,
  class: "subtitle-2"
}
const _hoisted_4 = {
  key: 1,
  class: "subtitle-2"
}
const _hoisted_5 = {
  key: 2,
  class: "subtitle-2"
}
const _hoisted_6 = {
  key: 3,
  class: "subtitle-2"
}
const _hoisted_7 = { class: "mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tab = _resolveComponent("base-tab")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_page_header, {
      "main-title": _ctx.$t('filtering')
    }, {
      tabs: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          ($setup.configurationStorageExists)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                to: {
            name: 'StorageManager',
            params: { storagePurpose: 'configuration', path: [] }
          }
              }, {
                default: _withCtx(({ isExactActive }) => [
                  _createVNode(_component_base_tab, { active: isExactActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('storage')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadEngagementNodeModelGit && $setup.showNodeModelGitPages)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 1,
                to: { name: 'FilteringConfig' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('configure')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.userCanReadEngagementNodeModelGit && $setup.showNodeModelGitPages)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 2,
                to: { name: 'FilteringBuild' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('build')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.showPipelinePages)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 3,
                to: { name: 'FilteringRun' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('run')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          ($setup.showPipelinePages)
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 4,
                to: { name: 'FilteringMonitor' }
              }, {
                default: _withCtx(({ isActive }) => [
                  _createVNode(_component_base_tab, { active: isActive }, {
                    default: _withCtx(() => [
                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('monitor')), 1)
                    ]),
                    _: 2
                  }, 1032, ["active"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "horizontal-line mt-2" }, [
            _createElementVNode("hr")
          ], -1))
        ])
      ]),
      subheader: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          ($setup.routerName == 'FilteringConfig')
            ? (_openBlock(), _createElementBlock("h4", _hoisted_3, _toDisplayString(_ctx.$t('filteringConfigDesc')), 1))
            : ($setup.routerName == 'FilteringBuild')
              ? (_openBlock(), _createElementBlock("h4", _hoisted_4, _toDisplayString(_ctx.$t('filteringBuildDesc')), 1))
              : ($setup.routerName == 'FilteringRun')
                ? (_openBlock(), _createElementBlock("h4", _hoisted_5, _toDisplayString(_ctx.$t('runTabDesc')), 1))
                : ($setup.routerName == 'FilteringMonitor')
                  ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.$t('monitorTabDesc')), 1))
                  : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }, 8, ["main-title"]),
    _createElementVNode("div", _hoisted_7, [
      _createVNode(_component_router_view)
    ])
  ], 64))
}