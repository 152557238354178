<template>
  <base-modal
    :modal-title="$t('addApplication')"
    :confirm-text="$t('add')"
    :dialog-visible="modalVisible"
    width="400px"
    @confirm="confirm"
    @close="$emit('close')"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        :rules="formRules"
        label-position="top"
      >
        <el-form-item :label="$t('scope')" prop="scope">
          <el-input v-model="form.scope" disabled />
        </el-form-item>
        <el-form-item :label="$t('name')" prop="aadObjectId">
          <el-select
            v-model="form.aadObjectId"
            filterable
            size="small"
            value-key="aadObjectId"
          >
            <el-option
              v-for="application in sortApplications(applications)"
              :key="application.aadObjectId"
              :label="application.name"
              :value="application.aadObjectId"
            />
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('roles')" prop="selectedRoles">
          <el-select
            v-model="form.selectedRoles"
            multiple
            size="small"
            value-key="name"
          >
            <el-option
              v-for="role in roles"
              :key="role.name"
              :label="role.name"
              :value="role.name"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>
<script lang="ts">
import BaseModal from '@/components/modals/BaseModal.vue';
import { ElForm, ElFormItem, ElOption, ElSelect, ElInput } from 'element-plus';

import { onUpdated, reactive, ref } from 'vue';
import {
  RoleModel,
  ApplicationModel,
  RoleAssignmentModel
} from '@etp/etp-authorization-client/axios';
import i18n from '@/i18n';

export default {
  name: 'AddApplicationRoleAssignmentModal',
  components: { BaseModal, ElSelect, ElOption, ElForm, ElFormItem, ElInput },
  props: {
    modalVisible: { type: Boolean, default: false },
    applications: {
      type: Array as () => Array<ApplicationModel>,
      required: true
    },
    roles: {
      type: Array as () => Array<RoleModel>,
      required: true
    },
    scope: {
      type: String,
      required: true
    }
  },
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const form = reactive({
      aadObjectId: '',
      selectedRoles: new Array<string>(),
      scope: props.scope
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      aadObjectId: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      },
      selectedRoles: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      }
    });

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          let roleAssignments = form.selectedRoles.map((roleName: string) => {
            return {
              principalId: form.aadObjectId,
              roleName: roleName,
              scope: form.scope
            } as RoleAssignmentModel;
          });
          emit('confirm', roleAssignments);
        }
      });
    };

    onUpdated(() => {
      form.aadObjectId = '';
      form.selectedRoles = new Array<string>();
      form.scope = props.scope;
    });

    return { confirm, form, formRef, formRules };
  },
  methods: {
    sortApplications(applications: Array<ApplicationModel>) {
      let sortedList = applications.sort((a, b) => {
        let fa = a.name?.toLowerCase() ?? '';
        let fb = b.name?.toLowerCase() ?? '';
        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return sortedList;
    }
  }
};
</script>
