import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-1" }
const _hoisted_2 = { class: "body-1" }
const _hoisted_3 = { class: "body-1" }
const _hoisted_4 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_add_role_modal = _resolveComponent("add-role-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "ml-auto",
          type: "primary",
          onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showAddRoleModal = true))
        }, {
          default: _withCtx(() => [
            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('addRole')), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
          key: 0,
          columns: 4
        }))
      : (_openBlock(), _createBlock(_component_el_table, {
          key: 1,
          data: $setup.aggregatedRoleAssignments
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_table_column, { width: "200" }, {
              default: _withCtx((scope) => [
                _createVNode(_component_el_tooltip, null, {
                  default: _withCtx(() => [
                    _createElementVNode("div", null, [
                      _createElementVNode("h4", null, _toDisplayString(scope.row.aggregationDisplayName ?? _ctx.$t('na')), 1),
                      _createElementVNode("span", _hoisted_1, _toDisplayString(Object.keys($setup.RoleAssignmentAggregation)[
                    Object.values($setup.RoleAssignmentAggregation).findIndex(
                      el => el == scope.row.aggregationType
                    )
                  ]), 1)
                    ])
                  ]),
                  content: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "primary",
                      onClick: ($event: any) => ($setup.removeAllRoleAssignments(scope.row))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('remove')), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"])
                  ]),
                  _: 2
                }, 1024)
              ]),
              _: 1
            }),
            _createVNode(_component_el_table_column, null, {
              header: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, { span: 6 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('role')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, { span: 17 }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('scope')), 1)
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              default: _withCtx((scope) => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(scope.row.roleAssignments.sort($setup.compareRoleAssignments) as Array<RoleAssignmentModel>, (roleAssignment) => {
                  return (_openBlock(), _createBlock(_component_el_row, {
                    key: roleAssignment.roleName
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 6 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_2, _toDisplayString(roleAssignment.roleName), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, { span: 17 }, {
                        default: _withCtx(() => [
                          _createElementVNode("span", _hoisted_3, _toDisplayString(roleAssignment.scope), 1)
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, { span: 1 }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/delete.svg`),
                            height: "12",
                            width: "12",
                            class: "action-icon",
                            onClick: ($event: any) => ($setup.removeSingleRoleAssignment(roleAssignment))
                          }, null, 8, _hoisted_4)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["data"])),
    _createVNode(_component_add_role_modal, {
      modelValue: $setup.showAddRoleModal,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.showAddRoleModal) = $event)),
      engagements: $setup.engagements,
      roles: $setup.roles,
      tenants: $setup.tenants,
      onConfirm: $setup.addRoleAssignments
    }, null, 8, ["modelValue", "engagements", "roles", "tenants", "onConfirm"])
  ], 64))
}