<template>
  <base-card no-header>
    <template #body>
      <el-row>
        <el-col :span="6">
          <h3>{{ configuration.storagePurpose }}</h3>
        </el-col>
        <el-col :span="15">
          <el-form :model="form">
            <el-form-item
              class="mb-0"
              :label="$t('displayName') + ' (' + $t('max30characters') + ')'"
              prop="displayName"
            >
              <el-input
                v-model="form.displayName"
                size="small"
                maxlength="30"
                @change="handleChangeDisplayName"
              />
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('implementation')"
              prop="implementation"
            >
              <el-select
                v-model="form.implementation"
                size="small"
                :placeholder="$t('empty')"
                class="mb-0"
                @change="handleChangeImplementation"
              >
                <el-option
                  v-for="(index, item) in Implementation"
                  :key="index"
                  :label="item"
                  :value="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('resourceName')"
              prop="resourceName"
            >
              <el-select
                v-model="form.resourceName"
                size="small"
                :placeholder="$t('empty')"
                @change="handleChangeResourceName"
              >
                <el-option
                  v-for="item in localResources"
                  :key="item.name ?? ''"
                  :label="item.name ?? ''"
                  :value="item.name ?? ''"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="form.implementation == Implementation.Aws_S3Bucket"
              class="mb-0"
              :label="$t('secondaryBucketName')"
              prop="secondaryBucketName"
            >
              <el-input
                v-model="form.secondaryBucketName"
                size="small"
                @change="handleChangeSecondaryBucketName"
              />
            </el-form-item>
            <el-form-item
              class="mb-0"
              :label="$t('pathPrefix')"
              prop="pathPrefix"
            >
              <el-input
                v-model="form.pathPrefix"
                size="small"
                placeholder="engagement-1337/import-"
                @change="handleChangePathPrefix"
              />
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="3" class="d-flex">
          <img
            :src="require(`@/assets/images/icons/new/delete.svg`)"
            height="12"
            width="12"
            class="action-icon ml-auto mt-1 mr-2"
            @click="deleteItem"
          />
        </el-col>
      </el-row>
    </template>
  </base-card>
</template>
<script lang="ts">
import BaseCard from '@/components/panels/BaseCard.vue';
import { onMounted, onUpdated, ref } from 'vue';
import {
  ElCol,
  ElForm,
  ElFormItem,
  ElInput,
  ElOption,
  ElRow,
  ElSelect
} from 'element-plus';
import {
  CreateOrUpdateConfigurationDto,
  EngagementStorageConfiguration,
  Implementation
} from '@etp/etp-storage-client/axios';
import { ResourceType, ResourceModel } from '@etp/etp-infra-client';

export default {
  name: 'StorageConfigurationCard',
  components: {
    BaseCard,
    ElCol,
    ElForm,
    ElFormItem,
    ElInput,
    ElOption,
    ElRow,
    ElSelect
  },
  props: {
    configuration: {
      type: Object as () => EngagementStorageConfiguration,
      required: true
    },
    resources: {
      type: Array as () => Array<ResourceModel>,
      default: () => new Array<ResourceModel>()
    }
  },
  emits: ['delete', 'update'],
  setup(props, { emit }) {
    const form = ref({
      implementation: -1,
      displayName: '',
      resourceName: '',
      secondaryBucketName: '',
      pathPrefix: ''
    });

    const emitUpdateEvent = async () => {
      await emit('update', {
        implementation: form.value.implementation,
        displayName: form.value.displayName,
        configuration: createConfiguration()
      } as CreateOrUpdateConfigurationDto);
    };

    const deleteItem = async () => emit('delete');

    const createConfiguration = () => {
      switch (form.value.implementation) {
        case Implementation.Azure_StorageAccount:
        case Implementation.Azure_DataLakeStorage:
          return JSON.stringify({
            StorageAccountName: form.value.resourceName,
            PathPrefix: form.value.pathPrefix
          });
        case Implementation.Aws_S3Bucket:
          return JSON.stringify({
            BucketName: form.value.resourceName,
            SecondaryBucketName: form.value.secondaryBucketName,
            PathPrefix: form.value.pathPrefix
          });
        default:
          return '';
      }
    };

    const localResources = ref(new Array<ResourceModel>());

    const handleChangeDisplayName = async (newValue: string) => {
      form.value.displayName = newValue;
      await emitUpdateEvent();
    };

    const handleChangeImplementation = async (type: number) => {
      form.value.implementation = type;
      switch (type) {
        case Implementation.Azure_StorageAccount:
          localResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.StorageAccount
          );
          form.value.resourceName =
            localConfiguration.value['StorageAccountName'] ?? '';
          break;
        case Implementation.Azure_DataLakeStorage:
          localResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.DataLakeStorage
          );
          form.value.resourceName =
            localConfiguration.value['StorageAccountName'] ?? '';
          break;
        case Implementation.Aws_S3Bucket:
          localResources.value = props.resources.filter(
            el => el.resourceType == ResourceType.StorageAccount
          );
          form.value.resourceName =
            localConfiguration.value['BucketName'] ?? '';
          form.value.secondaryBucketName =
            localConfiguration.value['SecondaryBucketName'] ?? '';
          break;
        default:
          localResources.value = new Array<ResourceModel>();
      }
      await emitUpdateEvent();
    };

    const handleChangePathPrefix = async (newValue: string) => {
      form.value.pathPrefix = newValue;
      await emitUpdateEvent();
    };

    const handleChangeResourceName = async (newValue: string) => {
      form.value.resourceName = newValue;
      await emitUpdateEvent();
    };

    const handleChangeSecondaryBucketName = async (newValue: string) => {
      form.value.secondaryBucketName = newValue;
      await emitUpdateEvent();
    };

    let localConfiguration = ref({});
    const updateLocalVars = async () => {
      localConfiguration.value = JSON.parse(
        props.configuration?.configuration || '{}'
      );
      form.value.displayName = props.configuration.displayName ?? '';
      form.value.pathPrefix = localConfiguration.value['PathPrefix'] ?? '';
      await handleChangeImplementation(props.configuration.implementation);
    };

    onMounted(async () => {
      await updateLocalVars();
      await emitUpdateEvent();
    });
    onUpdated(async () => await updateLocalVars());

    return {
      deleteItem,
      form,
      handleChangeDisplayName,
      handleChangeImplementation,
      handleChangePathPrefix,
      handleChangeResourceName,
      handleChangeSecondaryBucketName,
      Implementation,
      localResources
    };
  }
};
</script>
