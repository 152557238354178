import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "list-item body-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("li", _hoisted_1, [
    ($setup.showImportStoragePages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "body-1",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push({ name: 'Import' })))
        }, _toDisplayString(_ctx.$t('importFiles')), 1))
      : _createCommentVNode("", true),
    ($setup.showPipelinePages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "body-1",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$router.push({ name: 'ModelRun' })))
        }, _toDisplayString(_ctx.$t('runDataModel')), 1))
      : _createCommentVNode("", true),
    ($setup.showPipelinePages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: "body-1",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$router.push({ name: 'TestingRunOverview' })))
        }, _toDisplayString(_ctx.$t('runTests')), 1))
      : _createCommentVNode("", true),
    ($setup.showPipelinePages)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: "body-1",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$router.push({ name: 'FilteringRun' })))
        }, _toDisplayString(_ctx.$t('runPipeline')), 1))
      : _createCommentVNode("", true)
  ]))
}