import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "pipeline-name" }
const _hoisted_2 = { class: "content-meta d-flex" }
const _hoisted_3 = { class: "d-flex body-2" }
const _hoisted_4 = { class: "content-meta-item mr-1" }
const _hoisted_5 = { class: "content-meta-item d-flex body-2 ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_timeline_item = _resolveComponent("el-timeline-item")!
  const _component_el_timeline = _resolveComponent("el-timeline")!

  return (_openBlock(), _createBlock(_component_el_timeline, null, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.pipelineRuns, (item, index) => {
        return (_openBlock(), _createBlock(_component_el_timeline_item, {
          key: index,
          class: "timeline-item",
          type: 
        item.status === 'Succeeded'
          ? 'primary'
          : item.status === 'Failed'
          ? 'danger'
          : 'warning'
      
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.pipelineName), 1),
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("span", _hoisted_4, _toDisplayString($setup.getLayerName(item.layerName)), 1),
                _cache[0] || (_cache[0] = _createElementVNode("span", null, "|", -1)),
                _createElementVNode("span", _hoisted_5, _toDisplayString($setup.localDateTime(item.runStart)), 1)
              ])
            ])
          ]),
          _: 2
        }, 1032, ["type"]))
      }), 128))
    ]),
    _: 1
  }))
}