import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"
import _imports_0 from '@/assets/images/icons/share-26.svg'


const _hoisted_1 = { class: "body-1 form-item-readonly" }
const _hoisted_2 = { class: "body-1 form-item-readonly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form = _resolveComponent("el-form")!

  return (_openBlock(), _createBlock(_component_el_form, {
    ref: "formRef",
    model: $setup.form,
    rules: $setup.formRules,
    "label-position": "top"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 12,
            class: "d-flex pt-1"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_button, {
                class: "ml-auto",
                type: "primary",
                onClick: $setup.updateEngagementGeneralInfo
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, {
            span: 12,
            class: "mt-2"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_card, { class: "card-form" }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('name'),
                    prop: "name"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.name) = $event)),
                        size: "small"
                      }, null, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('engagementId')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_1, _toDisplayString($setup.engagement.id), 1)
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('tenantId')
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_2, [
                        _createElementVNode("span", {
                          class: "tenant-hyperlink d-flex align-items-center",
                          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => ($setup.redirectToTenant && $setup.redirectToTenant(...args)))
                        }, [
                          _createTextVNode(_toDisplayString($setup.engagement.tenantId) + " ", 1),
                          _cache[3] || (_cache[3] = _createElementVNode("img", {
                            class: "ml-1 icon-size-5",
                            src: _imports_0
                          }, null, -1))
                        ])
                      ])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('features')
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.selectedFeatures,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.selectedFeatures) = $event)),
                        multiple: "",
                        size: "small"
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.availableFeatures, (feature) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: feature,
                              label: feature,
                              value: feature
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["model", "rules"]))
}