<template>
  <el-form ref="formRef" :model="form" :rules="formRules" label-position="top">
    <el-row>
      <el-col :span="12" class="d-flex pt-1">
        <el-button class="ml-auto" type="primary" @click="updateUserInfo">
          <h4>{{ $t('save') }}</h4>
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" class="mt-2">
        <el-card class="cursor-default">
          <el-form-item :label="$t('name')" prop="name">
            <el-input v-model="form.name" size="small" class="body-1" />
          </el-form-item>
          <el-form-item :label="$t('jobTitle')" prop="jobTitle">
            <div class="body-1 ml-2 form-item-readonly">
              {{ form.jobTitle }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('aadObjectId')" prop="aadObjectId">
            <div class="body-1 ml-2 form-item-readonly">
              {{ form.aadObjectId }}
            </div>
          </el-form-item>
          <el-form-item :label="$t('language')" prop="language">
            <el-select v-model="form.language" size="small">
              <el-option
                v-for="(language, i) in $i18n.availableLocales"
                :key="`Lang${i}`"
                :label="language"
                :value="language"
              />
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('template')" prop="template">
            <el-select v-model="form.template" size="small">
              <el-option
                v-for="(template, i) in templates"
                :key="i"
                :label="template"
                :value="template"
              />
            </el-select>
          </el-form-item>
        </el-card>
      </el-col>
    </el-row>
  </el-form>
</template>
<script lang="ts">
import {
  ElButton,
  ElCard,
  ElCol,
  ElForm,
  ElFormItem,
  ElMessage,
  ElInput,
  ElRow,
  ElSelect,
  ElOption
} from 'element-plus';
import { computed, ComputedRef, onMounted, ref, reactive } from 'vue';
import { useRouter } from 'vue-router';
import store from '@/store';
import i18n from '@/i18n';
import {
  CreateOrUpdateUserDto,
  UserViewModel
} from '@etp/etp-profile-client/axios';
import {
  CreateOrUpdateUserModel,
  UserDetailsModel
} from '@etp/etp-authorization-client/axios';

export default {
  name: 'UserProfile',
  components: {
    ElRow,
    ElCol,
    ElButton,
    ElInput,
    ElForm,
    ElFormItem,
    ElCard,
    ElSelect,
    ElOption
  },
  setup() {
    const router = useRouter();
    let email: string;

    const authorisationUser: ComputedRef<UserDetailsModel> = computed(
      () => store.getters['users/getAuthorizationUser']
    );
    const profileUser: ComputedRef<UserViewModel> = computed(
      () => store.getters['users/getProfileUser']
    );
    const templates: string[] = ['Default', 'Deloitte'];

    const form = ref({
      name: '',
      aadObjectId: '',
      jobTitle: '',
      language: '',
      template: ''
    });
    const formRef = ref<HTMLFormElement>();
    const formRules = reactive({
      name: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        },
        {
          min: 3,
          message: i18n.global
            .t('pleaseEnterNumCharacters')
            .replace('[num]', '3'),
          trigger: 'blur'
        }
      ],
      language: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: ['change', 'blur']
      },
      template: {
        required: true,
        message: i18n.global.t('thisFieldIsRequired'),
        trigger: 'blur'
      }
    });

    const updateUserInfo = () => {
      formRef.value?.validate(async (valid: boolean) => {
        if (valid) {
          await store.dispatch('users/createOrUpdateUser', {
            email: email,
            authorizationModel: {
              name: form.value.name,
              disabled: authorisationUser.value.disabled
            } as CreateOrUpdateUserModel,
            profileModel: {
              displayName: form.value.name,
              language: form.value.language,
              jobTitle: form.value.jobTitle,
              templateName: form.value.template
            } as CreateOrUpdateUserDto
          });
          await updateLocalVars();
          ElMessage({
            showClose: true,
            message: i18n.global.t('userInfoSaved')
          });
        }
      });
    };

    const updateLocalVars = async () => {
      email = router.currentRoute.value.params.email as string;
      await store.dispatch('users/fetchAuthorizationUser', email);
      await store.dispatch('users/fetchProfileUser', email);
      form.value.name = authorisationUser.value.name ?? '';
      form.value.language = profileUser.value.language ?? '';
      form.value.jobTitle = profileUser.value.jobTitle ?? '';
      form.value.template = profileUser.value.templateName ?? '';
      form.value.aadObjectId = authorisationUser.value.aadObjectId ?? '';
    };

    onMounted(() => {
      updateLocalVars();
    });

    return {
      form,
      formRef,
      formRules,
      templates,
      updateUserInfo
    };
  }
};
</script>
<style lang="scss" scoped></style>
