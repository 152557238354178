import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex mt-2" }
const _hoisted_2 = { class: "mt-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_tab = _resolveComponent("base-tab")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_el_row, null, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_page_header, {
              "main-title": `${_ctx.$t('engagementDetails')} - ${$setup.engagement.name}`
            }, {
              tabs: _withCtx(() => [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_router_link, { to: { name: 'EngagementGeneral' } }, {
                    default: _withCtx(({ isExactActive }) => [
                      _createVNode(_component_base_tab, { active: isExactActive }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('general')), 1)
                        ]),
                        _: 2
                      }, 1032, ["active"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_router_link, { to: { name: 'EngagementResources' } }, {
                    default: _withCtx(({ isExactActive }) => [
                      _createVNode(_component_base_tab, { active: isExactActive }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('resources')), 1)
                        ]),
                        _: 2
                      }, 1032, ["active"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_router_link, { to: { name: 'EngagementUsers' } }, {
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_base_tab, { active: isActive }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('users')), 1)
                        ]),
                        _: 2
                      }, 1032, ["active"])
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_router_link, { to: { name: 'EngagementApplications' } }, {
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_base_tab, { active: isActive }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('application')), 1)
                        ]),
                        _: 2
                      }, 1032, ["active"])
                    ]),
                    _: 1
                  }),
                  ($setup.showRbacTasks)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 0,
                        to: { name: 'EngagementRbacTasks' }
                      }, {
                        default: _withCtx(({ isActive }) => [
                          _createVNode(_component_base_tab, { active: isActive }, {
                            default: _withCtx(() => [
                              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('rbacTasks')), 1)
                            ]),
                            _: 2
                          }, 1032, ["active"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_router_link, { to: { name: 'EngagementConfiguration' } }, {
                    default: _withCtx(({ isActive }) => [
                      _createVNode(_component_base_tab, { active: isActive }, {
                        default: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('configuration')), 1)
                        ]),
                        _: 2
                      }, 1032, ["active"])
                    ]),
                    _: 1
                  }),
                  ($setup.showConnectors)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: { name: 'EngagementConnectors' }
                      }, {
                        default: _withCtx(({ isActive }) => [
                          _createVNode(_component_base_tab, { active: isActive }, {
                            default: _withCtx(() => [
                              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('connectors')), 1)
                            ]),
                            _: 2
                          }, 1032, ["active"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  ($setup.showStorage)
                    ? (_openBlock(), _createBlock(_component_router_link, {
                        key: 2,
                        to: { name: 'EngagementStorage' }
                      }, {
                        default: _withCtx(({ isActive }) => [
                          _createVNode(_component_base_tab, { active: isActive }, {
                            default: _withCtx(() => [
                              _createElementVNode("h4", null, _toDisplayString(_ctx.$t('storage')), 1)
                            ]),
                            _: 2
                          }, 1032, ["active"])
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _cache[0] || (_cache[0] = _createElementVNode("div", { class: "horizontal-line mt-2" }, [
                    _createElementVNode("hr")
                  ], -1))
                ])
              ]),
              _: 1
            }, 8, ["main-title"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ])
  ]))
}