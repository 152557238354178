import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "data d-grid content" }
const _hoisted_2 = { class: "actions d-flex" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, _renderList(4, (row) => {
    return _createElementVNode("div", {
      key: row,
      class: "mt-2 d-grid skeleton-table-row"
    }, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "loading-table-checkbox" }, null, -1)),
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.columns, (column) => {
          return (_openBlock(), _createElementBlock("div", {
            key: column,
            class: "loading-table-data"
          }))
        }), 128))
      ]),
      _createElementVNode("div", _hoisted_2, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.actions, (action) => {
          return (_openBlock(), _createElementBlock("div", {
            key: action,
            class: "loading-table-action"
          }))
        }), 128))
      ])
    ])
  }), 64))
}