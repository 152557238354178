import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex" }
const _hoisted_2 = { class: "hyperlink" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "d-flex",
      onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.goToPath(0)))
    }, [
      _createElementVNode("h6", _hoisted_2, _toDisplayString($props.rootStorageName), 1),
      _cache[1] || (_cache[1] = _createElementVNode("h6", { class: "ml-1" }, _toDisplayString('/'), -1))
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.path, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "d-flex ml-1"
      }, [
        _createElementVNode("div", {
          class: "d-flex hyperlink",
          onClick: ($event: any) => ($setup.goToPath(index + 1))
        }, [
          _createElementVNode("img", {
            src: require(`@/assets/images/icons/folder-16.svg`),
            height: "14",
            width: "14"
          }, null, 8, _hoisted_4),
          _createElementVNode("h6", _hoisted_5, _toDisplayString(item), 1)
        ], 8, _hoisted_3),
        _cache[2] || (_cache[2] = _createElementVNode("h6", { class: "ml-1" }, _toDisplayString('/'), -1))
      ]))
    }), 128))
  ]))
}