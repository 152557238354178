{
  "signIn": "Sign In",
  "loginMessage": "Welcome back, please login to your account to continue to our application.",
  "login": "Log in",
  "etpMobile": "ENTERPRISE TAX PLATFORM",
  "welcome": "Welcome",
  "welcomeBack": "Welcome back",
  "yourOverview": "This is your overview, with quick actions.",
  "welcomeMessage": "Discover the best tax analytics platform",
  "etpVersion": "Version 1.0",
  "welcomeText": "ETP is the leading destination to process and run tax analytics for your business. Proceed to get meaningful insights from your data.",
  "addNewDatasource": "Add new datasource",
  "secureConnection": "Secure connection",
  "secureConnectionSubtitle": "Examples: oracle, sap",
  "specifyKindNewSource": "Specify which kind of new source will be added.",
  "nameOfSource": "Name of source",
  "headerTextReplace": ";",
  "healthCheck": "Healthcheck",
  "fileTextQualifier": "Quote Character",
  "fileDelimiter": "File delimiter:",
  "succeeded": "succeeded",
  "failed": "failed",
  "userAdded": "Request to add user sent.",
  "editFolder": "Edit folder",
  "pleaseProvideName": "Please provide name",
  "mayTakeMoment": "This may take a few minutes.",
  "youShallNotPass": "You have no access to this source.",
  "contactGeenen": "Please contact the administrator.",
  "fillInRequiredSettingsSource": "Fill in the required settings to make the source.",
  "fileLine": "Line ending",
  "options": "Options",
  "data": "Data",
  "analysisServices": "Analysis Services",
  "getStarted": "Get Started",
  "actions": "Actions",
  "loaded": "Loaded",
  "loading": "Loading...",
  "quoteChar": "Quote Char",
  "headerFileLine": "Header file line:",
  "fileUnicode": "File unicode:",
  "fileIsQueued": "File is being staged",
  "fileAreQueued": "Files are getting staged",
  "fileHasHeader": "File has header:",
  "fileTextQualified": "File text qualified:",
  "validateParameters": "Please validate the entered parameters.",
  "createTask": "Please, create your task first.",
  "startWizard": "Wizard",
  "addDatasource": "New Source",
  "parameters": "Parameters",
  "addMoreFiles": "Add more files",
  "confirmationRequired": "Confirmation required",
  "validSourceName": "Please enter a valid source name.",
  "task": "Task",
  "lastUpdated": "Last updated",
  "principalId": "Pincipal ID",
  "activated": "Activated",
  "lastReload": "Reload",
  "applicationSettings": "Application Settings",
  "userSettings": "User Settings",
  "settings": "Settings",
  "open": "Open",
  "strangeHappened": "Something strange happened.",
  "noRetrievingDatasources": "The data sources could not be retrieved.",
  "oracle": "Oracle",
  "sqlDb": "SQL DB",
  "close": "Close",
  "vatReturns": "VAT returns",
  "filtering": "Filtering",
  "materials": "Materials",
  "invoices": "Invoices",
  "companies": "Companies",
  "fiscalCalendar": "Fiscal Calendar",
  "lastUpload": "Last upload",
  "uploadAll": "Upload all",
  "loggedInAs": "Logged in as",
  "help&feedback": "Help & feedback",
  "gettingStartedGuide": "Getting started guide",
  "profile": "Profile",
  "profileSettings": "Profile settings",
  "helpCenter": "Help Center",
  "contactUs": "Contact Us",
  "selectDatanodeFirst": "and select a data node first.",
  "about": "About",
  "privacy": "Privacy",
  "privacyStatement": "Privacy Statement",
  "termsOfUse": "Terms of Use",
  "general": "General",
  "users": "Users",
  "tenants": "Tenants",
  "newField": "new field",
  "addField": "add field",
  "addFile": "Add File",
  "delimiter": "Delimiter",
  "lineEnding": "Line Ending",
  "lineEndingPlaceholder": "{CR}{LF}",
  "codeName": "Encoding Name",
  "utf8": "utf-8",
  "delete": "Delete",
  "noSelectionDetected": "No Selection Detected",
  "pleaseGoTabNode": "Please go to the tab 'node selection'",
  "nameNewField": "Name of new field",
  "language": "Language",
  "logout": "Logout",
  "privateEndpoints": "Private Endpoints",
  "labeling": "Labeling",
  "materialType": "Material Type",
  "awaiting": "Awaiting",
  "rebuildAll": "Rebuild All",
  "activateAll": "Activate All",
  "upload": "Upload",
  "download": "Download",
  "userManagement": "User management console",
  "userManagementSubtitle": "Add or remove users",
  "addUser": "Add user",
  "followInstructions": "Please follow the instructions below.",
  "start": "Start",
  "displayName": "Display name",
  "name": "Name",
  "value": "Value",
  "results": "Results",
  "addDashboard": "Add Dashboard",
  "enterName": "Enter your name.",
  "email": "E-mail",
  "sendAs": "Send as",
  "enterEmail": "Enter e-mail address.",
  "userRole": "User role",
  "applicationRole": "Application role",
  "specifyNode": "Specify node for building.",
  "selectRole": "Select roles.",
  "selectTenant": "Select Tenant",
  "seedDatabase": "Seed Database",
  "provisionResources": "Provision resources",
  "save": "Save",
  "forgotToSave": "It looks like you have been editing something.\nIf you close the form before saving, your changes will be lost.",
  "allUsers": "All Users",
  "overview": "Overview",
  "paginated": "Paginated",
  "tenantManagement": "Tenant management console",
  "addTenants": "Add or update tenants",
  "activeDirectoryId": "Active Directory ID",
  "resourceGroupName": "Resource Group Name",
  "confirmDeleteApplication": "You are about to delete an application. This cannot be undone.",
  "resourceName": "Resource name",
  "resourceConfiguration": "Resource configuration",
  "orchestrator": "Orchestrator",
  "processFunctions": "Process Functions",
  "activeUsers": "Active users",
  "functions": "Functions",
  "dataFactory": "Data Factory",
  "azureDataFactory": "Azure Data Factory",
  "azureDatabricks": "Azure databricks",
  "addTenant": "Add tenant",
  "usersTenant": "Users Tenant",
  "usersDeloitte": "Users Deloitte",
  "costMtd": "Costs month to date",
  "goBack": "Go back",
  "costLastMonth": "Costs last month",
  "active": "Active",
  "rejected": "Rejected",
  "reviewing": "Reviewing",
  "approved": "Approved",
  "downloadTemplate": "Download template",
  "uploadTemplate": "Upload template",
  "approve": "Approve",
  "reject": "Reject",
  "setReminder": "Set reminder",
  "reset": "Reset",
  "help": "Help",
  "sortBy": "Sort by",
  "userAddSuccess": "The user has been succesfully added/modified.",
  "selectFollowUpAction": "Select a follow-up action below.",
  "notification": "Notifications",
  "markAsRead": "Mark all as read",
  "tenant": "Tenant",
  "editTenant": "Edit Tenant",
  "toggleActive": "Active / Inactive",
  "copyValue": "Copy To Clipboard",
  "dataType": "Data Type",
  "taxDataModel": "Tax data model",
  "mappings": "Mappings",
  "completed": "Completed",
  "update": "Update",
  "configure": "Configure",
  "configuation": "Configuration",
  "source": "Source",
  "sources": "Sources",
  "tables": "Tables",
  "powerBI": "Power BI",
  "FSI": "FSI",
  "used": "Used",
  "addLabels": "New Label",
  "build": "Build",
  "import": "Import",
  "modeling": "Modeling",
  "updateTenant": "Update tenant",
  "editUser": "Edit User",
  "updateUser": "Update user",
  "userSuccesAdded": "User is successfully added / modified.",
  "reload": "Reload",
  "datasources": "Datasources",
  "success": "success",
  "running": "running",
  "edit": "Edit",
  "activeStage": "Active stage",
  "activeTask": "Active task",
  "completedStage": "Completed stage",
  "completedTask": "Completed task",
  "fileUploadSuccess": "File uploaded successfully",
  "fileProcessingSuccess": "File processed successfully",
  "taskCompleteSuccess": "Task completed successfully",
  "createInstance": "Create instance",
  "definitions": "Definitions",
  "instanceOf": "Instance of",
  "instances": "Instances",
  "noDefinitions": "There are no definitions available",
  "noInstances": "There are no instances available",
  "stage": "Stage",
  "azureSettings": "Azure Settings",
  "configured": "Configured",
  "newUser": "New User",
  "tenantFormName": "Tenant Name",
  "fillInTenantName": "Enter a name for the tenant to be created",
  "dropFilesHere": "Drop file here or",
  "dropFileHere": "Click here to upload your file",
  "payAttention": "Pay attention to the requirements.",
  "fileStorage": "File Storage",
  "uploadFiles": "Upload Files",
  "queryEditor": "Query Editor",
  "configureDataNodes": "Configure data nodes",
  "selectionModel": "Select the model",
  "fields": "Fields",
  "dataNodes": "Data Nodes",
  "nodes": "Nodes",
  "solution": "Solution",
  "node": "Node",
  "version": "Version",
  "editModule": "Edit Module",
  "savedAsVersion": "Saved as version",
  "overwrite": "Overwrite",
  "saveConfiguration": "Save configuration",
  "newTenant": "New Tenant",
  "dataFactorySubheader": "This is a service that allows developers to integrate disparate data sources.",
  "databricks": "Databricks",
  "databricksSubheader": "This gives users a single platform for Big Data processing and Machine Learning.",
  "oops": "Oops!",
  "somethingStrange": "Something strange happened.",
  "keepCalm": "Keep calm and try again.",
  "tryAgain": "Try Again",
  "valid": "Valid",
  "personalInformation": "Personal Information",
  "fillInPersonalInformation": "Fill in or adjust the personal information of a user",
  "invalid": "Invalid",
  "roles": "Roles",
  "fillInRoles": "Define the roles and allocate tenants to the user",
  "pipeline": "Pipeline",
  "pipelines": "Pipelines",
  "template": "Template",
  "na": "N/A",
  "analyticalModelStatus": "Analytical Status",
  "initiate": "Initiate",
  "addNewTest": "Add new test",
  "encoding": "Encoding",
  "modelStatus": "Model status",
  "copyString": "Copy",
  "testingModelStatus": "Testing status",
  "nodeSchema": "Node schema",
  "addNewBranch": "Add new branch",
  "addNewCategory": "Add new category",
  "editCategories": "Edit categories",
  "addNewSource": "Add new source",
  "parentBranch": "Parent branch",
  "branchName": "Branch name",
  "categories": "Categories",
  "nodeProcessing": "Node processing",
  "nodeType": "Node type",
  "newNodeName": "Name of the new node",
  "initialVersionName": "Initial version name",
  "createNewUser": "Create New User",
  "anyUsersAddedListed": "User(s) added will be listed here.",
  "nodeSequence": "Node Sequence",
  "addNode": "Add node",
  "createNode": "Create new node",
  "buildNodes": "Build data nodes",
  "rebuild": "Rebuild",
  "test": "Test",
  "toggleRebuild": "Toggle Rebuild",
  "configureBuild": "Configure Build",
  "pipelineName": "Pipeline",
  "openServiceWindow": "Open Service In A New Tab / Window",
  "link": "Link to execution",
  "status": "Status",
  "runStart": "Start time",
  "runEnd": "End time",
  "monitorExecution": "Link to execution details",
  "category": "Category",
  "az": "A-Z",
  "dashboards": "Dashboards",
  "dashboardsDesc": "The platform has a built-in dashboarding functionality. Data can be imported into the dashboard and the dashboard can be uploaded and published in the platform.",
  "reportName": "Dashboard name",
  "removeResources": "Remove resources",
  "confirmRemoveTenant": "Are you sure you want to delete this tenant?",
  "confirmRemoveTenantResources": "Are you really sure you want to remove ALL resources? This can't be undone. All data will be lost.",
  "discard": "Discard",
  "discardAll": "Discard all",
  "replaceFile": "Replace file",
  "administration": "Administration",
  "azureServices": "Azure Services",
  "overviewAzureServcies": "Overview of Azure services",
  "none": "None",
  "user": "User",
  "selectApplication": "Please select an application to add.",
  "selectUser": "Please select a user to add.",
  "available": "Available",
  "pleaseWaitWhile": "This can take some minutes.",
  "pipelineMonitor": "Pipeline Monitor",
  "console": "Console",
  "newNode": "New Node",
  "selectNodeForBuilding": "Select Nodes to Build Model",
  "makeSelection": "Make selection in the console at the right of the screen,",
  "buildCnm": "to start building up the common node model",
  "editQueries": "to start editing the queries.",
  "setNodeActive": "Set node on status active",
  "nodeWillOverwritten": "Node will be overwritten.",
  "selectNodesEditQueries": "Select Nodes to Edit Queries",
  "reloadAll": "Reload All",
  "tenantName": "Name",
  "storageAccountName": "Storage account name",
  "eventGridTopic": "Event grid topic",
  "eventGridSubscription": "Event grid subscription",
  "destination": "Destination",
  "functionAppName": "Function app name",
  "tenantId": "Tenant ID",
  "projectDatabaseConnectionstring": "Project database connection string",
  "stagingDatawarehouseConnectionstring": "Staging datawarehouse connection string",
  "modelDatawarehouseConnectionstring": "Model datawarehouse connection string",
  "createResourceGroup": "Create resource group",
  "dashboardsUnavailable": "Please switch the Power BI and Analysis Service On to view the dashboards.",
  "pausing": "Pausing",
  "resuming": "Resuming",
  "paused": "Paused",
  "refreshResourceStatus": "Refresh status",
  "reprocess": "Reprocess",
  "reprocessedMessage": "File is re-imported.",
  "powerBi": "Power BI",
  "versionNameToSave": "Version name to save",
  "copied": "Copied!",
  "errorReprocessing": "Error reprocessing",
  "building": "Building!",
  "configuring": "Configuring!",
  "etp": "ETPcore",
  "core": "core",
  "byDeloitte": "by Deloitte",
  "empty": "Empty",
  "0": "0.0",
  "seq": "Seq X.X",
  "hybridDataIntegration": "Hybrid Data Integration",
  "apacheSparkBased": "Apache Spark-based analytical platform",
  "synapseStaging": "Synapse Staging",
  "analyticalService": "Analytical Service",
  "synapseNodeModel": "Synapse Nodemodel",
  "projectManagementDatabase": "Project Management Database",
  "selectTestEditQueries": "Select Test to Edit Queries",
  "activity": "Activity",
  "details": "Details",
  "testSelection": "Test Selection",
  "newTest": "New Test",
  "updateTestMeta": "Update test meta",
  "updateQuery": "Update query",
  "subscriptionId": "Subscription ID",
  "dataFactoryName": "Data Factory Name",
  "analysisServicesConnectionString": "Analysis Services Connection String",
  "analysisServiceDescription": "Underlying Model for Power BI",
  "manuallyAdded": "needs to be manually added within the model layer.",
  "contactAdmin": "Please contact the administrator.",
  "notPresent": "Not Present",
  "powerBiEmbeddedResourceName": "Power Bi Embedded Resource Name",
  "workspaceName": "Workspace Name",
  "sqlInCloud": "SQL in cloud",
  "solutionNode": "Solution Node",
  "description": "Description",
  "businessKey": "Business Key",
  "addNewField": "Add new field",
  "inactiveUser": "User is not active",
  "ifThisIsMistake": "If you think this is a mistake,",
  "testTopic": "Test Topic",
  "initialVersion": "Initial Version",
  "testTheme": "Test Theme",
  "testDomain": "Test Domain",
  "testName": "Test Name",
  "testNumber": "Test Number",
  "testId": "Test Id",
  "testDescription": "Test Description",
  "userNotFound": "You are not registered user. Please contact the administrator.",
  "createNewTest": "Create new test.",
  "noTenantSelected": "You have not selected a tenant. Please select a tenant first.",
  "utc": "UTC",
  "copy": "Copy",
  "ap": "AP",
  "control": "Control",
  "validVat": "Valid VAT",
  "vatVersion": "VAT_MODEL_100",
  "vatId": "VAT ID",
  "vat": "VAT",
  "putShortDescription": "Put a short test description here.",
  "result": "Result",
  "noTenantUser": "You are not assigned to a tenant!",
  "enterUserName": "Enter the First and Last name of user.",
  "enterValidEmail": "The email address is not valid.",
  "selectValue": "Please select a value from the dropdown-list.",
  "enterTenantName": "Please enter the name of the Tenant.",
  "cannotBeEmpty": "This field cannot be empty or begin with special characters.",
  "noSpaceUnderscore": "This field value cannot contain space(s) or special characters other than :./",
  "noSpecialCharsAllowed": "Only uppercase, lowercase letters and '-' allowed",
  "errorOccured": "An error occured during the execution of your request.",
  "errorNo": "Your error number is:",
  "useErrorNo": "Please use this error number and contact the administrator.",
  "tenantSettings": "Tenant Settings",
  "deleteDashboard": "Are you sure you want to delete the dashboard?",
  "downloadLogs": "Download logs",
  "provisioning": "Provisioning...",
  "dataJourney": "Data journey",
  "visualisation": "Visualisation",
  "developers": "Developers",
  "analyticalModel": "Analytical Model",
  "testing": "Testing",
  "dashboarding": "Dashboarding",
  "confirmDeleteUser": "Are you sure you want to delete user?",
  "queue": "Queue",
  "position": "Position",
  "clickToUpload": "Click to upload",
  "staged": "Staged",
  "enqueued": "Enqueued",
  "processing": "Processsing",
  "finished": "Finished",
  "showCurrent": "Show Current",
  "showAll": "Show All",
  "removeAll": "Remove All",
  "noFilesInCurrentQueue": "No files in current queue.",
  "submit": "Submit",
  "saveAsVersion": "Save as version",
  "model": "Model",
  "createNewNode": "Create New Node",
  "view": "View",
  "labels": "Labels",
  "fileUpload": "File Upload",
  "fileUploadQueue": "File Upload Queue",
  "filesFrom": "Files From",
  "dashboardUpload": "Dashboard Upload",
  "dashboardUploadQueue": "Dashboard Upload Queue",
  "noReportsInCurrentQueue": "No reports in current queue.",
  "dashboardsToDisplay": "Dashboards to display.",
  "noDashboards": "Upload dashboard(s) and make sure Power BI Service is turned on to view the dashboard(s).",
  "viewDashboards": "Switch the Power BI Service on to view the dashboard(s).",
  "totalUsers": "Total Users",
  "deloitteUsers": "Deloitte Users",
  "created": "Created",
  "lastLogin": "Last Login",
  "tenantDetails": "Tenant details",
  "removable": "Removable",
  "infrastructure": "Infrastructure",
  "dataWarehouseModel": "Model Data Warehouse",
  "dataWarehouseStaging": "Staging Data Warehouse",
  "functionApp": "Function App Name",
  "powerBIEmbeddedResource": "Power BI Embedded Resource",
  "projectDB": "Project database",
  "storageAccount": "Storage account name",
  "workspace": "Workspace name",
  "reporting": "Reporting",
  "export": "Export",
  "environment": "Environment",
  "versionNo": "Version Number",
  "noTenant": "No tenant",
  "run": "Run",
  "jobGrade": "Job Grade",
  "add": "Add",
  "newFile": "New file",
  "modelingConfigDesc": "Data in the staging environment is transformed to a generic data model called the Common Node Model and transferred into a data warehouse or database. These data transformations can be modified in the Configuring tab, and deployed in the Building tab.",
  "modelingBuildingDesc": "This is where you can choose wether you want certain nodes to be turned on or off.",
  "monitorTabDesc": "This is where you can see the status of actions created during data processing.",
  "runTabDesc": "Here you can choose which pipelines to run",
  "analyticalModelOverviewDesc": "Based on the data journey, aggregations and calculations are performed and data sets to be analyzed are created within the Analytical Model. This data journey can be modified in the Configuring tab, and deployed in the Building tab.",
  "analyticalModelBuildingOverviewDesc": "This is where you can choose wether you want certain nodes to be turned on or off.",
  "analyticalModelRunOverviewDesc": "Here you can choose which pipelines to run.",
  "testingConfigDesc": "Predefined analyses are performed on the data in the Common Node Model and the results are written out to the model. These tests can be modified in the Configuring tab, and deployed in the Building tab.",
  "testingBuildingDesc": "Model your data.",
  "testingRunOverviewDesc": "Here you can choose which pipelines to run.",
  "importDesc": "Data can be imported from different sources and through different methods, ranging from automated pipelines to manual uploads. You can create sources to group input data and limit data access to specific users.",
  "filteringStorageDesc": "Filtering determines which data is used in the analysis. For example, which companies are in scope. You can apply filtering through downloading the template Excel file, apply the desired filters and upload the Excel file in the platform.",
  "filteringConfigDesc": "Filter your data.",
  "filteringBuildDesc": "This is where you can choose whether you want certain nodes to be turned on or off.",
  "labelingBuildDesc": "This is where you can choose whether you want certain nodes to be turned on or off.",
  "labelingConfigDesc": "Label your data.",
  "labelingOverviewDesc": "The data in the platform can be enriched with manual input. Through downloading the data as an Excel file, adding the data enrichment in the file and upload the Excel file in the platform, the data in the platform will be enriched.",
  "yes": "Yes",
  "no": "No",
  "addNewNode": "Add new node",
  "nameOfNode": "Name of node",
  "EULA": "End User License Agreement",
  "acceptTerms": "I have read the terms in the License Agreement.",
  "accept": "Accept",
  "tenantDesc": "This is where you manage tenants",
  "noSourcesInCurrentQueue": "No sources in current queue.",
  "allFiles": "All files",
  "noSource": "No data to display. Add a new source.",
  "newNodeModal": "Create new Node",
  "new": "New",
  "newTestNode": "New Test Node",
  "userDesc": "This is where you can view and update users of ETP.",
  "newDashboard": "New Dashboard",
  "dashboardName": "Dashboard name",
  "deployments": "Deployments",
  "deploymentStatus": "Deployment status",
  "refresh": "Refresh",
  "sync": "Sync",
  "syncRbacTenantUsers": "Synchronize RBAC of tenant users",
  "type": "Type",
  "metaCheck": "Metacheck",
  "queued": "Queued",
  "nodeTypes": "Node types",
  "deploy": "Deploy",
  "scope": "Scope",
  "noData": "No data to display.",
  "tenantAzureSettings": "Tenant Azure Settings",
  "rolesAddedSuccessfully": "Roles added successfully",
  "azureComponents": "Azure Components",
  "configuration": "Configuration",
  "taskScheduler": "TaskScheduler",
  "tenantConfigurationDesc": "Here you can see and copy the tenant configurations for tenant",
  "tenantAzureSetting": "Tenant Azure Setting",
  "compose": "Compose",
  "id": "Id",
  "aadObjectId": "Azure Active Directory Object ID",
  "syncRoles": "Synchronize roles",
  "saveAndDeploy": "Save and deploy",
  "editGeneralInfo": "Edit general information",
  "addNewFolder": "Add new folder",
  "createFolder": "Create Folder",
  "confirmImportFolder": "Are you sure you want to import all files in this folder?",
  "time": "Time",
  "monitor": "Monitor",
  "executions": "Executions",
  "file": "File",
  "confirmDeleteTenantAzureSetting": "Are you sure you want to delete this Azure resource?",
  "files": "Files",
  "folders": "Folders",
  "filters": "Filters",
  "remove": "Remove",
  "scopeMask": "Scope Mask",
  "openDashboard": "Open dashboard",
  "openDashboardNewTab": "Open dashboard (new tab)",
  "editFields": "Edit fields",
  "editProperties": "Edit properties",
  "whereYouLeftOff": "Where you left off",
  "newStoragePurposeDescription": "Please provide a new storage type. Note: it will be part of URL and cannot be changed later!",
  "thisPurposeAlreadyExists": "This storage type already exists",
  "quickActions": "Quick actions",
  "support": "Support",
  "pipelineRuns": "Pipeline runs",
  "seeAllDashboards": "see all dashboards",
  "exports": "Exports",
  "importFiles": "Import files",
  "runDataModel": "Run data model",
  "runTests": "Run tests",
  "branch": "Branch",
  "runPipeline": "Run pipeline",
  "pleaseProvideGUID": "Please provide a GUID",
  "resourceNameMustStartWithnletp": "Resource name must start with nletp",
  "onlyAlphanumericCharactersLowerCase": "Only lowercase alphanumeric characters are allowed",
  "onlyAlphaNumericCharactersLowerCaseAndDash": "Only lowercase alphanumeric and dash characters are allowed",
  "resourceGroupNameMustStartWithNLETP": "Resouce name must start with NL-ETP-",
  "manual": "Manual",
  "manualDesc": "This is the manual that guides you through our platform.",
  "feedback": "Feedback",
  "pleaseProvideDelimiter": "Please provide a delimeter",
  "pleaseProvideQuoteCharacter": "Please provide a quote character",
  "pleaseProvideLineEnding": "Please provide a line ending character",
  "pleaseProvideEncodingName": "Please provide encoding name",
  "feedbackDesc": "Here you can provide feedback to help us improve our platform.",
  "continue": "Continue",
  "feedbackForm": "Feedback form",
  "helpForm": "Help form",
  "emailSubject": "Subject",
  "emailMessage": "Message",
  "send": "Send",
  "thisFieldIsRequired": "This field is required",
  "noDuplicateFolderName": "There already exists a folder with this name",
  "pleaseEnterNumCharacters": "Please enter at least [num] characters",
  "thereIsNoActivityYet": "There is no activity yet",
  "noAccessToResource": "403 - Forbidden",
  "noFolderAccessUser": "You do not have access to this folder. Please ask your engagement manager to grant you access",
  "noFolderAccessManager": "You do not have access to this folder",
  "confirmResetTenantDeployment": "Are you sure you want to reset the tenant deployment?",
  "cancel": "Cancel",
  "confirm": "Confirm",
  "areYouSure": "Are you sure?",
  "pipelineStatus": "Pipeline status",
  "nodemodelFrameworkDatabase": "Node Model Framework Database",
  "importStorageAccount": "Import Storage Account",
  "pipelineManager": "Pipeline Manager",
  "dashboardPowerBi": "Dashboard Power Bi",
  "analysisService": "Analysis Services",
  "exportStorageAccount": "Export Storage Account",
  "dashboardWorkspace": "Dashboard Workspace",
  "dashboardStorageAccount": "Dashboard Storage Account",
  "integrate": "Integrate",
  "notSet": "Not set",
  "message": "Message",
  "currentQueue": "Current Queue",
  "fullQueue": "Full Queue",
  "noBuilds": "There are no builds to show",
  "noRuns": "There are no runs to show",
  "noMonitor": "There is nothing to monitor",
  "noImport": "There is nothing imported yet",
  "noPipeline": "There is no pipeline to monitor",
  "noUsers": "There are no users to import",
  "noTests": "There are no tests to show",
  "noCosts": "There are no costs to show",
  "runs": "Runs",
  "powerbi": "Power BI",
  "tenantConfiguration": "Tenant Configuration",
  "error": "Error",
  "mustBeAtLeastEightChars": "Resource name must be at least 8 characters long",
  "mustBeAtLeastThreeCharactersLong": "Resource name must be at least 3 characters long",
  "mustBeShorterThan24": "Resource name cannot be longer than 24 characters",
  "tasks": "Tasks",
  "rbacTasks": "RBAC Tasks",
  "newFolder": "New Folder",
  "outcome": "Outcome",
  "uploaded": "Uploaded",
  "imported": "Imported",
  "changeSettings": "Change settings",
  "importToDatabase": "Import to database",
  "searchUser": "Search user...",
  "searchFiles": "Search file...",
  "searchTenants": "Search tenant...",
  "searchEngagements": "Search engagement...",
  "mustIncludeEnvironment": "Resource name must include the envoirenment",
  "orchestrations": "Orchestrations",
  "newOrchestration": "New orchestration",
  "noTasks": "There are no tasks to show",
  "plannedExecutionDate": "Planned execution date",
  "orchestrationStatus": "Orchestration status",
  "addNewOrchestration": "Add a new orchestration",
  "updateOrchestration": "Update orchestration",
  "createOrchestration": "Create orchestration",
  "pickADate": "Pick a day",
  "orchestrationType": "Orchestration type",
  "dashboardStorage": "Dashboard storage",
  "createContainers": "Create containers",
  "createGatewayEntry": "Create gateway entry",
  "dataLakeStorageName": "Data Lake Storage name",
  "deployBlobPrivateEndpoint": "Deploy blob private endpoint",
  "deployDataFactoryPrivateEndpoint": "Deploy data-factory private endpoint",
  "deployDfsPrivateEndpoint": "Deploy dfs private endpoint",
  "deployFilePrivateEndpoint": "Deploy file private endpoint",
  "deployQueuePrivateEndpoint": "Deploy queue private endpoint",
  "deploySqlPrivateEndpoint": "Deploy sql private endpoint",
  "deployApacheSparkPool": "Deploy Apache Spark Pool",
  "deploySqlPool": "Deploy SQL pool",
  "deploySqlServerPrivateEndpoint": "Deploy sqlServer private endpoint",
  "deployMachineLearningPrivateEndpoint": "Deploy Azure MachineLearning private endpoint",
  "deployKeyVaultPrivateEndpoint": "Deploy Key Vault private endpoint",
  "deployContainerRegistryPrivateEndpoint": "Deploy Azure Container Registry private endpoint",
  "deployTopicPrivateEndpoint": "Deploy topic private endpoint",
  "managedIdentityName": "Managed identity name",
  "managedIdentityNames": "Managed identity names",
  "powerBiCapacityName": "Power BI capacity name",
  "preventDataExfiltration": "Prevent data exfiltration",
  "seedEntries": "Seed entries",
  "sqlServerName": "SQL server name",
  "applications": "Applications",
  "application": "Application",
  "applicationsDesc": "This is where you can establish connections to outside applications",
  "newApplication": "New application",
  "objectId": "Object identifier",
  "updateApplication": "Edit application",
  "noApplications": "There are no external applications to show",
  "addApplication": "Add application",
  "notValidJson": "Invalid json format",
  "costs": "Costs",
  "costsOverview": "This is where you find an overview of the costs per resource",
  "cost": "Costs",
  "analytical": "Analytical",
  "fieldsFromQry": "Fields from query to add",
  "documentation": "Documentation",
  "noExport": "There is nothing to export",
  "currentStage": "Current stage",
  "confirmApproveTask": "Are you sure you want to approve this task?",
  "confirmDisapproveTask": "Are you sure you want to remove the approval from this task?",
  "confirmEditTask": "Are you sure you want to edit this task?",
  "confirmRemoveUserFromTenant": "Are you sure you want to delete this user from the tenant?",
  "path": "Path",
  "hidePipeline": "Hide Pipeline",
  "taskNotEditable": "The parameters of this task are not editable",
  "total": "Total",
  "noConfigs": "There are no tenant configurations to show",
  "toggleClicked": "toggling...",
  "pipelineRunning": "[pipeline] is running",
  "nodesBuilding": "The selected nodes are being built",
  "addingTask": "Orchestration is being added. This can take a second",
  "editTask": "Orchestration is being edited. This can take a second",
  "togglingPipeline": "Toggling pipeline visibility...",
  "togglingUser": "Toggling user...",
  "togglingPowerBI": "Toggling Power BI...",
  "togglingAnalysisServices": "Toggling Analysis Services...",
  "noConfigurations": "No configurations to display.",
  "gitRepository": "Node Model Git Repository",
  "onlyLettersNumbersDots": "Only letters, numbers and '.' are allowed",
  "onlyLettersNumbersUnderScore": "Only letters, numbers and '_' are allowed",
  "onlyLettersNumbersUnderScoreSquareBrackets": "Only letters, numbers, '_' and '[' ']' are allowed",
  "onlyLettersNumbersDashUnderScore": "Only letters, numbers, '-' and '_' are allowed",
  "notValidField": "[name] is not a valid field name.",
  "noDuplicateBranchName": "There already exists a branch with this name",
  "noDuplicateNodeName": "There already exists a node with this name",
  "testsRunning": "The selected tests are being run.",
  "inherit": "Inherit From Folder",
  "clone": "Clone",
  "urlCloned": "URL copied to clipboard",
  "profileDesc": "This is where you can see your personal information and the tenants you are a part of",
  "myInfo": "My information",
  "yourTenants": "Your tenants",
  "yourTenantsDesc": "Click on one of the tenants to direcly switch to that tenant",
  "noTenants": "You have no tenants assigned to you",
  "stopCostSaving": "Stop Cost Saving",
  "stopCostSavingDesc": "For how long shall the cost saving functionality be disabled?",
  "custom": "Custom",
  "hours": "Hours",
  "minutes": "Minutes",
  "estimatedPeriodEnd": "Estimated period end",
  "noEmptyFieldAllowed": "Field cannot be empty",
  "dbSchema": "Database schema",
  "nameMustMinNumCharacters": "Name must be at least [num] characters",
  "invalidValue": "Invalid value",
  "invalidDataType": "Invalid data type",
  "inherited": "Inherited",
  "unknown": "Unknown",
  "removedfromdatabase": "Removed from db",
  "validationsuccess": "Validated",
  "validationfailed": "Validation failed",
  "validationerror": "Validation error",
  "admin": "Admin",
  "configurer": "Configurer",
  "superConfigurer": "Super configurer",
  "superUser": "Super user",
  "addNewProperty": "Add new property",
  "properties": "Properties",
  "savingChanges": "Saving changes...",
  "deletingFile": "Deleting file...",
  "not-a-valid-json": "Not a valid json",
  "containerName": "Container name",
  "fileName": "File name",
  "userInfoSaved": "User information is saved",
  "saved": "Saved",
  "applicationInfoSaved": "Application information is saved",
  "tenantInfoSaved": "Tenant information saved",
  "resourceDeleted": "Resource has been deleted",
  "confirmStartDeployment": "Are you sure you want to start deployment?",
  "confirmDeleteResource": "Are you sure you want to delete this resource?",
  "tenantAzureSettingsSaved": "The tenant azure settings have been saved",
  "tenantResourceSettingsSaved": "The tenant resources have been saved",
  "azureComponentAdded": "Azure component added",
  "deploymentInProgress": "Your deployment is in progress",
  "tenantDeploymentReset": "Your tenant deployment has been reset",
  "resource": "Resource",
  "resourcesRemoved": "All resources have been removed",
  "azureResourceDeleted": "The azure resource has been deleted",
  "tenantIntegrationIdsSaved": "The tenant integration IDs have been saved",
  "rolesSyncing": "Roles are being synchronized",
  "tenantUserDeleted": "User has been deleted from the tenant",
  "tenantApplicationDeleted": "Application has been deleted from the tenant",
  "applicationAdded": "Application has been added",
  "confirmRemoveApplication": "Are you sure you want to delete this application?",
  "applicationDeleted": "Application has been deleted",
  "configurationSaved": "Configuration has been saved",
  "newBranchAdded": "New branch added",
  "newNodeAdded": "New node added",
  "newSourceAdded": "New source added",
  "newCategoryAdded": "New category added",
  "newTestAdded": "New test added",
  "tenantRemoved": "Tenant has been removed",
  "newTenantAdded": "The tenant has been added",
  "enterValidEmailAddress": "Enter a valid email address",
  "folderAdded": "Folder has been added",
  "resultingSystemName": "Resulting system name",
  "folderNameTaken": "This folder name is already taken!",
  "dashboardDeleted": "The selected dashboard(s) have been deleted",
  "orchestrationUpdated": "Orchestration has been updated",
  "orchestrationAdded": "Orchestration has been added",
  "noDuplicateField": "There already exists a field with this name",
  "noDuplicateProperty": "There already exists a property with this name",
  "noDuplicateCategory": "There already exists a category with this name",
  "noResultsFound": "There where no results found with the provided query",
  "pipelineStatusFilter": "Filter statuses",
  "searchOrchestrations": "Search orchestrations",
  "filterStatus": "Filter by status",
  "importingFilesToDb": "Importing files to database",
  "importingFileToDb": "Importing file to database",
  "InProgress": "In Progress",
  "TimedOut": "Timed Out",
  "Queued": "Queued",
  "Success": "Success",
  "Succeeded": "Succeeded",
  "Failed": "Failed",
  "Canceling": "Canceling",
  "Cancelled": "Cancelled",
  "newRole": "New Role",
  "editRole": "Edit Role",
  "rolesDesc": "This is where you can create new roles, and edit the existing ones",
  "roleInfoSaved": "Role information is saved",
  "noRoles": "There are no roles to show",
  "permissions": "Permissions",
  "permissionsDesc": "This is where you can create new permissions, and edit the existing ones",
  "newPermission": "New Permission",
  "noPermissions": "There are no permissions to show",
  "editPermission": "Edit Permission",
  "deletePermission": "Delete Permission",
  "deleteRole": "Delete Role",
  "permissionInfoSaved": "Permission information is saved",
  "confirmRemovePermission": "Are you sure you want to remove this permission?",
  "confirmRemoveRole": "Are you sure you want to remove this role?",
  "permissionRemoved": "Permission has been removed",
  "roleRemoved": "Role has been removed",
  "cloudProvider": "Cloud provider",
  "cloudConfiguration": "Cloud configuration",
  "microsoftAzure": "Microsoft Azure",
  "amazonAws": "Amazon Aws",
  "PageLockedBecauseDeploymentInProgress": "Fields are locked because a deployment is in progress",
  "confirmDeleteTenant": "Are you sure you want to delete this tenant with all its resources permanently?",
  "tenantDeleted": "Tenant has been deleted",
  "userDetails": "User Details",
  "applicationDetails": "Application Details",
  "jobTitle": "Job Title",
  "emailAddressNotAvailable": "The email address is not available",
  "addRole": "Add Role",
  "roleName": "Role name",
  "roleAdded": "Role has been added",
  "role": "Role",
  "implementation": "Implementation",
  "pipelinePlatform": "Pipeline Platform",
  "showTenantCosts": "Show Tenant Costs",
  "engagements": "Engagements",
  "newEngagementAdded": "New engagement added",
  "engagement": "Engagement",
  "engagementsDesc": "This is where you manage engagements",
  "newEngagement": "New engagement",
  "engagementDetails": "Engagement details",
  "engagementId": "Engagement ID",
  "engagementUserDeleted": "User has been deleted from the engagement",
  "engagementApplicationDeleted": "Application has been deleted from the engagement",
  "confirmRemoveUserFromEngagement": "Are you sure you want to delete this user from the engagement?",
  "confirmRemoveApplicationFromTenant": "Are you sure you want to delete this application from the tenant?",
  "storage": "Storage",
  "storageManager": "Storage Manager",
  "selectEngagement": "Select Engagement",
  "noEngagement": "No Engagement",
  "yourEngagements": "Your engagements",
  "yourEngagementsDesc": "Click on one of the engagements to direcly switch to that engagement",
  "engagementConfigurationDesc": "This is a resource overview for engagement",
  "engagementConfiguration": "Engagement Configuration",
  "StorageAccount": "Storage Account",
  "sqlDatabase": "SQL Database",
  "SqlDatabase": "SQL Database",
  "EventGridTopic": "Event Grid Topic",
  "DataFactory": "Data Factory",
  "SynapseDataWarehouse": "Synapse Data Warehouse",
  "SynapseAnalytics": "Synapse Analytics Workspace",
  "PowerBIEmbedded": "Power BI Embedded",
  "AnalysisServices": "Analysis Services",
  "FunctionAppPython": "Function App Python",
  "FunctionAppDotnet": "Function App .NET",
  "PowerBIWorkspace": "Power BI Workspace",
  "DataLakeStorage": "Data Lake Storage",
  "PurviewAccount": "Purview Account",
  "GitRepository": "Git Repository",
  "IntegrationRuntime": "Integration Runtime",
  "SqlServer": "SQL Server",
  "ManagedIdentity": "Managed Identity",
  "MachineLearningWorkspace": "Machine Learning Workspace",
  "dashboardPlatform": "Dashboard platform",
  "dashboardType": "Dashboard Type",
  "storageType": "Storage Type",
  "powerBiWorkspaceId": "Power BI Workspace ID",
  "repository": "Repository",
  "targetRepository": "Target Repository",
  "sourceRepository": "Source Repository",
  "targetBranch": "Target Branch",
  "rolesSyncedSuccessfully": "Roles synchronized successfully",
  "features": "Features",
  "targetRepoRequired": "Please choose a target repository",
  "getExampleParams": "Get Example",
  "nodeModel": "Node Model",
  "addConfiguration": "Add configuration",
  "connectors": "Connectors",
  "service": "Service",
  "publishPipelines": "Publish Pipelines",
  "repositories": "Repositories",
  "openAll": "Open All",
  "uploadingFile": "Uploading file",
  "gitImplementation": "Git Implementation",
  "KeyVault": "Key Vault",
  "ContainerRegistry": "Azure Container Registry",
  "AzureContainerRegistryTier": "Azure Container Registry Tier",
  "keyVaultName": "Key Vault Name",
  "linkedServices": "Linked Services",
  "addDataFactoryLinkedServices": "Add data-factory linked services",
  "deployDatabricksPrivateEndpoint": "Deploy databricks private endpoint",
  "azureDatabricksTier": "Databricks workspace Tier",
  "DatabricksWorkspace": "Databricks Workspace",
  "sftpSettings": "SFTP settings",
  "transformationEngineSettings": "Transformation Engine settings",
  "containerRegistryName": "Azure Container Registry Name",
  "transformationEngineBatchSize": "ETP Transformation engine - Processing batch size (records)",
  "transformationEngineTargetBranchName": "ETP Transformation engine - Git branch name",
  "scanWindowMinutes": "Scan window - minutes",
  "gain": "GAIN",
  "targetTableName": "Target SQL table - name",
  "sourceFileName": "Source file - name",
  "sourceFileWorksheetName": "Source file - worksheet name",
  "sourceFileHasHeaderRow": "Source file - has header row",
  "sourceFileRowsToSkip": "Source file - rows to skip",
  "rowExistenceCriterion": "Source file - row existence criterion",
  "rowExistenceCriterionTooltip": "'Strict' expects table to have rows on its own. 'Coupled' expects at least one row among a group of tables.",
  "sourceFileColsToSkip": "Source file - cols to skip",
  "sourceFileColsToTake": "Source file - cols to take",
  "takeAllColumns": "Take all columns",
  "systemStorage": "System Storage",
  "system": "System",
  "awsRegion": "AWS Region",
  "awsAccountId": "AWS Account ID",
  "etpTemplateType": "Select Template",
  "prefix": "Prefix",
  "confirmRepsocess": "Are you sure you want to reprocess this folder?",
  "reprocessing": "Reprocessing",
  "resourceType": "Resource type",
  "sqlReservedWord": "This is a SQL reserved word",
  "eInvoicing": "E-Invoicing",
  "provider": "Provider",
  "engagementInfoSaved": "Engagement information is saved",
  "gainOverview": "GAIN overview",
  "gainOverviewDesc": "This page lists some batch details available to ETP/CDX",
  "noBatchDetails": "There are no batch details to display (yet)",
  "receivedFromClient": "Received from client",
  "sentToGain": "Sent to GAIN",
  "records": "records",
  "resources": "Resources",
  "outputContainer": "Output container",
  "importTemplate": "Import Template",
  "batchStatus": "Batch status",
  "scanPath": "Scan path",
  "archivePath": "Archive path",
  "fileNameTooltipContent": "Enter expected file name here. Use single * to denote any number of wildcard characters",
  "required": "Required",
  "requiredTooltipContent": "Presence of all required files is checked before starting a batch execution",
  "archived": "Archived",
  "archivedTooltipContent": "File will be moved to 'archive' folder after each batch is complete and will need to be reuploaded again",
  "trigger": "Trigger",
  "triggerTooltipContent": "Uploading any such file will start a batch run",
  "addInputFile": "Add input file",
  "deleteInputFile": "Delete input file",
  "batchOverview": "Batch overview",
  "noHistoryToDisplay": "No history to display",
  "requirementsStatus": "Requirements status",
  "timestamp": "Timestamp",
  "fileConfigurations": "File Configurations",
  "SignedOff": "Signed off",
  "ReadyForTesting": "Ready for testing",
  "MappingInProgress": "Mapping in progress",
  "ReadyForImplementation": "Ready for implementation",
  "RequirementsStarted": "Requirements started",
  "NotStarted": "Not started",
  "updatedBy": "Updated by",
  "requirementsStatusSubmitted": "Requirements status submitted successfully",
  "updateRequirementsStatus": "Update requirements status",
  "currentVersion": "Current version",
  "configurationHistory": "Configuration history",
  "restore": "Restore",
  "tag": "Tag",
  "item": "Item",
  "table": "Table",
  "tagMaxLengthExceeded": "The max length of 100 characters is reached",
  "engagementActions": "Engagement actions",
  "tenantActions": "Tenant actions",
  "columnValidationRules": "Column validation rules",
  "mustHaveValue": "Must have value",
  "columnName": "Column name",
  "columnIndex": "Column index",
  "Any": "Any",
  "Guid": "Guid",
  "Number": "Number",
  "Integer": "Integer",
  "DateTime_String": "Datetime (string)",
  "DateTime_Excel": "Datetime (Excel)",
  "CountryName": "Country name",
  "CountryCode": "Country code",
  "RegionName": "Region name",
  "RegionCode": "Region code",
  "newRule": "New rule",
  "deleteValidationRule": "Delete validation rule",
  "importConfigColumnNameTooltipContent": "If source file has header - please specify the column name, as in original file. If file has no header - please specify the column index starting from 0.",
  "dateFormatTooltipContent": "Click here to see datetime formatting rules",
  "batches": "Batches",
  "promotions": "Promotions",
  "revert": "Revert",
  "revertPromotionModalTitle": "Revert promotion",
  "revertPromotionModalText": "You are about to revert the environment configuration. This includes file, folder and mapping configurations, as well as requirements files. This action is irreversible.",
  "comment": "Comment",
  "target": "Target",
  "revertingPromotion": "Reverting promotion...",
  "versionToRevert": "Version to revert",
  "versionToRestore": "Version to restore",
  "Draft": "Draft",
  "Reverted": "Reverted",
  "encryptionKey_FilePath": "Encryption key - File path",
  "encryptionKey_Passphrase": "Encryption key - Passphrase",
  "sftpPgpFilePath_TooltipContent": "This is the full path to the PGP private key file within ETP file system (not SFTP!). It consists of folderId (can be fetched from URL, preferably a System folder) and a file name, separated with slash. For example: system-pgp-keys/mykey.asc",
  "versioned": "Versioned",
  "versionedTooltipContent": "Content replaced by wildcard character * must be the same for all files where this checkbox is ticked",
  "mustContainAtMostOneAsterisk": "Value cannot contain multiple asterisks",
  "selectFiles": "Select files",
  "selectFilesToImport": "Select files to import",
  "selectFilesToReprocess": "Select files to reprocess",
  "addEntry": "Add entry",
  "deleteEntry": "Delete entry",
  "pathPrefix": "Path prefix",
  "secondaryBucketName": "Secondary bucket name",
  "access": "Access",
  "engagementAccessOverview": "Engagement access overview",
  "activateUser": "Activate user",
  "deactivateUser": "Deactivate user",
  "userActivated": "User activated",
  "userDeactivated": "User deactivated",
  "kind": "Kind",
  "filesGain": "Files (GAIN)",
  "tablesGain": "Tables (GAIN)",
  "archive": "Archive",
  "onFail": "On fail",
  "encryptionSettings": "Encryption settings",
  "fullPath": "Full path",
  "inheritedAssignments": "Inherited assignments",
  "directAssignments": "Direct assignments",
  "metadata": "Metadata",
  "uploadedFiles": "Uploaded files",
  "fileExtensionNotSupported": "This file extension is not supported",
  "fileUploadFailed": "File upload failed",
  "getAccess": "Get access",
  "stopCostSavingFailed": "Failed to disable cost saving functionality, please try again",
  "storageConfigurationSaveSuccess": "Successfully saved storage configurations",
  "storageConfigurationSaveFailure": "Failed to save storage configurations",
  "actualName": "Actual name",
  "max30characters": "max. 30 chars"
}
