import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = ["src"]
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]
const _hoisted_4 = { style: {"width":"500px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_base_toggle_button = _resolveComponent("base-toggle-button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_table, {
      data: $props.storageObjects,
      fit: "",
      class: "nowrap-tab"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "type",
          width: "55"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("img", {
              src: 
            [
              $setup.StorageObjectType.Folder,
              $setup.StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? require(`@/assets/images/icons/folder-16.svg`)
              : require(`@/assets/images/icons/file.svg`)
          ,
              class: "icon-size-7"
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "displayName",
          label: _ctx.$t('name')
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("div", {
              class: _normalizeClass(
            [
              $setup.StorageObjectType.Folder,
              $setup.StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? 'cursor-pointer'
              : ''
          ),
              onClick: ($event: any) => (
            [
              $setup.StorageObjectType.Folder,
              $setup.StorageObjectType.VirtualFolder
            ].includes(scope.row.type)
              ? _ctx.$emit('open-folder', scope.row)
              : null
          )
            }, [
              _createVNode(_component_el_tooltip, {
                placement: "top",
                content: $setup.getObjectDetails(scope.row)
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString($setup.showActualNames
                  ? scope.row.actualName
                  : scope.row.displayName ?? scope.row.actualName), 1)
                ]),
                _: 2
              }, 1032, ["content"])
            ], 10, _hoisted_2)
          ]),
          _: 1
        }, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          label: _ctx.$t('uploaded'),
          width: "120",
          sortable: "",
          "sort-by": $setup.sortByDate
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("h4", null, _toDisplayString($setup.formatDate(scope.row.createdAt)), 1),
            _createElementVNode("h4", null, _toDisplayString($setup.formatTime(scope.row.createdAt)), 1)
          ]),
          _: 1
        }, 8, ["label", "sort-by"]),
        _createVNode(_component_el_table_column, {
          width: "20",
          "class-name": "meta-column"
        }, {
          default: _withCtx((scope) => [
            ($setup.isCustomMetadata(scope.row.metadata))
              ? (_openBlock(), _createBlock(_component_el_tooltip, {
                  key: 0,
                  placement: "top"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("img", {
                      src: require(`@/assets/images/icons/new/information.svg`),
                      class: "action-icons icon-size-7"
                    }, null, 8, _hoisted_3)
                  ]),
                  content: _withCtx(() => [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_el_row, { class: "mb-2" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('metadata') + ':'), 1)
                        ]),
                        _: 1
                      }),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(
                  $setup.filterCustomMetadata(scope.row.metadata)
                ), ([key, value]) => {
                        return (_openBlock(), _createBlock(_component_el_row, { key: key }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(key), 1)
                              ]),
                              _: 2
                            }, 1024),
                            _createVNode(_component_el_col, { span: 12 }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(value), 1)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ])
                  ]),
                  _: 2
                }, 1024))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          width: "120",
          align: "right",
          label: _ctx.$t('actions')
        }, {
          default: _withCtx((scope) => [
            _renderSlot(_ctx.$slots, "default", {
              row: scope.row
            }, undefined, true)
          ]),
          _: 3
        }, 8, ["label"])
      ]),
      _: 3
    }, 8, ["data"]),
    _createVNode(_component_el_row, { class: "ml-auto" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_toggle_button, {
          modelValue: $setup.showActualNames,
          "onUpdate:modelValue": [
            _cache[0] || (_cache[0] = ($event: any) => (($setup.showActualNames) = $event)),
            $setup.toggleButton
          ],
          "active-text": _ctx.$t('actualName'),
          "inactive-text": _ctx.$t('displayName'),
          class: "ml-auto"
        }, null, 8, ["modelValue", "active-text", "inactive-text", "onUpdate:modelValue"])
      ]),
      _: 1
    })
  ], 64))
}