import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"
import _imports_0 from '@/assets/images/icons/share-26.svg'


const _hoisted_1 = ["src", "onClick"]
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_input_number = _resolveComponent("el-input-number")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_collapse_item = _resolveComponent("el-collapse-item")!
  const _component_el_collapse = _resolveComponent("el-collapse")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, {
    "no-header": "",
    class: "width-100"
  }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 5 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString($setup.form.targetTableName), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 17 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, {
                ref: "formRef",
                model: $setup.form,
                rules: $setup.formRules
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('targetTableName'),
                    prop: "targetTableName",
                    class: "mb-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.targetTableName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.targetTableName) = $event)),
                        size: "small",
                        onChange: $setup.emitUpdate
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    label: _ctx.$t('sourceFileName'),
                    prop: "sourceFileName",
                    class: "mb-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.sourceFileName,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.sourceFileName) = $event)),
                        size: "small",
                        filterable: "",
                        "allow-create": "",
                        onChange: $setup.emitUpdate
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.props.fileConfigurations, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              value: item.namePattern ?? ''
                            }, null, 8, ["value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  ($setup.form.sourceFileName?.toLocaleLowerCase().endsWith('xlsx'))
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        label: _ctx.$t('sourceFileWorksheetName'),
                        prop: "sourceFileWorksheetName",
                        class: "mb-0"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: $setup.form.sourceFileWorksheetName,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.sourceFileWorksheetName) = $event)),
                            size: "small",
                            onChange: $setup.emitUpdate
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_row, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('sourceFileRowsToSkip'),
                            prop: "sourceFileRowsToSkip",
                            class: "mb-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input_number, {
                                modelValue: $setup.form.sourceFileRowsToSkip,
                                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.sourceFileRowsToSkip) = $event)),
                                min: 0,
                                onChange: $setup.emitUpdate
                              }, null, 8, ["modelValue", "onChange"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('sourceFileHasHeaderRow'),
                            prop: "sourceFileHasHeaderRow",
                            class: "mb-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: $setup.form.sourceFileHasHeaderRow,
                                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.sourceFileHasHeaderRow) = $event)),
                                onChange: $setup.emitUpdate
                              }, null, 8, ["modelValue", "onChange"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_el_col, { span: 8 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_form_item, {
                            label: _ctx.$t('rowExistenceCriterion'),
                            prop: "rowExistenceCriterion",
                            class: "mb-0"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_tooltip, {
                                placement: "top",
                                content: _ctx.$t('rowExistenceCriterionTooltip')
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_select, {
                                    modelValue: $setup.form.rowExistenceCriterion,
                                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.form.rowExistenceCriterion) = $event)),
                                    size: "small",
                                    onChange: $setup.emitUpdate
                                  }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.RowExistenceCriterion, (index1, item1) => {
                                        return (_openBlock(), _createBlock(_component_el_option, {
                                          key: index1,
                                          label: item1,
                                          value: index1
                                        }, null, 8, ["label", "value"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  }, 8, ["modelValue", "onChange"])
                                ]),
                                _: 1
                              }, 8, ["content"])
                            ]),
                            _: 1
                          }, 8, ["label"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  ($setup.form.sourceFileName?.toLocaleLowerCase().endsWith('xlsx'))
                    ? (_openBlock(), _createBlock(_component_el_row, { key: 1 }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, { span: 8 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$t('sourceFileColsToSkip'),
                                prop: "sourceFileColsToSkip",
                                class: "mb-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_input_number, {
                                    modelValue: $setup.form.sourceFileColsToSkip,
                                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.form.sourceFileColsToSkip) = $event)),
                                    min: 0,
                                    onChange: $setup.emitUpdate
                                  }, null, 8, ["modelValue", "onChange"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, { span: 8 }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_form_item, {
                                label: _ctx.$t('takeAllColumns'),
                                prop: "takeAllColumns",
                                class: "mb-0"
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_checkbox, {
                                    modelValue: $setup.form.takeAllColumns,
                                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.form.takeAllColumns) = $event)),
                                    onChange: $setup.emitUpdate
                                  }, null, 8, ["modelValue", "onChange"])
                                ]),
                                _: 1
                              }, 8, ["label"])
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_el_col, { span: 8 }, {
                            default: _withCtx(() => [
                              (!$setup.form.takeAllColumns)
                                ? (_openBlock(), _createBlock(_component_el_form_item, {
                                    key: 0,
                                    label: _ctx.$t('sourceFileColsToTake'),
                                    prop: "sourceFileColsToTake",
                                    class: "mb-0"
                                  }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_input_number, {
                                        modelValue: $setup.form.sourceFileColsToTake,
                                        "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.form.sourceFileColsToTake) = $event)),
                                        min: 1,
                                        disabled: $setup.form.takeAllColumns,
                                        onChange: $setup.emitUpdate
                                      }, null, 8, ["modelValue", "disabled", "onChange"])
                                    ]),
                                    _: 1
                                  }, 8, ["label"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_collapse, { class: "width-100" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_collapse_item, { name: "1" }, {
                        title: _withCtx(() => [
                          _createElementVNode("h4", null, _toDisplayString(_ctx.$t('columnValidationRules') +
                      ' (' +
                      ($setup.form.columnConfigurations?.length ?? 0) +
                      ')'), 1)
                        ]),
                        default: _withCtx(() => [
                          ($setup.form.columnConfigurations?.length)
                            ? (_openBlock(), _createBlock(_component_el_row, { key: 0 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_col, { span: 8 }, {
                                    default: _withCtx(() => [
                                      _createVNode(_component_el_tooltip, {
                                        placement: "top",
                                        content: _ctx.$t('importConfigColumnNameTooltipContent')
                                      }, {
                                        default: _withCtx(() => [
                                          _createElementVNode("h4", null, _toDisplayString($setup.form.sourceFileHasHeaderRow
                            ? _ctx.$t('columnName')
                            : _ctx.$t('columnIndex')), 1)
                                        ]),
                                        _: 1
                                      }, 8, ["content"])
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, {
                                    span: 4,
                                    class: "d-flex justify-content-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('mustHaveValue')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, { span: 8 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('dataType')), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_el_col, { span: 3 }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('onFail')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }))
                            : _createCommentVNode("", true),
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.form.columnConfigurations, (item, index) => {
                            return (_openBlock(), _createBlock(_component_el_row, { key: index }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_form_item, { class: "width-100" }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_el_col, { span: 8 }, {
                                      default: _withCtx(() => [
                                        ($setup.form.sourceFileHasHeaderRow)
                                          ? (_openBlock(), _createBlock(_component_el_input, {
                                              key: 0,
                                              modelValue: item.columnName,
                                              "onUpdate:modelValue": ($event: any) => ((item.columnName) = $event),
                                              size: "small",
                                              onChange: $setup.emitUpdate
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                                          : (_openBlock(), _createBlock(_component_el_input_number, {
                                              key: 1,
                                              modelValue: item.columnIndex,
                                              "onUpdate:modelValue": ($event: any) => ((item.columnIndex) = $event),
                                              min: 0,
                                              size: "small",
                                              onChange: $setup.emitUpdate
                                            }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_el_col, {
                                      span: 4,
                                      class: "d-flex justify-content-center"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_checkbox, {
                                          modelValue: item.mustHaveValue,
                                          "onUpdate:modelValue": ($event: any) => ((item.mustHaveValue) = $event),
                                          onChange: $setup.emitUpdate
                                        }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_el_col, { span: 4 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_select, {
                                          modelValue: item.dataType,
                                          "onUpdate:modelValue": ($event: any) => ((item.dataType) = $event),
                                          size: "small",
                                          onChange: $setup.emitUpdate
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.DataType, (index1, item1) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: index1,
                                                disabled: 
                            index1 == $setup.DataType.DateTime_Excel &&
                            !$setup.form.sourceFileName
                              ?.toLocaleLowerCase()
                              .endsWith('xlsx')
                          ,
                                                label: _ctx.$t(item1),
                                                value: index1
                                              }, null, 8, ["disabled", "label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_el_col, { span: 4 }, {
                                      default: _withCtx(() => [
                                        (item.dataType == $setup.DataType.DateTime_String)
                                          ? (_openBlock(), _createBlock(_component_el_tooltip, {
                                              key: 0,
                                              "trigger-keys": []
                                            }, {
                                              content: _withCtx(() => [
                                                _createElementVNode("span", {
                                                  class: "hyperlink",
                                                  onClick: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => ($setup.openDateTimeDocumentation && $setup.openDateTimeDocumentation(...args)))
                                                }, [
                                                  _createTextVNode(_toDisplayString(_ctx.$t('dateFormatTooltipContent')) + " ", 1),
                                                  _cache[11] || (_cache[11] = _createElementVNode("img", {
                                                    class: "ml-1 icon-size-5",
                                                    src: _imports_0
                                                  }, null, -1))
                                                ])
                                              ]),
                                              default: _withCtx(() => [
                                                _createVNode(_component_el_input, {
                                                  modelValue: item.dataTypeSpecs,
                                                  "onUpdate:modelValue": ($event: any) => ((item.dataTypeSpecs) = $event),
                                                  placeholder: "dd-MMM-yyyy HH:mm",
                                                  size: "small",
                                                  onChange: $setup.emitUpdate
                                                }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"])
                                              ]),
                                              _: 2
                                            }, 1024))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_el_col, { span: 3 }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_select, {
                                          modelValue: item.failureBehaviour,
                                          "onUpdate:modelValue": ($event: any) => ((item.failureBehaviour) = $event),
                                          size: "small",
                                          onChange: $setup.emitUpdate
                                        }, {
                                          default: _withCtx(() => [
                                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.ValidationFailureBehaviour, (index1, item1) => {
                                              return (_openBlock(), _createBlock(_component_el_option, {
                                                key: index1,
                                                label: item1,
                                                value: index1
                                              }, null, 8, ["label", "value"]))
                                            }), 128))
                                          ]),
                                          _: 2
                                        }, 1032, ["modelValue", "onUpdate:modelValue", "onChange"])
                                      ]),
                                      _: 2
                                    }, 1024),
                                    _createVNode(_component_el_col, {
                                      span: 1,
                                      class: "d-flex"
                                    }, {
                                      default: _withCtx(() => [
                                        _createVNode(_component_el_tooltip, {
                                          placement: "top",
                                          content: _ctx.$t('deleteValidationRule'),
                                          class: "ml-auto"
                                        }, {
                                          default: _withCtx(() => [
                                            _createElementVNode("img", {
                                              src: require(`@/assets/images/icons/new/delete.svg`),
                                              height: "14",
                                              width: "14",
                                              class: "action-icon ml-auto",
                                              onClick: ($event: any) => ($setup.deleteColumnConfiguration(index))
                                            }, null, 8, _hoisted_1)
                                          ]),
                                          _: 2
                                        }, 1032, ["content"])
                                      ]),
                                      _: 2
                                    }, 1024)
                                  ]),
                                  _: 2
                                }, 1024)
                              ]),
                              _: 2
                            }, 1024))
                          }), 128)),
                          _createVNode(_component_el_row, { class: "mt-1" }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_col, { span: 9 }, {
                                default: _withCtx(() => [
                                  _createVNode(_component_el_button, {
                                    type: "info",
                                    size: "small",
                                    onClick: $setup.addColumnConfiguration
                                  }, {
                                    default: _withCtx(() => [
                                      _createElementVNode("h4", null, _toDisplayString(_ctx.$t('newRule')), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["onClick"])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["model", "rules"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 2,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              (!$props.disableDelete)
                ? (_openBlock(), _createElementBlock("img", {
                    key: 0,
                    src: require(`@/assets/images/icons/new/delete.svg`),
                    height: "12",
                    width: "12",
                    class: "action-icon ml-auto mt-1 mr-2",
                    onClick: _cache[10] || (_cache[10] = 
//@ts-ignore
(...args) => ($setup.deleteTableConfiguration && $setup.deleteTableConfiguration(...args)))
                  }, null, 8, _hoisted_2))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}