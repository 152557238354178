import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "loginpage",
  class: "login"
}
const _hoisted_2 = { class: "login-tile-wrapper" }
const _hoisted_3 = { class: "login-tile" }
const _hoisted_4 = { class: "login-header" }
const _hoisted_5 = { class: "login-content" }
const _hoisted_6 = { class: "login-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_login_circles = _resolveComponent("login-circles")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_login_circles),
    _createVNode(_component_el_row, {
      align: "middle",
      justify: "center",
      class: "login-wrapper"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h2", null, _toDisplayString(_ctx.$t('signIn')), 1)
            ]),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$t('loginMessage')), 1),
            _createElementVNode("div", _hoisted_6, [
              _createVNode(_component_el_button, {
                class: "login-button",
                type: "primary",
                onClick: $setup.login
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('login')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ])
          ])
        ])
      ]),
      _: 1
    })
  ]))
}