<template>
  <el-row class="d-flex">
    <h4 class="mt-auto">
      {{ tasks.length }} &#183;
      {{ $t('tasks') }}
    </h4>
  </el-row>
  <el-table :data="tasks">
    <el-table-column :label="$t('principalId')" :formatter="findPrincipalId" />
    <el-table-column :label="$t('status')">
      <template #default="scope">
        <el-tooltip>
          <template #default>
            {{ findStatus(scope.row) }}
          </template>
          <template #content>
            {{ scope.row.message }}
          </template>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column
      :label="$t('resourceName')"
      :formatter="findResourceName"
    />
    <el-table-column :label="$t('resourceType')" :formatter="findType" />
    <el-table-column :label="$t('lastUpdated')">
      <template #default="scope">
        <h4>{{ formatDate(scope.row.timestamp) }}</h4>
      </template>
    </el-table-column>
  </el-table>
</template>
<script lang="ts">
import { ElTable, ElTableColumn, ElRow, ElTooltip } from 'element-plus';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ResourceType } from '@etp/etp-infra-client';
import {
  PrincipalType,
  RBACAssignmentTask,
  TaskProcessorStatus
} from '@etp/etp-rbac-client';
import moment from 'moment';
import { ApplicationModel } from '@etp/etp-authorization-client/axios';
export default {
  name: 'EngagementRbacTasks',
  components: {
    ElTable,
    ElTableColumn,
    ElRow,
    ElTooltip
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const engagementId = ref('');

    const tasks: ComputedRef<Array<RBACAssignmentTask>> = computed(
      () => store.getters['engagements/getEngagementTasks']
    );

    const applications: ComputedRef<Array<ApplicationModel>> = computed(
      () => store.getters['applications/getApplications']
    );

    const updateLocalVars = async () => {
      engagementId.value = router.currentRoute.value.params
        .engagementId as string;
      await store.dispatch(
        'engagements/fetchEngagementTasks',
        engagementId.value
      );
      await store.dispatch('applications/fetchAllApplications');
    };

    const findPrincipalId = (entry: RBACAssignmentTask) => {
      if (entry.principalType == PrincipalType.User)
        return entry.principalId ?? '';

      return (
        applications.value?.find(
          element => element.aadObjectId == entry.principalId
        )?.name ??
        entry.principalId ??
        ''
      );
    };

    const findStatus = (entry: RBACAssignmentTask) => {
      if (entry.taskProcessorStatus != null)
        return Object.keys(TaskProcessorStatus)[entry.taskProcessorStatus];
      else return '';
    };

    const findResourceName = (entry: RBACAssignmentTask): string => {
      try {
        const taskSpecs = JSON.parse(entry.taskSpecs || '{}');
        return taskSpecs['Resource'].Name ?? '';
      } catch {
        // Do nothing
      }
      return '';
    };

    const findType = (entry: RBACAssignmentTask): string => {
      try {
        const taskSpecs = JSON.parse(entry.taskSpecs || '{}');
        return (
          Object.keys(ResourceType)[taskSpecs['Resource'].ResourceType] ?? ''
        );
      } catch {
        // Do nothing
      }
      return '';
    };

    const formatDate = (date: Date) => {
      return moment(date).format('DD-MM-YYYY, HH:mm:ss');
    };

    onMounted(async () => {
      await updateLocalVars();
    });

    return {
      findPrincipalId,
      findStatus,
      findResourceName,
      findType,
      formatDate,
      tasks,
      ResourceType
    };
  }
};
</script>
<style lang="scss" scoped></style>
