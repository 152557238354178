import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "ml-auto d-flex" }
const _hoisted_2 = { class: "actions" }
const _hoisted_3 = ["src", "onClick"]
const _hoisted_4 = ["src", "onClick"]
const _hoisted_5 = ["src", "onClick"]
const _hoisted_6 = ["src", "onClick"]
const _hoisted_7 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_path_tree = _resolveComponent("path-tree")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_base_upload_button = _resolveComponent("base-upload-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_storage_objects_table = _resolveComponent("storage-objects-table")!
  const _component_create_folder_modal = _resolveComponent("create-folder-modal")!
  const _component_folder_users_modal = _resolveComponent("folder-users-modal")!
  const _component_folder_applications_modal = _resolveComponent("folder-applications-modal")!
  const _component_base_modal = _resolveComponent("base-modal")!
  const _component_metadata_modal = _resolveComponent("metadata-modal")!
  const _component_import_file_selection_modal = _resolveComponent("import-file-selection-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "d-flex" }, {
      default: _withCtx(() => [
        ($setup.currentPath.length)
          ? (_openBlock(), _createBlock(_component_path_tree, {
              key: 0,
              path: $setup.currentPath,
              "root-storage-name": 
        $setup.currentStorageConfiguration.displayName ??
        $setup.currentStorageConfiguration.storagePurpose
      ,
              class: "ml-1-5",
              onGoToPath: $setup.goToPath
            }, null, 8, ["path", "root-storage-name", "onGoToPath"]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_1, [
          (
          $setup.userCanWriteEngagementStorageFolders && !$setup.isSignedOffGainEngagement
        )
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 0,
                type: "primary",
                class: "mr-2",
                size: "small",
                onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showCreateFolderModal = true))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString('+ ' + _ctx.$t('newFolder')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          (
          $setup.FileUploadSupported(
            $setup.GetPathPrefix($setup.currentStorageConfiguration),
            $setup.currentPath
          ) &&
          $setup.userCanWriteEngagementStorageFolders &&
          !$setup.isSignedOffGainEngagement
        )
            ? (_openBlock(), _createBlock(_component_base_upload_button, {
                key: 1,
                class: "mr-2",
                onUpload: $setup.uploadFile
              }, null, 8, ["onUpload"]))
            : _createCommentVNode("", true),
          (
          $setup.currentStorageConfiguration.storagePurpose?.toLocaleLowerCase() ==
            'import' || ($setup.currentPath[0] ?? '').startsWith('import')
        )
            ? (_openBlock(), _createBlock(_component_el_button, {
                key: 2,
                disabled: 
          !$setup.currentFolderObjects.filter(el => el.type === $setup.StorageObjectType.File)
            .length
        ,
                size: "small",
                type: "primary",
                onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showImportFileSelectionModal = true))
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h4", null, _toDisplayString($setup.isGainEngagement && $setup.currentPath.length
              ? _ctx.$t('reprocess')
              : _ctx.$t('import')), 1)
                ]),
                _: 1
              }, 8, ["disabled"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "mt-2" }, {
      default: _withCtx(() => [
        ($setup.loadingFolderObjects)
          ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
              key: 0,
              columns: 2
            }))
          : (_openBlock(), _createBlock(_component_storage_objects_table, {
              key: 1,
              "storage-objects": $setup.currentFolderObjects,
              onOpenFolder: _cache[2] || (_cache[2] = (object) => $setup.openFolder(object))
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("div", _hoisted_2, [
                  (row.type === $setup.StorageObjectType.File)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 0,
                        content: _ctx.$t('download'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/download.svg`),
                            class: "action-icon mr-1 icon-size-7",
                            onClick: ($event: any) => ($setup.downloadFile(row))
                          }, null, 8, _hoisted_3)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true),
                  ($setup.userCanWriteEngagementStorageFolders)
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 1,
                        content: _ctx.$t('metadata'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/settings.svg`),
                            class: "action-icon mr-1 icon-size-7",
                            onClick: ($event: any) => ($setup.openMetadataModal(row))
                          }, null, 8, _hoisted_4)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true),
                  (
              row.type === $setup.StorageObjectType.File && !$setup.isSignedOffGainEngagement
            )
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 2,
                        content: _ctx.$t('delete'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/bin.svg`),
                            class: "action-icon mr-1 icon-size-7",
                            onClick: ($event: any) => ($setup.deleteFile(row))
                          }, null, 8, _hoisted_5)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true),
                  (
              [
                $setup.StorageObjectType.Folder,
                $setup.StorageObjectType.VirtualFolder
              ].includes(row.type) &&
              !$setup.isGainEngagement &&
              $setup.userCanWriteEngagementAuthorization
            )
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 3,
                        content: _ctx.$t('applications'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/healthcheck.svg`),
                            class: "action-icon mr-1 icon-size-7",
                            onClick: ($event: any) => ($setup.openApplicationPermissionsModal(row))
                          }, null, 8, _hoisted_6)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true),
                  (
              [
                $setup.StorageObjectType.Folder,
                $setup.StorageObjectType.VirtualFolder
              ].includes(row.type) &&
              !$setup.isGainEngagement &&
              $setup.userCanWriteEngagementAuthorization
            )
                    ? (_openBlock(), _createBlock(_component_el_tooltip, {
                        key: 4,
                        content: _ctx.$t('users'),
                        placement: "top"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/users.svg`),
                            class: "action-icon mr-1 icon-size-7",
                            onClick: ($event: any) => ($setup.openUserPermissionsModal(row))
                          }, null, 8, _hoisted_7)
                        ]),
                        _: 2
                      }, 1032, ["content"]))
                    : _createCommentVNode("", true)
                ])
              ]),
              _: 1
            }, 8, ["storage-objects"]))
      ]),
      _: 1
    }),
    _createVNode(_component_create_folder_modal, {
      "dialog-visible": $setup.showCreateFolderModal,
      "existing-system-names": 
      $setup.currentFolderObjects
        .filter(el => el.type === $setup.StorageObjectType.Folder)
        .map(el => el.actualName)
    ,
      prefix: 
      $setup.currentPath.length
        ? ''
        : $setup.GetPathPrefix($setup.currentStorageConfiguration).split('/').pop() ?? ''
    ,
      onConfirm: $setup.createFolder,
      onClose: _cache[3] || (_cache[3] = ($event: any) => ($setup.showCreateFolderModal = false))
    }, null, 8, ["dialog-visible", "existing-system-names", "prefix", "onConfirm"]),
    _createVNode(_component_folder_users_modal, {
      "dialog-visible": $setup.showFolderUsersModal,
      "engagement-users": $setup.engagementUsers,
      "target-scope": $setup.targetRoleAssignmentScope,
      onUpdateUsers: $setup.updateFolderUsers,
      onClose: _cache[4] || (_cache[4] = ($event: any) => ($setup.showFolderUsersModal = false))
    }, null, 8, ["dialog-visible", "engagement-users", "target-scope", "onUpdateUsers"]),
    _createVNode(_component_folder_applications_modal, {
      "dialog-visible": $setup.showFolderApplicationsModal,
      "engagement-applications": $setup.engagementApplications,
      "target-scope": $setup.targetRoleAssignmentScope,
      onUpdateApplications: $setup.updateFolderApplications,
      onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showFolderApplicationsModal = false))
    }, null, 8, ["dialog-visible", "engagement-applications", "target-scope", "onUpdateApplications"]),
    _createVNode(_component_base_modal, {
      "modal-title": _ctx.$t('noAccessToResource'),
      "confirm-text": 
      $setup.userCanWriteEngagementAuthorization ? _ctx.$t('getAccess') : _ctx.$t('close')
    ,
      "dialog-visible": !$setup.userHasFolderAccess,
      onConfirm: _cache[6] || (_cache[6] = ($event: any) => (
      $setup.userCanWriteEngagementAuthorization
        ? $setup.grantSelfCurrentFolderAccess()
        : $setup.router.go(-1)
    )),
      onClose: _cache[7] || (_cache[7] = ($event: any) => ($setup.router.go(-1)))
    }, {
      content: _withCtx(() => [
        _createElementVNode("h4", null, _toDisplayString($setup.userCanWriteEngagementAuthorization
            ? _ctx.$t('noFolderAccessManager')
            : _ctx.$t('noFolderAccessUser')), 1)
      ]),
      _: 1
    }, 8, ["modal-title", "confirm-text", "dialog-visible"]),
    _createVNode(_component_metadata_modal, {
      "dialog-visible": $setup.showMetadataModal,
      metadata: $setup.targetMetadataObject.metadata,
      onConfirm: $setup.updateMetadata,
      onClose: _cache[8] || (_cache[8] = ($event: any) => ($setup.showMetadataModal = false))
    }, null, 8, ["dialog-visible", "metadata", "onConfirm"]),
    _createVNode(_component_import_file_selection_modal, {
      "dialog-visible": $setup.showImportFileSelectionModal,
      "feedback-text": 
      $setup.isGainEngagement ? _ctx.$t('reprocessing') : _ctx.$t('importingFilesToDb')
    ,
      files: 
      $setup.currentFolderObjects.filter(el => el.type === $setup.StorageObjectType.File)
    ,
      "modal-title": 
      $setup.isGainEngagement
        ? _ctx.$t('selectFilesToReprocess')
        : _ctx.$t('selectFilesToImport')
    ,
      "show-archive": 
      $setup.isGainEngagement &&
      $setup.gainImportConfiguration.fileLogicType == $setup.FileLogicType.SqlLogic
    ,
      onConfirm: $setup.importFileSelection,
      onClose: _cache[9] || (_cache[9] = ($event: any) => ($setup.showImportFileSelectionModal = false))
    }, null, 8, ["dialog-visible", "feedback-text", "files", "modal-title", "show-archive", "onConfirm"])
  ], 64))
}