<template>
  <el-row class="d-flex">
    <path-tree
      v-if="currentPath.length"
      :path="currentPath"
      :root-storage-name="
        currentStorageConfiguration.displayName ??
        currentStorageConfiguration.storagePurpose
      "
      class="ml-1-5"
      @go-to-path="goToPath"
    />
    <div class="ml-auto d-flex">
      <el-button
        v-if="
          userCanWriteEngagementStorageFolders && !isSignedOffGainEngagement
        "
        type="primary"
        class="mr-2"
        size="small"
        @click="showCreateFolderModal = true"
      >
        <h4>{{ '+ ' + $t('newFolder') }}</h4>
      </el-button>
      <base-upload-button
        v-if="
          FileUploadSupported(
            GetPathPrefix(currentStorageConfiguration),
            currentPath
          ) &&
          userCanWriteEngagementStorageFolders &&
          !isSignedOffGainEngagement
        "
        class="mr-2"
        @upload="uploadFile"
      />
      <el-button
        v-if="
          currentStorageConfiguration.storagePurpose?.toLocaleLowerCase() ==
            'import' || (currentPath[0] ?? '').startsWith('import')
        "
        :disabled="
          !currentFolderObjects.filter(el => el.type === StorageObjectType.File)
            .length
        "
        size="small"
        type="primary"
        @click="showImportFileSelectionModal = true"
      >
        <h4>
          {{
            isGainEngagement && currentPath.length
              ? $t('reprocess')
              : $t('import')
          }}
        </h4>
      </el-button>
    </div>
  </el-row>
  <el-row class="mt-2">
    <table-placeholder-loading v-if="loadingFolderObjects" :columns="2" />
    <storage-objects-table
      v-else
      :storage-objects="currentFolderObjects"
      @open-folder="(object: StorageObject) => openFolder(object)"
    >
      <template #default="{ row }">
        <div class="actions">
          <el-tooltip
            v-if="row.type === StorageObjectType.File"
            :content="$t('download')"
            placement="top"
          >
            <img
              :src="require(`@/assets/images/icons/new/download.svg`)"
              class="action-icon mr-1 icon-size-7"
              @click="downloadFile(row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="userCanWriteEngagementStorageFolders"
            :content="$t('metadata')"
            placement="top"
          >
            <img
              :src="require(`@/assets/images/icons/new/settings.svg`)"
              class="action-icon mr-1 icon-size-7"
              @click="openMetadataModal(row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="
              row.type === StorageObjectType.File && !isSignedOffGainEngagement
            "
            :content="$t('delete')"
            placement="top"
          >
            <img
              :src="require(`@/assets/images/icons/bin.svg`)"
              class="action-icon mr-1 icon-size-7"
              @click="deleteFile(row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="
              [
                StorageObjectType.Folder,
                StorageObjectType.VirtualFolder
              ].includes(row.type) &&
              !isGainEngagement &&
              userCanWriteEngagementAuthorization
            "
            :content="$t('applications')"
            placement="top"
          >
            <img
              :src="require(`@/assets/images/icons/new/healthcheck.svg`)"
              class="action-icon mr-1 icon-size-7"
              @click="openApplicationPermissionsModal(row)"
            />
          </el-tooltip>
          <el-tooltip
            v-if="
              [
                StorageObjectType.Folder,
                StorageObjectType.VirtualFolder
              ].includes(row.type) &&
              !isGainEngagement &&
              userCanWriteEngagementAuthorization
            "
            :content="$t('users')"
            placement="top"
          >
            <img
              :src="require(`@/assets/images/icons/new/users.svg`)"
              class="action-icon mr-1 icon-size-7"
              @click="openUserPermissionsModal(row)"
            />
          </el-tooltip>
        </div>
      </template>
    </storage-objects-table>
  </el-row>
  <create-folder-modal
    :dialog-visible="showCreateFolderModal"
    :existing-system-names="
      currentFolderObjects
        .filter(el => el.type === StorageObjectType.Folder)
        .map(el => el.actualName)
    "
    :prefix="
      currentPath.length
        ? ''
        : GetPathPrefix(currentStorageConfiguration).split('/').pop() ?? ''
    "
    @confirm="createFolder"
    @close="showCreateFolderModal = false"
  />
  <folder-users-modal
    :dialog-visible="showFolderUsersModal"
    :engagement-users="engagementUsers"
    :target-scope="targetRoleAssignmentScope"
    @update-users="updateFolderUsers"
    @close="showFolderUsersModal = false"
  />
  <folder-applications-modal
    :dialog-visible="showFolderApplicationsModal"
    :engagement-applications="engagementApplications"
    :target-scope="targetRoleAssignmentScope"
    @update-applications="updateFolderApplications"
    @close="showFolderApplicationsModal = false"
  />
  <base-modal
    :modal-title="$t('noAccessToResource')"
    :confirm-text="
      userCanWriteEngagementAuthorization ? $t('getAccess') : $t('close')
    "
    :dialog-visible="!userHasFolderAccess"
    @confirm="
      userCanWriteEngagementAuthorization
        ? grantSelfCurrentFolderAccess()
        : router.go(-1)
    "
    @close="router.go(-1)"
  >
    <template #content>
      <h4>
        {{
          userCanWriteEngagementAuthorization
            ? $t('noFolderAccessManager')
            : $t('noFolderAccessUser')
        }}
      </h4>
    </template>
  </base-modal>
  <metadata-modal
    :dialog-visible="showMetadataModal"
    :metadata="targetMetadataObject.metadata"
    @confirm="updateMetadata"
    @close="showMetadataModal = false"
  />
  <import-file-selection-modal
    :dialog-visible="showImportFileSelectionModal"
    :feedback-text="
      isGainEngagement ? $t('reprocessing') : $t('importingFilesToDb')
    "
    :files="
      currentFolderObjects.filter(el => el.type === StorageObjectType.File)
    "
    :modal-title="
      isGainEngagement
        ? $t('selectFilesToReprocess')
        : $t('selectFilesToImport')
    "
    :show-archive="
      isGainEngagement &&
      gainImportConfiguration.fileLogicType == FileLogicType.SqlLogic
    "
    @confirm="importFileSelection"
    @close="showImportFileSelectionModal = false"
  />
</template>

<script lang="ts">
import { ElButton, ElMessage, ElRow, ElTooltip } from 'element-plus';
import { Modification, ModificationAction } from '@/models/modification';
import { computed, ComputedRef, onMounted, ref } from 'vue';
import { FileUploadSupported, GetPathPrefix } from '@/utils/pathHelper';
import { GetScopeForStoragePath } from '@/utils/scopeHelper';
import { gainImportFlowPipelineName } from '@/utils/variables';
import { useRouter } from 'vue-router';
import { EngagementModel } from '@etp/etp-engagements-client/axios';
import {
  FileLogicType,
  GainImportConfiguration,
  RequirementsStatus
} from '@etp/etp-gain-bff-client/axios';
import {
  CreateOrUpdateFolderDto,
  EngagementStorageConfiguration,
  StorageObject,
  StorageObjectType,
  UpdateStorageObjectDto
} from '@etp/etp-storage-client/axios';
import { useStore } from 'vuex';
import i18n from '@/i18n';
import BaseModal from '@/components/modals/BaseModal.vue';
import BaseUploadButton from '@/components/buttons/BaseUploadButton.vue';
import CreateFolderModal, {
  CreateFolderModalOutput
} from '@/views/home/storage/components/CreateFolderModal.vue';
import FolderApplicationsModal from '@/components/modals/FolderApplicationsModal.vue';
import FolderUsersModal from '@/components/modals/FolderUsersModal.vue';
import ImportFileSelectionModal from '@/views/home/data-journey/import/components/ImportFileSelectionModal.vue';
import MetadataModal from '@/components/modals/MetadataModal.vue';
import PathTree from '@/views/home/storage/components/PathTree.vue';
import StorageObjectsTable from '@/components/tables/StorageObjectsTable.vue';
import TablePlaceholderLoading from '@/components/tables/TablePlaceholderLoading.vue';
import {
  ApplicationDetailsModel,
  UserDetailsModel
} from '@etp/etp-authorization-client/axios';
import { ImportFileSelection } from '@/views/home/data-journey/import/models/ImportFileSelection';
import { StartPipelineRunDto } from '@etp/etp-pipelines-client/axios';
import { UploadFileResult } from '@/models/uploadFileResult';
import { HttpStatusCode } from 'axios';

export default {
  name: 'StorageManager',
  components: {
    BaseModal,
    BaseUploadButton,
    CreateFolderModal,
    ElButton,
    ElRow,
    ElTooltip,
    FolderApplicationsModal,
    FolderUsersModal,
    ImportFileSelectionModal,
    MetadataModal,
    PathTree,
    StorageObjectsTable,
    TablePlaceholderLoading
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const currentPath = ref(new Array<string>());
    const loadingFolderObjects = ref(false);
    const showCreateFolderModal = ref(false);
    const showFolderUsersModal = ref(false);
    const showFolderApplicationsModal = ref(false);
    const showImportFileSelectionModal = ref(false);
    const showMetadataModal = ref(false);
    const storagePurpose = ref('');
    const targetRoleAssignmentFolder = ref({} as StorageObject);
    const targetRoleAssignmentScope = ref('');
    const targetMetadataObject = ref({} as StorageObject);

    const currentEngagement: ComputedRef<EngagementModel> = computed(
      () => store.getters['home/getCurrentEngagement']
    );
    const currentFolder: ComputedRef<StorageObject> = computed(
      () => store.getters['storage/getCurrentFolder']
    );
    const currentFolderObjects: ComputedRef<Array<StorageObject>> = computed(
      () => store.getters['storage/getCurrentFolderObjects']
    );
    const currentStorageConfiguration: ComputedRef<EngagementStorageConfiguration> =
      computed(() => store.getters['storage/getCurrentConfiguration']);
    const engagementApplications: ComputedRef<Array<ApplicationDetailsModel>> =
      computed(() => store.getters['engagements/getEngagementApplications']);
    const engagementUsers: ComputedRef<Array<UserDetailsModel>> = computed(
      () => store.getters['engagements/getEngagementUsers']
    );
    const gainImportConfiguration: ComputedRef<GainImportConfiguration> =
      computed(() => store.getters['import/getGainImportConfiguration']);
    const isGainEngagement: ComputedRef<boolean> = computed(
      () => store.getters['home/isGainEngagement']
    );
    const isSignedOffGainEngagement: ComputedRef<boolean> = computed(
      () =>
        isGainEngagement.value &&
        store.getters['gain/getCurrentRequirementsStatus']
          ?.requirementsStatus === RequirementsStatus.SignedOff
    );
    const userCanWriteEngagementAuthorization: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementAuthorization']
    );
    const userCanWriteEngagementStorageFolders: ComputedRef<boolean> = computed(
      () => store.getters['userPermissions/canWriteEngagementStorageFolders']
    );
    const userHasFolderAccess: ComputedRef<boolean> = computed(
      () => store.getters['storage/getUserHasFolderAccess']
    );

    const createFolder = async (model: CreateFolderModalOutput) => {
      loadingFolderObjects.value = true;
      showCreateFolderModal.value = false;
      await store.dispatch('storage/createOrUpdateFolder', {
        path: [...currentPath.value, model.systemName],
        model: {
          displayName: model.displayName,
          metadata: {}
        } as CreateOrUpdateFolderDto
      });
      await updateLocalVars();
      loadingFolderObjects.value = false;
    };

    const deleteFile = async (file: StorageObject) => {
      loadingFolderObjects.value = true;
      await store.dispatch('storage/deleteStorageObject', {
        path: file.fullPath.split('/')
      });
      await updateLocalVars();
      loadingFolderObjects.value = false;
    };

    const downloadFile = async (file: StorageObject) => {
      var targetPath = file.fullPath?.split('/');
      if (!targetPath) {
        ElMessage({
          showClose: true,
          message: i18n.global.t('invalidPath')
        });
        return;
      }
      await store.dispatch('storage/downloadFile', {
        path: targetPath
      });
    };

    const goToPath = (targetPath: Array<string>) => {
      router.push({
        name: router.currentRoute.value.name ?? 'Overview',
        params: { path: targetPath }
      });
    };

    const grantSelfCurrentFolderAccess = async () => {
      const userEmail = store.getters['base/getCurrentUser'].email;
      if (!userEmail) {
        router.go(-1);
        return;
      }
      await store.dispatch('storage/addUserToStoragePath', {
        path: [...currentPath.value, ''],
        email: userEmail
      });
      await updateLocalVars();
    };

    const importFileSelection = async (selection: ImportFileSelection) => {
      if (isGainEngagement.value) {
        await store.dispatch('model/startPipelineRun', {
          pipelineName: gainImportFlowPipelineName,
          parameters: {
            Request: {
              EngagementId: store.getters['home/getCurrentEngagement'].id,
              FileNames: selection.fileNamesToProcess,
              FilePaths: selection.fileNamesToProcess.map(
                fileName => `${currentPath.value.join('/')}/${fileName}`
              ),
              FilePathsToArchive: selection.fileNamesToArchive.map(
                fileName => `${currentPath.value.join('/')}/${fileName}`
              ),
              TriggerFileNames: []
            }
          }
        } as StartPipelineRunDto);
      } else {
        await store.dispatch('import/importFiles', {
          storagePurpose: currentStorageConfiguration.value.storagePurpose,
          path: currentPath.value,
          fileNames: selection.fileNamesToProcess
        });
      }
      showImportFileSelectionModal.value = false;
    };

    const openFolder = async (folder: StorageObject) => {
      var targetPath = folder.fullPath?.split('/');
      if (!targetPath) {
        ElMessage({
          showClose: true,
          message: i18n.global.t('invalidPath')
        });
        return;
      }
      goToPath(targetPath);
    };

    const openMetadataModal = async (targetObject: StorageObject) => {
      targetMetadataObject.value = targetObject;
      showMetadataModal.value = true;
    };

    const openApplicationPermissionsModal = async (
      targetObject: StorageObject
    ) => {
      const engagementId = currentEngagement.value.id;
      if (!engagementId || !targetObject.fullPath) return;
      await store.dispatch(
        'engagements/fetchEngagementApplications',
        engagementId
      );
      targetRoleAssignmentFolder.value = targetObject;
      targetRoleAssignmentScope.value = GetScopeForStoragePath(
        engagementId,
        currentStorageConfiguration.value.storagePurpose,
        targetObject.fullPath?.split('/')
      );
      showFolderApplicationsModal.value = true;
    };

    const openUserPermissionsModal = async (targetObject: StorageObject) => {
      const engagementId = currentEngagement.value.id;
      if (!engagementId || !targetObject.fullPath) return;
      await store.dispatch('engagements/fetchEngagementUsers', engagementId);
      targetRoleAssignmentFolder.value = targetObject;
      targetRoleAssignmentScope.value = GetScopeForStoragePath(
        engagementId,
        currentStorageConfiguration.value.storagePurpose,
        targetObject.fullPath?.split('/')
      );
      showFolderUsersModal.value = true;
    };

    const updateFolderApplications = async (
      modifications: Array<Modification<ApplicationDetailsModel>>
    ) => {
      ElMessage({
        message: 'Saving...'
      });
      for (const modification of modifications) {
        if (modification.action == ModificationAction.Add) {
          await store.dispatch('storage/addApplicationToStoragePath', {
            path: targetRoleAssignmentFolder.value.fullPath?.split('/'),
            aadObjectId: modification.item.aadObjectId
          });
        }
        if (modification.action == ModificationAction.Remove) {
          await store.dispatch('storage/removeApplicationFromStoragePath', {
            path: targetRoleAssignmentFolder.value.fullPath?.split('/'),
            aadObjectId: modification.item.aadObjectId
          });
        }
      }
      showFolderApplicationsModal.value = false;
    };

    const updateFolderUsers = async (
      modifications: Array<Modification<UserDetailsModel>>
    ) => {
      ElMessage({
        message: 'Saving...'
      });
      for (const modification of modifications) {
        if (modification.action == ModificationAction.Add) {
          await store.dispatch('storage/addUserToStoragePath', {
            path: targetRoleAssignmentFolder.value.fullPath?.split('/'),
            email: modification.item.email
          });
        }
        if (modification.action == ModificationAction.Remove) {
          await store.dispatch('storage/removeUserFromStoragePath', {
            path: targetRoleAssignmentFolder.value.fullPath?.split('/'),
            email: modification.item.email
          });
        }
      }
      showFolderUsersModal.value = false;
    };

    const updateMetadata = async (metadata: Record<string, string>) => {
      showMetadataModal.value = false;
      loadingFolderObjects.value = true;
      await store.dispatch('storage/updateObject', {
        path: targetMetadataObject.value.fullPath.split('/'),
        model: { metadata } as UpdateStorageObjectDto
      });
      targetMetadataObject.value = {} as StorageObject;
      await updateLocalVars();
      loadingFolderObjects.value = false;
    };

    const uploadFile = async (file: File) => {
      var result = (await store.dispatch('storage/uploadFile', {
        path: currentPath.value,
        file: file
      })) as UploadFileResult;
      if (result.httpStatusCode == HttpStatusCode.Ok) {
        ElMessage({
          message:
            i18n.global.t('uploadedFiles') + ': ' + result.resultingFileNames
        });
      } else if (result.httpStatusCode == HttpStatusCode.UnsupportedMediaType) {
        ElMessage({
          message: i18n.global.t('fileExtensionNotSupported')
        });
      } else {
        ElMessage({
          message: i18n.global.t('fileUploadFailed')
        });
      }
      await updateLocalVars();
    };

    onMounted(async () => {
      loadingFolderObjects.value = true;
      await updateLocalVars();
      loadingFolderObjects.value = false;
    });

    const updateLocalVars = async () => {
      storagePurpose.value = router.currentRoute.value.params
        .storagePurpose as string;
      if (!storagePurpose.value) return;
      await store.dispatch(
        'storage/fetchCurrentConfiguration',
        storagePurpose.value
      );
      const routePathParam = router.currentRoute.value.params.path;
      currentPath.value = routePathParam
        ? (routePathParam as Array<string>).filter(el => el)
        : [];
      await store.dispatch('storage/fetchCurrentFolder', {
        path: currentPath.value
      });
      store.commit(
        'home/SET_PAGE_HEADER',
        currentFolder.value.displayName ??
          currentStorageConfiguration.value.displayName ??
          i18n.global.t('storageManager')
      );
      if (!userHasFolderAccess.value) {
        store.commit(
          'storage/SET_CURRENT_FOLDER_OBJECTS',
          [] as Array<StorageObject>
        );
        return;
      }
      await store.dispatch('storage/fetchCurrentFolderObjects', {
        path: currentPath.value
      });
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementAuthorization'
      );
      await store.dispatch(
        'userPermissions/checkIfCanWriteEngagementStorageFolders'
      );
      if (isGainEngagement.value) {
        await store.dispatch('gain/fetchCurrentRequirementsStatus');
        await store.dispatch('import/fetchGainImportConfiguration');
      }
    };

    return {
      createFolder,
      currentFolder,
      currentFolderObjects,
      currentPath,
      currentStorageConfiguration,
      deleteFile,
      downloadFile,
      engagementApplications,
      engagementUsers,
      FileLogicType,
      FileUploadSupported,
      gainImportConfiguration,
      GetPathPrefix,
      goToPath,
      grantSelfCurrentFolderAccess,
      importFileSelection,
      isGainEngagement,
      isSignedOffGainEngagement,
      loadingFolderObjects,
      openApplicationPermissionsModal,
      openFolder,
      openMetadataModal,
      openUserPermissionsModal,
      router,
      showCreateFolderModal,
      showFolderApplicationsModal,
      showFolderUsersModal,
      showImportFileSelectionModal,
      showMetadataModal,
      StorageObjectType,
      targetMetadataObject,
      targetRoleAssignmentScope,
      updateFolderApplications,
      updateFolderUsers,
      updateMetadata,
      uploadFile,
      userCanWriteEngagementAuthorization,
      userCanWriteEngagementStorageFolders,
      userHasFolderAccess
    };
  }
};
</script>
