import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_code_editor = _resolveComponent("code-editor")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _component_table_placeholder_loading = _resolveComponent("table-placeholder-loading")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { class: "width-100" }, {
      default: _withCtx(() => [
        _createVNode(_component_base_card, {
          "no-header": "",
          class: "width-100"
        }, {
          body: _withCtx(() => [
            _createVNode(_component_el_row, { class: "d-flex" }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: $setup.localFileLogicType,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.localFileLogicType) = $event)),
                  size: "small",
                  class: "ml-auto width-20",
                  onChange: $setup.handleLogicTypeChange
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.FileLogicType, (index, item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: index,
                        label: item,
                        value: index
                      }, null, 8, ["label", "value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue", "onChange"]),
                _createVNode(_component_el_button, {
                  class: "ml-2",
                  type: "primary",
                  disabled: $setup.props.disabled,
                  onClick: $setup.save
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, { class: "mt-3" }, {
              default: _withCtx(() => [
                _createVNode(_component_code_editor, {
                  modelValue: $setup.localSqlQuery,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.localSqlQuery) = $event)),
                  font_size: "13px",
                  languages: [['SQL', 'SQL']],
                  theme: "light",
                  min_height: "300px",
                  min_width: "100%"
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_el_row, { class: "width-100 mt-2" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 9 }, {
          default: _withCtx(() => [
            _createVNode(_component_base_card, {
              "no-header": "",
              class: "mr-1"
            }, {
              body: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 16,
                      class: "d-flex"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'string'
                        }, {
                          default: _withCtx(() => _cache[2] || (_cache[2] = [
                            _createElementVNode("h4", null, _toDisplayString('Name'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 6,
                      class: "d-flex justify-content-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'boolean'
                        }, {
                          default: _withCtx(() => _cache[3] || (_cache[3] = [
                            _createElementVNode("h4", null, _toDisplayString('IsNewDrop'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localStagingFiles, (item, index) => {
                  return (_openBlock(), _createBlock(_component_el_row, {
                    key: index,
                    class: "mt-1"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_col, {
                        span: 16,
                        class: "d-flex justify-content-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: item.name,
                            "onUpdate:modelValue": ($event: any) => ((item.name) = $event),
                            size: "small"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, {
                        span: 6,
                        class: "d-flex justify-content-center align-items-center"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_checkbox, {
                            modelValue: item.isNewDrop,
                            "onUpdate:modelValue": ($event: any) => ((item.isNewDrop) = $event),
                            size: "small"
                          }, null, 8, ["modelValue", "onUpdate:modelValue"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_component_el_col, {
                        span: 2,
                        class: "d-flex justify-content-center align-items-center"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("img", {
                            src: require(`@/assets/images/icons/new/delete.svg`),
                            height: "14",
                            width: "14",
                            class: "action-icon",
                            onClick: ($event: any) => ($setup.deleteStagingFile(index))
                          }, null, 8, _hoisted_1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024))
                }), 128)),
                _createVNode(_component_el_row, { class: "mt-1" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_button, {
                      type: "info",
                      onClick: $setup.addStagingFile
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('add')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_el_button, {
                      class: "ml-2",
                      type: "info",
                      onClick: $setup.resetStagingFiles
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('reset')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"]),
                    _createVNode(_component_el_button, {
                      type: "primary",
                      class: "ml-auto",
                      onClick: $setup.testStagingFiles
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('test')), 1)
                      ]),
                      _: 1
                    }, 8, ["onClick"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 15 }, {
          default: _withCtx(() => [
            _createVNode(_component_base_card, {
              "no-header": "",
              class: "ml-1"
            }, {
              body: _withCtx(() => [
                _createVNode(_component_el_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_col, {
                      span: 9,
                      class: "d-flex"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'string'
                        }, {
                          default: _withCtx(() => _cache[4] || (_cache[4] = [
                            _createElementVNode("h4", null, _toDisplayString('FileName'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 6,
                      class: "d-flex"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'string'
                        }, {
                          default: _withCtx(() => _cache[5] || (_cache[5] = [
                            _createElementVNode("h4", null, _toDisplayString('Batch'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 3,
                      class: "d-flex justify-content-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'boolean'
                        }, {
                          default: _withCtx(() => _cache[6] || (_cache[6] = [
                            _createElementVNode("h4", null, _toDisplayString('IsTrigger'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 3,
                      class: "d-flex justify-content-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'boolean'
                        }, {
                          default: _withCtx(() => _cache[7] || (_cache[7] = [
                            _createElementVNode("h4", null, _toDisplayString('IsMissing'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_el_col, {
                      span: 3,
                      class: "d-flex justify-content-center"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          placement: "top",
                          content: 'boolean'
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createElementVNode("h4", null, _toDisplayString('Archived'), -1)
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                ($setup.testInProgress)
                  ? (_openBlock(), _createBlock(_component_table_placeholder_loading, {
                      key: 0,
                      columns: 2
                    }))
                  : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList($setup.localBatchFiles, (item, index) => {
                      return (_openBlock(), _createBlock(_component_el_row, {
                        key: index,
                        class: "mt-1"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_col, {
                            span: 9,
                            class: "d-flex justify-content-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: item.fileName,
                                "onUpdate:modelValue": ($event: any) => ((item.fileName) = $event),
                                disabled: "",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 6,
                            class: "d-flex justify-content-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_input, {
                                modelValue: item.batch,
                                "onUpdate:modelValue": ($event: any) => ((item.batch) = $event),
                                disabled: "",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 3,
                            class: "d-flex justify-content-center align-items-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: item.isTrigger,
                                "onUpdate:modelValue": ($event: any) => ((item.isTrigger) = $event),
                                disabled: "",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 3,
                            class: "d-flex justify-content-center align-items-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: item.isMissing,
                                "onUpdate:modelValue": ($event: any) => ((item.isMissing) = $event),
                                disabled: "",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024),
                          _createVNode(_component_el_col, {
                            span: 3,
                            class: "d-flex justify-content-center align-items-center"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, {
                                modelValue: item.archived,
                                "onUpdate:modelValue": ($event: any) => ((item.archived) = $event),
                                disabled: "",
                                size: "small"
                              }, null, 8, ["modelValue", "onUpdate:modelValue"])
                            ]),
                            _: 2
                          }, 1024)
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}