import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers } from "vue"
import _imports_0 from '@/assets/images/icons/new/edit.svg'


const _hoisted_1 = { class: "d-flex align-items-center" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "body-1 edit-fields-icon" }
const _hoisted_4 = { class: "d-flex pr-2" }
const _hoisted_5 = { class: "d-flex align-items-center" }
const _hoisted_6 = {
  key: 0,
  class: "ml-auto"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_plus = _resolveComponent("plus")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_base_card = _resolveComponent("base-card")!
  const _component_model_list_item = _resolveComponent("model-list-item")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_code_editor = _resolveComponent("code-editor")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_fields_modal = _resolveComponent("fields-modal")!
  const _component_new_branch_modal = _resolveComponent("new-branch-modal")!
  const _component_new_node_modal = _resolveComponent("new-node-modal")!
  const _component_new_source_modal = _resolveComponent("new-source-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_row, { gutter: $setup.spacingWidthHeaderComponents }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, { span: 5 }, {
          default: _withCtx(() => [
            _createVNode(_component_base_card, { "no-header": "" }, {
              body: _withCtx(() => [
                _createVNode(_component_el_form, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('branch'),
                      class: "mb-0 form-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, [
                          _createVNode(_component_el_select, {
                            modelValue: $setup.selectedBranch,
                            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedBranch) = $event)),
                            disabled: $setup.loadingBranches,
                            "value-key": "name",
                            size: "small",
                            placeholder: $setup.loadingBranches ? _ctx.$t('loading') : _ctx.$t('empty')
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.branches, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.name ?? '',
                                  label: item.name ?? '',
                                  value: item
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "disabled", "placeholder"]),
                          _createVNode(_component_el_button, {
                            disabled: 
                    !$setup.userCanWriteEngagementNodeModelGit ||
                    $setup.isSignedOffGainEngagement
                  ,
                            class: "icon-button p-1 cursor-pointer",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.showBranchModal = true))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus, { class: "icon-size-8" })
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('node'),
                      class: "mb-0 form-item"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, [
                          _createVNode(_component_el_select, {
                            modelValue: $setup.selectedNode,
                            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.selectedNode) = $event)),
                            disabled: !$setup.selectedBranch.name || $setup.loadingNodes,
                            "value-key": "name",
                            size: "small",
                            placeholder: $setup.loadingNodes ? _ctx.$t('loading') : _ctx.$t('empty')
                          }, {
                            default: _withCtx(() => [
                              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.nodes, (item) => {
                                return (_openBlock(), _createBlock(_component_el_option, {
                                  key: item.name ?? '',
                                  label: item.name ?? '',
                                  value: item
                                }, null, 8, ["label", "value"]))
                              }), 128))
                            ]),
                            _: 1
                          }, 8, ["modelValue", "disabled", "placeholder"]),
                          _createVNode(_component_el_button, {
                            disabled: 
                    !$setup.selectedBranch.name ||
                    $setup.selectedBranch.readOnly ||
                    !$setup.userCanWriteEngagementNodeModelGit ||
                    $setup.isSignedOffGainEngagement
                  ,
                            class: "icon-button p-1 cursor-pointer",
                            onClick: _cache[3] || (_cache[3] = ($event: any) => ($setup.showNodeModal = true))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_plus, { class: "icon-size-8" })
                            ]),
                            _: 1
                          }, 8, ["disabled"])
                        ])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            ($setup.selectedNode.name)
              ? (_openBlock(), _createBlock(_component_base_card, {
                  key: 0,
                  "no-header": "",
                  class: "mt-1"
                }, {
                  body: _withCtx(() => [
                    _createVNode(_component_el_form, {
                      class: "model-form",
                      "label-position": "top"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_form_item, {
                          label: _ctx.$t('fields')
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_scrollbar, { "max-height": "200px" }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.fieldsObjects, (field) => {
                                  return (_openBlock(), _createBlock(_component_model_list_item, {
                                    key: field.name ?? '',
                                    item: field
                                  }, null, 8, ["item"]))
                                }), 128))
                              ]),
                              _: 1
                            }),
                            (
                  !$setup.selectedBranch.readOnly &&
                  $setup.userCanWriteEngagementNodeModelGit &&
                  !$setup.isSignedOffGainEngagement
                )
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  class: "edit-fields-container",
                                  onClick: _cache[4] || (_cache[4] = ($event: any) => ($setup.showFieldsModal = true))
                                }, [
                                  _createElementVNode("div", _hoisted_3, [
                                    _cache[12] || (_cache[12] = _createElementVNode("img", {
                                      src: _imports_0,
                                      height: "16",
                                      width: "16",
                                      class: "edit-field action-icon"
                                    }, null, -1)),
                                    _createElementVNode("h4", null, _toDisplayString(_ctx.$t('editFields')), 1)
                                  ])
                                ]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["label"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 19 }, {
          default: _withCtx(() => [
            _createVNode(_component_base_card, { "no-header": "" }, {
              body: _withCtx(() => [
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_el_form, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_form_item, {
                        label: _ctx.$t('source')
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_5, [
                            _createVNode(_component_el_select, {
                              modelValue: $setup.selectedSource,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.selectedSource) = $event)),
                              disabled: !$setup.selectedNode.name || $setup.loadingSources,
                              "value-key": "name",
                              size: "small",
                              placeholder: $setup.loadingSources ? _ctx.$t('loading') : _ctx.$t('empty')
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.sources, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.name ?? '',
                                    label: item.name ?? '',
                                    value: item
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["modelValue", "disabled", "placeholder"]),
                            _createVNode(_component_el_button, {
                              disabled: 
                      !$setup.selectedNode.name ||
                      $setup.selectedBranch.readOnly ||
                      !$setup.userCanWriteEngagementNodeModelGit ||
                      $setup.isSignedOffGainEngagement
                    ,
                              class: "icon-button p-1 cursor-pointer",
                              onClick: _cache[6] || (_cache[6] = ($event: any) => ($setup.showSourceModal = true))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_plus, { class: "icon-size-8" })
                              ]),
                              _: 1
                            }, 8, ["disabled"])
                          ])
                        ]),
                        _: 1
                      }, 8, ["label"])
                    ]),
                    _: 1
                  }),
                  (
                $setup.sourceDetail.query &&
                !$setup.selectedBranch.readOnly &&
                $setup.userCanWriteEngagementNodeModelGit &&
                !$setup.isSignedOffGainEngagement
              )
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_el_button, {
                          type: "primary",
                          class: "mt-2",
                          onClick: _withModifiers($setup.updateSourceDetail, ["stop"])
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                          ]),
                          _: 1
                        }, 8, ["onClick"])
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createVNode(_component_el_form, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('queryEditor')
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_code_editor, {
                          modelValue: $setup.sourceDetail.query,
                          "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.sourceDetail.query) = $event)),
                          read_only: !$setup.selectedSource.name,
                          font_size: "13px",
                          languages: [['SQL', 'SQL']],
                          theme: "light",
                          min_height: "300px",
                          min_width: "100%"
                        }, null, 8, ["modelValue", "read_only"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["gutter"]),
    _createVNode(_component_fields_modal, {
      "dialog-visible": $setup.showFieldsModal,
      fields: $setup.fields,
      "hide-data-types": $setup.isGainEngagement,
      "min-field-length": $setup.isGainEngagement ? 2 : 4,
      "all-fields-in-query": $setup.allFieldsInQuery,
      onConfirm: $setup.updateFields,
      onClose: _cache[8] || (_cache[8] = ($event: any) => ($setup.showFieldsModal = false))
    }, null, 8, ["dialog-visible", "fields", "hide-data-types", "min-field-length", "all-fields-in-query", "onConfirm"]),
    _createVNode(_component_new_branch_modal, {
      "show-modal": $setup.showBranchModal,
      "default-parent-branch": $setup.selectedBranch,
      branches: $setup.branches,
      onConfirm: $setup.addNewBranch,
      onClose: _cache[9] || (_cache[9] = ($event: any) => ($setup.showBranchModal = false))
    }, null, 8, ["show-modal", "default-parent-branch", "branches", "onConfirm"]),
    _createVNode(_component_new_node_modal, {
      "show-modal": $setup.showNodeModal,
      nodes: $setup.nodes,
      "hide-schema-field": "",
      onConfirm: $setup.addNewNode,
      onClose: _cache[10] || (_cache[10] = ($event: any) => ($setup.showNodeModal = false))
    }, null, 8, ["show-modal", "nodes", "onConfirm"]),
    _createVNode(_component_new_source_modal, {
      "show-modal": $setup.showSourceModal,
      onConfirm: $setup.addNewSource,
      onClose: _cache[11] || (_cache[11] = ($event: any) => ($setup.showSourceModal = false))
    }, null, 8, ["show-modal", "onConfirm"])
  ], 64))
}