import { ActionContext, Module } from 'vuex';
import {
  createAuthorisationClient,
  createCostManagementClient,
  createInfraApiClient
} from '@/services/api';
import {
  TenantModel,
  BaseTenantModel,
  DeploymentViewModel,
  ResourceModel,
  UpdateTenantModel,
  UpdateResourceModel,
  CreateTenantModel,
  CreateResourceModel,
  DeploymentTaskViewModel,
  BaseTenantModelPage
} from '@etp/etp-infra-client';
import { encodeStringToBase64 } from '@/utils/Base64Conversion';
import { AutopauseExclusionInput } from '@etp/etp-costsaving-client/axios';
import {
  ApplicationDetailsModel,
  UserDetailsModel
} from '@etp/etp-authorization-client/axios';
import { GetScopeForTenant } from '@/utils/scopeHelper';

interface TenantState {
  loading: boolean;
  tenants: Array<BaseTenantModel>;
  pageTenants: Array<BaseTenantModel>;
  tenantsCount: number;
  tenantsPage: number;
  tenantsPageSize: number;
  activeTenant: TenantModel;
  deployments: Array<DeploymentViewModel>;
  deploymentTasks: Array<DeploymentTaskViewModel>;
  resources: Array<ResourceModel>;
  users: Array<UserDetailsModel>;
  applications: Array<ApplicationDetailsModel>;
}

class TenantsModule implements Module<TenantState, any> {
  namespaced = true;
  state = {
    loading: false,
    tenants: [],
    pageTenants: [],
    tenantsCount: 0,
    tenantsPage: 1,
    tenantsPageSize: 10,
    activeTenant: {} as TenantModel,
    deployments: [],
    deploymentTasks: [],
    resources: [],
    users: [],
    applications: []
  };
  getters = {
    getTenants: (state: TenantState): Array<BaseTenantModel> => {
      return state.tenants;
    },
    getPageTenants: (state: TenantState): Array<BaseTenantModel> => {
      return state.pageTenants;
    },
    getTenantsCount: (state: TenantState): number => {
      return state.tenantsCount;
    },
    getTenantsPageNumber: (state: TenantState): number => {
      return state.tenantsPage;
    },
    getTenantsPageSize: (state: TenantState): number => {
      return state.tenantsPageSize;
    },
    getActiveTenant: (state: TenantState): TenantModel => {
      return state.activeTenant;
    },
    getDeployments: (state: TenantState): Array<DeploymentViewModel> => {
      return state.deployments;
    },
    getDeploymentTasks: (
      state: TenantState
    ): Array<DeploymentTaskViewModel> => {
      return state.deploymentTasks;
    },
    getResources: (state: TenantState): Array<ResourceModel> => {
      return state.resources;
    },
    getUsers: (state: TenantState): Array<UserDetailsModel> => {
      return state.users;
    },
    getApplications: (state: TenantState): Array<ApplicationDetailsModel> => {
      return state.applications;
    }
  };
  actions = {
    async fetchPageTenants(
      context: ActionContext<TenantState, any>,
      filter: string
    ) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.listTenants(
        context.state.tenantsPage,
        context.state.tenantsPageSize,
        filter
      );
      if (status == 200) {
        context.commit('SET_PAGINATED_TENANTS', data);
      } else {
        context.commit('SET_PAGINATED_TENANTS', {} as BaseTenantModelPage);
      }
      context.state.loading = false;
    },
    async fetchAllTenants(context: ActionContext<TenantState, any>) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.listTenants();
      if (status == 200) {
        context.commit('SET_TENANTS', data);
      } else {
        context.commit('SET_TENANTS', {} as BaseTenantModelPage);
      }
      context.state.loading = false;
    },
    async fetchTenant(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string }
    ) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.getTenant(payload.tenantId);
      if (status == 200) {
        context.commit('SET_ACTIVE_TENANT', data);
      } else {
        context.commit('SET_ACTIVE_TENANT', {} as TenantModel);
      }
      context.state.loading = false;
    },
    async createTenant(
      context: ActionContext<TenantState, any>,
      payload: CreateTenantModel
    ) {
      let client = createInfraApiClient();
      await client.createTenant(payload);
    },
    async deleteTenant(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string }
    ) {
      let client = createInfraApiClient();
      await client.deleteTenant(payload.tenantId);
    },
    async updateTenant(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string; model: UpdateTenantModel }
    ) {
      let client = createInfraApiClient();
      await client.updateTenant(payload.tenantId, payload.model);
    },
    async fetchResources(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string }
    ) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.listTenantResources(
        payload.tenantId
      );
      if (status == 200) {
        context.commit('SET_RESOURCES', data);
      } else {
        context.commit('SET_RESOURCES', new Array<ResourceModel>());
      }
      context.state.loading = false;
    },
    async createResource(
      context: ActionContext<TenantState, any>,
      payload: {
        tenantId: string;
        model: CreateResourceModel;
      }
    ) {
      let client = createInfraApiClient();
      await client.createTenantResource(payload.tenantId, {
        ...payload.model,
        configuration: encodeStringToBase64(payload.model.configuration)
      } as CreateResourceModel);
    },
    async updateResource(
      context: ActionContext<TenantState, any>,
      payload: {
        tenantId: string;
        resourceId: string;
        model: UpdateResourceModel;
      }
    ) {
      let client = createInfraApiClient();
      await client.updateTenantResource(payload.tenantId, payload.resourceId, {
        ...payload.model,
        configuration: encodeStringToBase64(payload.model.configuration)
      });
    },
    async deleteResource(
      contect: ActionContext<TenantState, any>,
      payload: {
        tenantId: string;
        resourceId: string;
      }
    ) {
      let client = createInfraApiClient();
      await client.deleteTenantResource(payload.tenantId, payload.resourceId);
    },
    async fetchDeployments(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string }
    ) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.listDeployments(payload.tenantId);
      if (status == 200) {
        context.commit('SET_DEPLOYMENTS', data);
      } else {
        context.commit('SET_DEPLOYMENTS', new Array<DeploymentViewModel>());
      }
      context.state.loading = false;
    },
    async startDeployment(
      context: ActionContext<TenantState, any>,
      payload: {
        tenantId: string;
      }
    ) {
      let costSavingClient = createCostManagementClient();
      let infraClient = createInfraApiClient();

      await costSavingClient.setTenantAutoPauseExclusion(payload.tenantId, {
        hours: 1
      } as AutopauseExclusionInput);

      await infraClient.createDeployment(payload.tenantId);
    },
    async fetchDeploymentTasks(
      context: ActionContext<TenantState, any>,
      payload: { tenantId: string; deploymentId: string }
    ) {
      context.state.loading = true;
      let client = createInfraApiClient();
      const { data, status } = await client.getTenantDeploymentTasks(
        payload.tenantId,
        payload.deploymentId
      );
      if (status == 200) {
        context.commit('SET_DEPLOYMENT_TASKS', data);
      } else {
        context.commit(
          'SET_DEPLOYMENT_TASKS',
          new Array<DeploymentTaskViewModel>()
        );
      }
      context.state.loading = false;
    },
    async fetchUsers(
      context: ActionContext<TenantState, any>,
      tenantId: string
    ) {
      let client = createAuthorisationClient();
      let scope = GetScopeForTenant(tenantId);
      const { data, status } = await client.getUsersByScope(scope, true);
      if (status == 200) {
        context.commit('SET_USERS', data);
      } else {
        context.commit('SET_USERS', new Array<UserDetailsModel>());
      }
    },
    async fetchApplications(
      context: ActionContext<TenantState, any>,
      tenantId: string
    ) {
      let client = createAuthorisationClient();
      let scope = GetScopeForTenant(tenantId);
      const { data, status } = await client.getApplicationsByScope(scope, true);
      if (status == 200) {
        context.commit('SET_APPLICATIONS', data);
      } else {
        context.commit(
          'SET_APPLICATIONS',
          new Array<ApplicationDetailsModel>()
        );
      }
    }
  };
  mutations = {
    SET_TENANTS(state: TenantState, payload: BaseTenantModelPage) {
      state.tenantsCount = payload.meta?.totalItems ?? 0;
      state.tenants = payload.results ?? new Array<BaseTenantModel>();
    },
    SET_PAGINATED_TENANTS(state: TenantState, payload: BaseTenantModelPage) {
      state.pageTenants = payload.results ?? new Array<BaseTenantModel>();
      state.tenantsCount = payload.meta?.totalItems ?? 0;
    },
    SET_TENANTS_PAGE(state: TenantState, payload: number) {
      state.tenantsPage = payload;
    },
    SET_ACTIVE_TENANT(state: TenantState, payload: TenantModel) {
      state.activeTenant = payload;
    },
    SET_DEPLOYMENTS(state: TenantState, payload: Array<DeploymentViewModel>) {
      state.deployments = payload;
    },
    SET_DEPLOYMENT_TASKS(
      state: TenantState,
      payload: Array<DeploymentTaskViewModel>
    ) {
      state.deploymentTasks = payload;
    },
    SET_RESOURCES(state: TenantState, payload: Array<ResourceModel>) {
      state.resources = payload;
    },
    SET_USERS(state: TenantState, payload: Array<UserDetailsModel>) {
      state.users = payload;
    },
    SET_APPLICATIONS(
      state: TenantState,
      payload: Array<ApplicationDetailsModel>
    ) {
      state.applications = payload;
    }
  };
}

export default new TenantsModule();
