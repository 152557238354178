import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "body-1 form-item-readonly" }
const _hoisted_2 = { class: "body-1 form-item-readonly" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_card = _resolveComponent("el-card")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_edit_configuration_modal = _resolveComponent("edit-configuration-modal")!
  const _component_base_confirmation_modal = _resolveComponent("base-confirmation-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form, {
      ref: "formRef",
      model: $setup.form,
      rules: $setup.formRules,
      "label-position": "top",
      disabled: $setup.deploymentStatusIsInProgress
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 12,
              class: "d-flex pt-1"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_button, {
                  class: "ml-auto",
                  type: "primary",
                  disabled: $setup.deploymentStatusIsInProgress,
                  onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.showEditConfigurationModal = true))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      content: _ctx.$t('PageLockedBecauseDeploymentInProgress'),
                      disabled: !$setup.deploymentStatusIsInProgress
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('configuration')), 1)
                      ]),
                      _: 1
                    }, 8, ["content", "disabled"])
                  ]),
                  _: 1
                }, 8, ["disabled"]),
                _createVNode(_component_el_button, {
                  class: "ml-2",
                  type: "primary",
                  disabled: $setup.deploymentStatusIsInProgress,
                  onClick: $setup.updateTenantGeneralInfo
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_tooltip, {
                      content: _ctx.$t('PageLockedBecauseDeploymentInProgress'),
                      disabled: !$setup.deploymentStatusIsInProgress
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('save')), 1)
                      ]),
                      _: 1
                    }, 8, ["content", "disabled"])
                  ]),
                  _: 1
                }, 8, ["disabled", "onClick"]),
                ($setup.tenant.isRemovable)
                  ? (_openBlock(), _createBlock(_component_el_button, {
                      key: 0,
                      class: "ml-2",
                      type: "primary",
                      disabled: $setup.deploymentStatusIsInProgress,
                      onClick: $setup.openConfirmationModal
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("h4", null, _toDisplayString(_ctx.$t('delete')), 1)
                      ]),
                      _: 1
                    }, 8, ["disabled", "onClick"]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 12,
              class: "mt-2"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_el_card, { class: "cursor-default" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('name'),
                      prop: "name"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          content: _ctx.$t('PageLockedBecauseDeploymentInProgress'),
                          disabled: !$setup.deploymentStatusIsInProgress
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.name,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.name) = $event)),
                              disabled: $setup.deploymentStatusIsInProgress,
                              size: "small"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["content", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('tenantId'),
                      prop: "id"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_1, _toDisplayString($setup.tenant.id), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('cloudProvider'),
                      prop: "cloudProvider"
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_2, _toDisplayString($setup.getCloudProvider($setup.tenant.cloudProvider ?? 0)), 1)
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('subscriptionId'),
                      prop: "subscriptionId"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          content: _ctx.$t('PageLockedBecauseDeploymentInProgress'),
                          disabled: !$setup.deploymentStatusIsInProgress
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.subscriptionId,
                              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.subscriptionId) = $event)),
                              disabled: $setup.deploymentStatusIsInProgress,
                              size: "small"
                            }, null, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["content", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"]),
                    _createVNode(_component_el_form_item, {
                      label: _ctx.$t('resourceGroupName'),
                      prop: "resourceGroupName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_tooltip, {
                          content: _ctx.$t('PageLockedBecauseDeploymentInProgress'),
                          disabled: !$setup.deploymentStatusIsInProgress
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, {
                              modelValue: $setup.form.resourceGroupName,
                              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.resourceGroupName) = $event)),
                              disabled: $setup.deploymentStatusIsInProgress,
                              size: "small"
                            }, {
                              prepend: _withCtx(() => [
                                _createTextVNode(_toDisplayString($setup.resourceGroupPrefix), 1)
                              ]),
                              _: 1
                            }, 8, ["modelValue", "disabled"])
                          ]),
                          _: 1
                        }, 8, ["content", "disabled"])
                      ]),
                      _: 1
                    }, 8, ["label"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model", "rules", "disabled"]),
    _createVNode(_component_edit_configuration_modal, {
      modelValue: $setup.showEditConfigurationModal,
      "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.showEditConfigurationModal) = $event)),
      configuration: $setup.tenant.cloudConfiguration ?? '',
      onConfirm: $setup.handleConfigurationUpdate,
      onClose: _cache[5] || (_cache[5] = ($event: any) => ($setup.showEditConfigurationModal = false))
    }, null, 8, ["modelValue", "configuration", "onConfirm"]),
    _createVNode(_component_base_confirmation_modal, {
      modelValue: $setup.showBaseConfirmationModal,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => (($setup.showBaseConfirmationModal) = $event)),
      content: _ctx.$t('confirmDeleteTenant'),
      "feedback-text": _ctx.$t('tenantDeleted'),
      onConfirm: $setup.deleteTenant,
      onClose: _cache[7] || (_cache[7] = ($event: any) => ($setup.showBaseConfirmationModal = false))
    }, null, 8, ["modelValue", "content", "feedback-text", "onConfirm"])
  ], 64))
}