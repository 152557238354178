import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "alignment-routelink" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", {
      class: _normalizeClass([$props.active ? 'active-tab' : 'no-active-tab'])
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    _createElementVNode("hr", {
      class: _normalizeClass([$props.active ? 'active-tab' : 'not-active-tab'])
    }, null, 2)
  ]))
}