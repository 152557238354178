<template>
  <base-modal
    width="500px"
    :modal-title="$t('addNewFolder')"
    :confirm-text="$t('save')"
    :dialog-visible="dialogVisible"
    @confirm="confirm()"
    @close="handleClose"
  >
    <template #content>
      <el-form
        ref="formRef"
        :model="form"
        label-position="top"
        :rules="formRules"
        :inline="true"
      >
        <el-form-item :label="$t('name')" prop="displayName" class="mr-0">
          <el-input
            v-model="form.displayName"
            size="small"
            @input="handleDisplayNameInput"
          />
        </el-form-item>
        <el-form-item :label="$t('resultingSystemName')" prop="systemName">
          <el-input v-model="form.systemName" disabled size="small" />
        </el-form-item>
      </el-form>
    </template>
  </base-modal>
</template>

<script lang="ts">
import { ElForm, ElFormItem, ElInput, ElMessage } from 'element-plus';
import BaseModal from '@/components/modals/BaseModal.vue';
import { onUpdated, reactive, ref } from 'vue';
import i18n from '@/i18n';
import { mustBeAlphaNumericDashAndLowerCase } from '@/utils/formValidators';
import { CreateSystemName } from '@/utils/pathHelper';

export interface CreateFolderModalOutput {
  displayName: string;
  systemName: string;
}

export default {
  name: 'CreateFolderModal',
  components: { BaseModal, ElForm, ElFormItem, ElInput },
  props: {
    dialogVisible: { type: Boolean },
    existingSystemNames: {
      type: Array as () => Array<string>,
      default: () => new Array<string>()
    },
    prefix: { type: String, default: '' }
  },
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    const form = ref({
      displayName: '',
      systemName: ''
    } as CreateFolderModalOutput);

    const formSystemNameValidator = (
      rule: any,
      value: string,
      callback: any
    ) => {
      if (
        props.existingSystemNames.some(
          el => el?.toLowerCase() == value.toLowerCase()
        )
      ) {
        callback(new Error(i18n.global.t('folderNameTaken')));
      } else callback();
    };

    const formRules = reactive({
      displayName: [
        {
          required: true,
          message: i18n.global.t('thisFieldIsRequired'),
          trigger: 'blur'
        }
      ],
      systemName: [
        {
          validator: mustBeAlphaNumericDashAndLowerCase,
          trigger: 'blur'
        },
        {
          validator: formSystemNameValidator,
          trigger: 'change'
        }
      ]
    });

    const formRef = ref<HTMLFormElement>();

    const handleDisplayNameInput = (newValue: string) => {
      form.value.systemName = props.prefix + CreateSystemName(newValue);
    };

    const confirm = () => {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          emit('confirm', form.value as CreateFolderModalOutput);
          ElMessage({
            showClose: true,
            message: i18n.global.t('folderAdded')
          });
        }
      });
    };

    onUpdated(() => {
      formRef.value?.resetFields();
    });

    const handleClose = () => {
      emit('close');
    };

    return {
      confirm,
      form,
      formRef,
      formRules,
      handleClose,
      handleDisplayNameInput
    };
  }
};
</script>
