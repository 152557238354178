import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_base_card = _resolveComponent("base-card")!

  return (_openBlock(), _createBlock(_component_base_card, { "no-header": "" }, {
    body: _withCtx(() => [
      _createVNode(_component_el_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_el_col, { span: 6 }, {
            default: _withCtx(() => [
              _createElementVNode("h3", null, _toDisplayString($props.configuration.storagePurpose), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, { span: 15 }, {
            default: _withCtx(() => [
              _createVNode(_component_el_form, { model: $setup.form }, {
                default: _withCtx(() => [
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('displayName') + ' (' + _ctx.$t('max30characters') + ')',
                    prop: "displayName"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.displayName,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.form.displayName) = $event)),
                        size: "small",
                        maxlength: "30",
                        onChange: $setup.handleChangeDisplayName
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('implementation'),
                    prop: "implementation"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.implementation,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.form.implementation) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('empty'),
                        class: "mb-0",
                        onChange: $setup.handleChangeImplementation
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.Implementation, (index, item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: index,
                              label: item,
                              value: index
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('resourceName'),
                    prop: "resourceName"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_select, {
                        modelValue: $setup.form.resourceName,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.form.resourceName) = $event)),
                        size: "small",
                        placeholder: _ctx.$t('empty'),
                        onChange: $setup.handleChangeResourceName
                      }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.localResources, (item) => {
                            return (_openBlock(), _createBlock(_component_el_option, {
                              key: item.name ?? '',
                              label: item.name ?? '',
                              value: item.name ?? ''
                            }, null, 8, ["label", "value"]))
                          }), 128))
                        ]),
                        _: 1
                      }, 8, ["modelValue", "placeholder", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"]),
                  ($setup.form.implementation == $setup.Implementation.Aws_S3Bucket)
                    ? (_openBlock(), _createBlock(_component_el_form_item, {
                        key: 0,
                        class: "mb-0",
                        label: _ctx.$t('secondaryBucketName'),
                        prop: "secondaryBucketName"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_el_input, {
                            modelValue: $setup.form.secondaryBucketName,
                            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.form.secondaryBucketName) = $event)),
                            size: "small",
                            onChange: $setup.handleChangeSecondaryBucketName
                          }, null, 8, ["modelValue", "onChange"])
                        ]),
                        _: 1
                      }, 8, ["label"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_el_form_item, {
                    class: "mb-0",
                    label: _ctx.$t('pathPrefix'),
                    prop: "pathPrefix"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_el_input, {
                        modelValue: $setup.form.pathPrefix,
                        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.form.pathPrefix) = $event)),
                        size: "small",
                        placeholder: "engagement-1337/import-",
                        onChange: $setup.handleChangePathPrefix
                      }, null, 8, ["modelValue", "onChange"])
                    ]),
                    _: 1
                  }, 8, ["label"])
                ]),
                _: 1
              }, 8, ["model"])
            ]),
            _: 1
          }),
          _createVNode(_component_el_col, {
            span: 3,
            class: "d-flex"
          }, {
            default: _withCtx(() => [
              _createElementVNode("img", {
                src: require(`@/assets/images/icons/new/delete.svg`),
                height: "12",
                width: "12",
                class: "action-icon ml-auto mt-1 mr-2",
                onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => ($setup.deleteItem && $setup.deleteItem(...args)))
              }, null, 8, _hoisted_1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}