import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"
import _imports_0 from '@/assets/images/icons/git-commit.svg'
import _imports_1 from '@/assets/images/icons/new/arrow.svg'


const _hoisted_1 = { class: "project-selection-wrapper width-100" }
const _hoisted_2 = { class: "project-meta-content" }
const _hoisted_3 = { class: "projectstatus-icon-wrapper" }
const _hoisted_4 = {
  key: 0,
  src: _imports_0,
  class: "engagement-icon"
}
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 0,
  class: "subtitle-1"
}
const _hoisted_7 = ["title"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createBlock(_component_el_dropdown, {
    class: "menu-dropdown",
    trigger: "click",
    placement: "bottom",
    "max-height": "400px",
    onCommand: $setup.handleClick
  }, {
    dropdown: _withCtx(() => [
      _createVNode(_component_el_dropdown_menu, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.engagements, (engagement) => {
            return (_openBlock(), _createBlock(_component_el_dropdown_item, {
              key: engagement.id,
              title: engagement.name,
              command: engagement
            }, {
              default: _withCtx(() => [
                _createElementVNode("h3", null, _toDisplayString(engagement.name), 1)
              ]),
              _: 2
            }, 1032, ["title", "command"]))
          }), 128)),
          ($props.engagements.length === 0)
            ? (_openBlock(), _createBlock(_component_el_dropdown_item, { key: 0 }, {
                default: _withCtx(() => [
                  _createElementVNode("h3", null, _toDisplayString(_ctx.$t('noEngagement')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (
              !$props.currentEngagement ||
              !$props.currentEngagement.id ||
              !$props.currentEngagement.name
            )
              ? (_openBlock(), _createElementBlock("img", _hoisted_4))
              : (_openBlock(), _createElementBlock("img", {
                  key: 1,
                  src: $setup.getImage(),
                  class: "engagement-icon"
                }, null, 8, _hoisted_5))
          ]),
          _createElementVNode("div", null, [
            (
              !$props.currentEngagement ||
              !$props.currentEngagement.id ||
              !$props.currentEngagement.name
            )
              ? (_openBlock(), _createElementBlock("h4", _hoisted_6, _toDisplayString(_ctx.$t('selectEngagement')), 1))
              : (_openBlock(), _createElementBlock("span", {
                  key: 1,
                  class: "subtitle-1",
                  title: $props.currentEngagement.name
                }, _toDisplayString($props.currentEngagement.name), 9, _hoisted_7))
          ])
        ]),
        _cache[0] || (_cache[0] = _createElementVNode("img", {
          src: _imports_1,
          width: "10",
          height: "10",
          class: "action-icon"
        }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["onCommand"]))
}